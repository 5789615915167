import sideMenuStyles from './SideMenu.module.scss';
import ClientConfig from '../../config/index';
import { useNavigate } from 'react-router-dom';
import { Drawer } from 'antd';
import menuItemsList from '../managementOptions/menuItems/menuItems';
import providerMenuItemsList from '../managementOptions/menuItems/providerMenuItems';
import { useTranslation } from 'react-i18next';
import { RoutesUrl } from '../../router/RoutesUrls';

interface IProps {
    onCloseHandler: () => void;
}
const SideMenu = (props: IProps) => {
    const { onCloseHandler } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const navigationHandler = (routeUrl: string) => {
        onCloseHandler();
        navigate(routeUrl);
    };

    const managementOptions = !ClientConfig.isProvider ? menuItemsList : providerMenuItemsList;

    return (
        <Drawer placement='right' onClose={onCloseHandler} open={true}>
            <div onClick={() => navigationHandler(RoutesUrl.dashboard)} className={sideMenuStyles.managementOptionCintainer}>
                <span>{t('sideMenu.dashboard')}</span>
            </div>
            {managementOptions.map((item, i) => (
                <div key={i} onClick={() => navigationHandler(item.routeUrl)} className={sideMenuStyles.managementOptionCintainer}>
                    <span>{t(`${item.label}`)}</span>
                </div>
            ))}
        </Drawer>
    );
};

export default SideMenu;
