import OrderingModel from '../order/OrderingPolicy.model';
import EmailModel from './Email.model';
import LegalcyModel from './Legalcy.model';

export default class SystemModel {
    ordering: OrderingModel = new OrderingModel();

    legalcy: LegalcyModel = new LegalcyModel();

    email: EmailModel = new EmailModel();

    constructor(system?: SystemModel) {
        if (system) {
            this.ordering = system.ordering;
            this.legalcy = system.legalcy;
            this.email = system.email;
        }
    }
}
