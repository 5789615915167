import { observer } from 'mobx-react';
import Select from 'react-select';
import { ISelectOption } from '../../../interfaces/interfaces';
import selectStyle from './ISelect.module.scss';

interface IProps {
    options: ISelectOption[];
    placeholder?: string;
    onChange: (selected: ISelectOption) => void;
    isClearable: boolean;
    value?: any;
}

const customStyles = {
    option: (provided) => ({
        ...provided,
        color: 'black',
    }),
    control: (provided) => ({
        ...provided,
        color: 'black',
        backgroundColor: 'transparent',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
};

const ISelect = observer((props: IProps) => {
    const { options, placeholder, onChange, isClearable, value } = props;

    return (
        <Select
            className={selectStyle.selectContainer}
            styles={customStyles}
            options={options}
            onChange={onChange}
            placeholder={placeholder}
            isClearable={isClearable}
            value={value}
        />
    );
});

export default ISelect;
