import iChartsStyle from './ICharts.module.scss';
import { BarChart } from '@mui/x-charts/BarChart';
import { IChartsSeries } from '../../../interfaces/interfaces';
import './ICharts.customization.css';

interface IProps {
    title: string;
    data: string[];
    series: IChartsSeries[];
    colors: string[];
}

const ICharts = (props: IProps) => {
    const { colors, title, data, series } = props;

    const initialSeries = series.some((item) => item?.data?.length !== 0) ? series : [];

    return (
        <div className={`${iChartsStyle.iChartsContainer} icharts-container`}>
            <div className={`${iChartsStyle.titleContainer} row`}>
                <span>{title}</span>
            </div>
            <div className={iChartsStyle.barContainer}>
                <BarChart
                    xAxis={[
                        {
                            scaleType: 'band',
                            data: data,
                        },
                    ]}
                    series={initialSeries}
                    height={300}
                    colors={colors}
                />
            </div>
        </div>
    );
};

export default ICharts;
