import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';

import UserModel from '../../models/user/User.model';

class UserStore {
    @observable
    isLoading: boolean = false;

    @observable
    user: UserModel = new UserModel();

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'UserStore',
            properties: ['user'],
            storage: window.localStorage,
        });
    }

    @action
    setUser = (user: UserModel) => (this.user = user);

    @action
    setIsLoading = (value: boolean) => (this.isLoading = value);
}

export default UserStore;
