import { useTranslation } from 'react-i18next';
import StatusIndicator from '../../components/statusIndicator/StatusIndicator';
import { CProducts } from '../../constants/constants';
import { ITableColumn } from '../../interfaces/interfaces';
import ProductsCategoryModel from '../../models/product/ProductsCategory.model';
import ProductsSectionModel from '../../models/product/ProductsSection.model';
import ProductsSubCategoryModel from '../../models/product/ProductsSubCategory.model';

const ProductsProviderColumns = () => {
    const { t } = useTranslation();

    const columns: ITableColumn[] = [
        {
            field: CProducts.IMAGE,
            headerName: t('products.image'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => <img src={e.row.images[0]} alt={e.value} style={{ width: 50, height: 50 }} />,
        },
        { field: CProducts.SERIAL_NUMBER, headerName: t('products.serialNumber'), align: 'center', headerAlign: 'center', flex: 1 },
        { field: CProducts.NAME, headerName: t('products.name'), align: 'center', headerAlign: 'center', flex: 1 },
        {
            field: CProducts.SECTIONS,
            headerName: t('products.section'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => e.value?.map((section: ProductsSectionModel) => <span key={section.sectionId}> {`${section.label}`} </span>),
        },
        {
            field: CProducts.CATEGORIES,
            headerName: t('products.category'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => e.value?.map((category: ProductsCategoryModel) => <span key={category.categoryId}>{category.label}</span>),
        },
        {
            field: CProducts.SUB_CATEGORIES,
            headerName: t('products.subCategory'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) =>
                e.value?.map((subCategory: ProductsSubCategoryModel) => <span key={subCategory.subCategoryId}>{subCategory.label}</span>),
        },
        {
            field: CProducts.IS_VISIBLE,
            headerName: t('products.isVisible'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => <StatusIndicator isActive={e.value} />,
        },
        {
            field: CProducts.IN_STOCK,
            headerName: t('products.inStock'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => <StatusIndicator isActive={e.value} />,
        },
        {
            field: CProducts.HOT,
            headerName: t('products.hot'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => <StatusIndicator isActive={e.value} />,
        },
    ];

    return columns;
};

export default ProductsProviderColumns;
