import { useNavigate } from 'react-router-dom';
import OrdersStatus from '../../models/order/OrdersStatus.model';
import { formatDateAndTime } from '../../utils/dateUtils';
import orderMiniCardStyle from './OrderMiniCard.module.scss';
import { RoutesUrl } from '../../router/RoutesUrls';

interface IProps {
    order: any;
}

const OrderMiniCard = (props: IProps) => {
    const { order } = props;

    const navigate = useNavigate();

    const currStatus: OrdersStatus = order.orderStatus.find((oStatus: OrdersStatus) => oStatus.active);

    return (
        <div onClick={() => navigate(`${RoutesUrl.orders}/${order._id}`)} className={orderMiniCardStyle.productMiniCardContainer}>
            <span className={orderMiniCardStyle.orderId}>{order.shortId}</span>
            <span>{order.user.firstName}</span>
            <span>{formatDateAndTime(order.date)}</span>
            <span>{currStatus.label}</span>
        </div>
    );
};

export default OrderMiniCard;
