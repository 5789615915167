export default class BillingInformationModel {
    address: string = '';
    address2: string = '';
    city: string = '';
    floor: string = '';
    appartmentNumber: string = '';
    appartmentType: string = '';

    constructor(bi?: BillingInformationModel) {
        if (bi) {
            this.address = bi.address;
            this.address2 = bi.address2;
            this.city = bi.city;
            this.floor = bi.floor;
            this.appartmentNumber = bi.appartmentNumber;
            this.appartmentType = bi.appartmentType;
        }
    }
}
