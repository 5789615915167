import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { hashPassword } from '../../actions/hashPassword';
import { ILoginPayload } from '../../interfaces/interfaces';
import { RoutesUrl } from '../../router/RoutesUrls';
import commonStyles from '../../styles/commonStyles.module.scss';
import IButton from '../commonComponents/IButton/IButton';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import loginStyle from './loginForm.module.scss';

interface IProps {
    loginHandler: (payload: ILoginPayload) => void;
}

const LoginForm = (props: IProps) => {
    const { loginHandler } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        email: yup.string().email(t('loginForm.invalidEmail')).required(t('loginForm.requiredField')),
        password: yup.string().required(t('loginForm.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await loginHandler({ email: values.email, password: await hashPassword(values.password) });
            navigate(RoutesUrl.dashboard);
        },
    });

    return (
        <div className={loginStyle.loginFormContainer}>
            <form className={loginStyle.loginForm} onSubmit={formik.handleSubmit}>
                <ITextInput
                    size='small'
                    handleOnChange={formik.handleChange('email')}
                    variant='outlined'
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    placeholder={t('loginForm.email')}
                    inputProps={{ style: { textAlign: 'right' } }}
                />
                <ITextInput
                    type='password'
                    size='small'
                    handleOnChange={formik.handleChange('password')}
                    variant='outlined'
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    value={formik.values.password}
                    placeholder={t('loginForm.password')}
                    inputProps={{ style: { textAlign: 'right' } }}
                />
                <IButton className={`${commonStyles.defaultButton} ${loginStyle.loginButton}`} title={t('loginForm.login')} type={'submit'} />
            </form>
        </div>
    );
};

export default LoginForm;
