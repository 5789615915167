import { observer } from 'mobx-react-lite';
import Spinner from '../../../components/commonComponents/spinner/Spinner';
import LoginForm from '../../../components/loginForm/loginForm';
import rootStores from '../../../stores';
import AuthStore from '../../../stores/auth/Auth.store';
import { AUTH_STORE } from '../../../stores/constants';
import loginStyle from './LoginPage.module.scss';

import banner from '../../../assets/banners/mtshop-logo.png';

const authStore = rootStores[AUTH_STORE] as AuthStore;

const LoginPage = observer(() => {
    const { login, isLoading } = authStore;
    return (
        <div className={loginStyle.container}>
            {isLoading && <Spinner />}
            <div className={loginStyle.bannerContainer}>
                <img className={loginStyle.banner} src={banner} alt={'smoke-talk-banner'} />
            </div>
            <div className={loginStyle.formContainer}>
                <LoginForm loginHandler={login} />
            </div>
        </div>
    );
});

export default LoginPage;
