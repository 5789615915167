import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import ClientConfig from '../../config/index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IButton from '../../components/commonComponents/IButton/IButton';
import ITextInput from '../../components/commonComponents/ITextInput/TextInput';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import ITable from '../../components/commonComponents/table/ITable';
import ProductModel from '../../models/product/Product.model';
import { RoutesUrl } from '../../router/RoutesUrls';
import { FILTER_STORE, PRODUCTS_STORE } from '../../stores/constants';
import FilterStore from '../../stores/filter/Filter.store';
import rootStores from '../../stores/index';
import ProductsStore from '../../stores/products/Products.store';
import commonStyles from '../../styles/commonStyles.module.scss';
import productsStyle from './Products.module.scss';
import ProductsColumns from './ProductsColumns';
import ProductsProviderColumns from './ProductsProviderColumns';
import { useMediaQuery } from 'react-responsive';
import ProductMiniCard from '../../components/productMiniCard/ProductMiniCard';

const filterStore = rootStores[FILTER_STORE] as FilterStore;
const productsStore = rootStores[PRODUCTS_STORE] as ProductsStore;

const Products = observer(() => {
    const [serialNumberSearch, setSerialNumberSearch] = useState<string>('');
    const [productNameSearch, setProductNameSearch] = useState<string>('');

    const { productsFilter } = filterStore;
    const { getProductsByFilters, products, setSelectedProduct, isLoading } = productsStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const columns = !ClientConfig.isProvider ? ProductsColumns() : ProductsProviderColumns();

    const filteredRows: ProductModel[] = products?.filter(
        (row) =>
            row.serialNumber.toLowerCase().includes(serialNumberSearch.toLowerCase()) &&
            row.name.toLowerCase().includes(productNameSearch.toLowerCase())
    );

    const selectProductHandler = (selectedProduct: ProductModel) => {
        setSelectedProduct(selectedProduct);
        navigate(`${RoutesUrl.products}/${selectedProduct._id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getProductsByFilters(productsFilter);
            } catch (error) {}
        };

        fetchData();
    }, [getProductsByFilters]);

    return (
        <div className={productsStyle.productsContainer}>
            <div className={`${productsStyle.header} row`}>
                <div className='col-12 col-md-2'>
                    <IButton
                        onClick={() => {
                            setSelectedProduct(new ProductModel());
                            navigate(RoutesUrl.createProduct);
                        }}
                        title={t('products.createNewProduct')}
                        className={`${commonStyles.defaultButton}`}
                    />
                </div>
                <div className='col-12 col-md-3'>
                    <ITextInput
                        size='small'
                        value={serialNumberSearch}
                        handleOnChange={(e) => setSerialNumberSearch(e.target.value)}
                        variant='outlined'
                        placeholder={t('products.searchSerialNumber')}
                    />
                </div>
                <div className='col-12 col-md-3'>
                    <ITextInput
                        size='small'
                        value={productNameSearch}
                        handleOnChange={(e) => setProductNameSearch(e.target.value)}
                        variant='outlined'
                        placeholder={t('products.searchProductName')}
                    />
                </div>
            </div>
            {!isMobileScreen && (
                <div className='products-data-grid-container'>
                    <ITable columns={columns} rows={filteredRows} checkboxSelection={false} setSelectedRow={selectProductHandler} hidePagination />
                </div>
            )}
            {isMobileScreen && (
                <div className={productsStyle.filteredCardsContainer}>
                    {filteredRows.map((product) => (
                        <ProductMiniCard key={product._id} product={product} selectProductHandler={selectProductHandler} />
                    ))}
                </div>
            )}
            {<Spinner /> && isLoading}
        </div>
    );
});

export default Products;
