import { BaseHttpFetcher } from './BaseHttpFetcher';
import ClientConfig from '../config/index';
import UpdateSystemFieldsPayload from '../models/system/UpdateSystemFieldsPayload.model';

const systemPrefix = 'systemManagement';

class SystemFetchers extends BaseHttpFetcher {
    async getSystemSettings(): Promise<any> {
        return await this.get(`${systemPrefix}/settings`);
    }

    async updateSystemSettingsByFields(payload: UpdateSystemFieldsPayload): Promise<any> {
        return await this.patch(`${systemPrefix}/settings`, payload.updates);
    }
}

const systemFetcherInstance = new SystemFetchers('/', ClientConfig.apiBaseHost);
export default systemFetcherInstance;
