import Category from './Category.model';

export default class SectionModel {
    sectionId!: string;

    sectionName!: string;

    categories?: Category[];

    disabled?: boolean;

    constructor(section?: SectionModel) {
        if (section) {
            this.sectionId = section.sectionId;
            this.sectionName = section.sectionName;
            this.categories = section.categories?.map((category) => new Category(category));
            this.disabled = section.disabled;
        }
    }
}
