import { useTranslation } from 'react-i18next';
import orderConfirmationFormStyle from './OrderConfirmationMessageForm.module.scss';
import rootStores from '../../../stores';
import { SYSTEM_STORE } from '../../../stores/constants';
import SystemStore from '../../../stores/system/System.store';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import ITextInput from '../../commonComponents/ITextInput/TextInput';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/IButton/IButton';
import { CButton, COrderConfirmationMessageForm, CVariant } from '../../../constants/constants';
import UpdateSystemFieldsPayload from '../../../models/system/UpdateSystemFieldsPayload.model';
import { useEffect } from 'react';
import OrderConfirmationMessageTemplate from './orderConfirmationMessageTemplate/OrderConfirmationMessageTemplate';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const OrderConfirmationMessageForm = observer(() => {
    const {
        system: {
            email: { orderConfirmation },
        },
        updateSystemSettingsByFields,
    } = systemStore;

    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        subject: yup.string().required(t('orderConfirmationMessageForm.requiredField')),
        footerMessage: yup.string().required(t('orderConfirmationMessageForm.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            subject: orderConfirmation.subject,
            footerMessage: orderConfirmation.footerMessage,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const prefex = COrderConfirmationMessageForm.PREFEX;
            await updateSystemSettingsByFields(
                new UpdateSystemFieldsPayload({
                    updates: {
                        [`${prefex}.${COrderConfirmationMessageForm.SUBJECT}`]: values.subject,
                        [`${prefex}.${COrderConfirmationMessageForm.FOOTER_MESSAGE}`]: values.footerMessage,
                    },
                })
            );
        },
    });

    useEffect(() => {
        formik.setValues({
            subject: orderConfirmation.subject,
            footerMessage: orderConfirmation.footerMessage,
        });
    }, [orderConfirmation]);

    return (
        <div className={orderConfirmationFormStyle.orderConfirmationFormContainer}>
            <div className={`${orderConfirmationFormStyle.contextMessage} row`}>
                <div className={`${orderConfirmationFormStyle.formContainer} col-12 col-md-6`}>
                    <form className={orderConfirmationFormStyle.form} onSubmit={formik.handleSubmit}>
                        <div className={orderConfirmationFormStyle.inputContainer}>
                            <ITextInput
                                size={CButton.SMALL}
                                handleOnChange={formik.handleChange(COrderConfirmationMessageForm.SUBJECT)}
                                variant={CVariant.OUTLINED}
                                error={formik.touched.subject && Boolean(formik.errors.subject)}
                                helperText={formik.touched.subject && formik.errors.subject}
                                value={formik.values.subject}
                                inputLabel={t('orderConfirmationMessageForm.subject')}
                                inputProps={{ maxLength: '60' }}
                            />
                        </div>
                        <div className={orderConfirmationFormStyle.inputContainer}>
                            <ITextInput
                                size={CButton.SMALL}
                                handleOnChange={formik.handleChange(COrderConfirmationMessageForm.FOOTER_MESSAGE)}
                                variant={CVariant.OUTLINED}
                                error={formik.touched.footerMessage && Boolean(formik.errors.footerMessage)}
                                helperText={formik.touched.footerMessage && formik.errors.footerMessage}
                                value={formik.values.footerMessage}
                                multiline={true}
                                inputProps={{ maxLength: '350' }}
                                inputLabel={t('orderConfirmationMessageForm.footerMessage')}
                            />
                        </div>
                        <div className={orderConfirmationFormStyle.buttonContainer}>
                            <IButton className={commonStyles.defaultButton} title={t('orderConfirmationMessageForm.update')} type={CButton.SUBMIT} />
                        </div>
                    </form>
                </div>
                <div className={`${orderConfirmationFormStyle.previewContainer} col-12 col-md-6`}>
                    <OrderConfirmationMessageTemplate footerMessage={formik.values.footerMessage} subjectMessage={formik.values.subject} />
                </div>
            </div>
        </div>
    );
});

export default OrderConfirmationMessageForm;
