import { observer } from 'mobx-react';
import sectionCollapseStyle from './SectionCollapse.module.scss';
import SectionModel from '../../models/topics/Section.model';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CategoryCollapse from '../categoryCollapse/CategoryCollapse';
import { useTranslation } from 'react-i18next';
import IButton from '../commonComponents/IButton/IButton';
import commonStyles from '../../styles/commonStyles.module.scss';
import { CButton, CCategories, CVariant } from '../../constants/constants';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import DropDown from '../commonComponents/dropDown/DropDown';
import { ISelectOption } from '../../interfaces/interfaces';
import rootStores from '../../stores/index';
import { SECTIONS_STORE } from '../../stores/constants';
import SectionsStore from '../../stores/sections/Sections.store';
import AddCategoryForm from '../addCategoryForm/AddCategoryForm';
import CategoryModel from '../../models/topics/Category.model';

const sectionsStore = rootStores[SECTIONS_STORE] as SectionsStore;

interface IProps {
    section: SectionModel;
}

const SectionCollapse = observer((props: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isAddCategoryMode, setIsAddCategoryMode] = useState<boolean>(false);
    const { updateSection, createCategory } = sectionsStore;
    const { section } = props;

    const { t } = useTranslation();

    const activityOptions: ISelectOption[] = [
        { value: CCategories.ACTIVE, label: t('categories.active') },
        { value: CCategories.DISABLED, label: t('categories.disabled') },
    ];

    const validationSchema = yup.object().shape({
        sectionName: yup.string().required(t('categories.requiredField')),
        disabled: yup.boolean().required(t('categories.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            sectionName: section.sectionName,
            disabled: section.disabled,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const updatedSection: SectionModel = new SectionModel({
                sectionId: section.sectionId,
                sectionName: values.sectionName,
                disabled: values.disabled,
            });

            await updateSection(updatedSection);
            setIsEditMode(false);
        },
    });

    const submitAddCategoryFormHandler = async (categoryName: string) => {
        await createCategory(
            new CategoryModel({
                categoryId: '',
                categoryName,
                sectionId: section.sectionId,
            })
        );
        setIsAddCategoryMode(false);
    };

    useEffect(() => {
        formik.setValues({
            sectionName: section.sectionName,
            disabled: section.disabled,
        });
    }, [isEditMode]);

    return (
        <>
            <div className={`${sectionCollapseStyle.sectionCollapseContainer} ${section.disabled ? sectionCollapseStyle.disabled : ''}`}>
                <span>
                    {isEditMode ? (
                        <ITextInput
                            size={CButton.SMALL}
                            handleOnChange={formik.handleChange(CCategories.SECTION_NAME)}
                            variant={CVariant.OUTLINED}
                            error={formik.touched.sectionName && Boolean(formik.errors.sectionName)}
                            helperText={formik.touched.sectionName && formik.errors.sectionName}
                            value={formik.values.sectionName}
                        />
                    ) : (
                        section.sectionName
                    )}
                </span>
                <span>
                    {isEditMode ? (
                        <DropDown
                            value={formik.values.disabled ? CCategories.DISABLED : CCategories.ACTIVE}
                            options={activityOptions}
                            onSelectOption={(option) => {
                                formik.setFieldValue(CCategories.DISABLED, option.value === CCategories.DISABLED ? true : false);
                            }}
                        />
                    ) : (
                        `${section.disabled ? t('categories.disabled') : t('categories.active')}`
                    )}
                </span>
                <span className={sectionCollapseStyle.formActionsContainer}>
                    <IButton
                        title={isEditMode ? t('categories.save') : t('categories.edit')}
                        className={commonStyles.textButton}
                        onClick={() => {
                            isEditMode ? formik.handleSubmit() : setIsEditMode(!isEditMode);
                        }}
                    />
                    {isEditMode && (
                        <IButton
                            title={t('categories.cancel')}
                            className={commonStyles.textButton}
                            onClick={() => {
                                formik.resetForm();
                                setIsEditMode(!isEditMode);
                            }}
                        />
                    )}
                </span>
                <span
                    className={sectionCollapseStyle.expandIcon}
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                >
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </span>
            </div>

            {isOpen && (
                <>
                    <div className={sectionCollapseStyle.addCategoryContainer}>
                        <IButton
                            title={t('categories.addCategory', { section: section.sectionName })}
                            className={commonStyles.defaultButton}
                            onClick={() => {
                                setIsAddCategoryMode(true);
                            }}
                        />
                        {isAddCategoryMode && (
                            <AddCategoryForm
                                submitFormHandler={submitAddCategoryFormHandler}
                                closeFormHandler={() => {
                                    setIsAddCategoryMode(false);
                                }}
                            />
                        )}
                    </div>
                    {section.categories?.map((category) => (
                        <div key={category.categoryId} className={sectionCollapseStyle.categoriesContainer}>
                            <CategoryCollapse category={category} sectionDisabled={section.disabled} parentDisabled={undefined} />
                        </div>
                    ))}
                </>
            )}
        </>
    );
});

export default SectionCollapse;
