import { IPieCharts } from '../../../interfaces/interfaces';
import iPieChartStyle from './IPieChart.module.scss';
import { PieChart } from '@mui/x-charts/PieChart';
import { useMediaQuery } from 'react-responsive';

interface IProps {
    data: IPieCharts[];
    colors: string[];
}

const IPieChart = (props: IProps) => {
    const { data, colors } = props;

    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });
    return (
        <div className={`${iPieChartStyle.iPieChartsContainer}`}>
            <PieChart
                series={[
                    {
                        data,
                        highlightScope: { faded: 'global', highlighted: 'item' },
                        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                    },
                ]}
                colors={colors}
                height={isMobileScreen ? 120 : 250}
            />
        </div>
    );
};

export default IPieChart;
