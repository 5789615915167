import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Divider from '@mui/material/Divider';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import IButton from '../../components/commonComponents/IButton/IButton';
import { ORDERS_STORE, USER_STORE } from '../../stores/constants';
import rootStores from '../../stores/index';
import OrdersStore from '../../stores/orders/Orders.store';
import ClientConfig from '../../config/index';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ISelect from '../../components/commonComponents/ISelect/ISelect';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import ITimeline from '../../components/commonComponents/ITimeLine/ITimeLine';
import { DeliveryMethods, EDiscount } from '../../enums/enums';
import UserStore from '../../stores/user/User.store';
import commonStyles from '../../styles/commonStyles.module.scss';
import { formatDateAndTime } from '../../utils/dateUtils';
import orderStyles from './Order.module.scss';
import ProductInOrder from './ProductInOrder';
import { RoutesUrl } from '../../router/RoutesUrls';
import OrdersStatus from '../../models/order/OrdersStatus.model';
import { ISelectOption } from '../../interfaces/interfaces';
import IModal from '../../components/commonComponents/IModal/IModal';
import ChangeOrderStatusContext from '../../components/commonComponents/IModal/changeOrderStatusContext/ChangeOrderStatusContext';
import { CCoupons } from '../../constants/constants';

const ordersStore = rootStores[ORDERS_STORE] as OrdersStore;
const userStore = rootStores[USER_STORE] as UserStore;

const Order = observer(() => {
    const [changeStatusModalIsOpen, setChangeStatusModalIsOpen] = useState<boolean>(false);
    const [newStatusToUpdate, setNewStatusToUpdate] = useState<OrdersStatus>(new OrdersStatus());
    const { selectedOrder, ordersStatusesAsISelect, getOrderStatusesOptions, getOrderByOrderId, isLoading, updateOrderStatus } = ordersStore;
    const { user } = userStore;

    const { t } = useTranslation();

    const params = useParams();
    const navigate = useNavigate();

    const activeStatus = selectedOrder?.orderStatus.find((os) => os.active);

    const onBackClick = () => navigate(RoutesUrl.orders);

    const onStatusChange = async (selectedStatus: ISelectOption) => {
        if (selectedStatus.value !== activeStatus?.state) {
            const status: OrdersStatus = new OrdersStatus({
                state: selectedStatus.value,
                label: selectedStatus.label,
                createdBy: user.id,
                comment: '',
            });

            setNewStatusToUpdate(status);
            setChangeStatusModalIsOpen(true);
        }
    };

    const submitChangeStatusHandler = async (comment: string) => {
        newStatusToUpdate.comment = comment;
        setChangeStatusModalIsOpen(false);
        await updateOrderStatus(newStatusToUpdate, selectedOrder!);
    };

    const cancelChangeStatus = () => {
        setNewStatusToUpdate(new OrdersStatus());
        setChangeStatusModalIsOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                !ordersStatusesAsISelect.length && (await getOrderStatusesOptions());
            } catch (error) {}
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (params.orderId) getOrderByOrderId(params.orderId);
    }, []);

    return (
        <div className={`${orderStyles.orderContainer} ${isLoading ? 'blur-background' : ''}`}>
            {isLoading && <Spinner />}
            <IModal isOpen={changeStatusModalIsOpen} onClose={cancelChangeStatus}>
                <ChangeOrderStatusContext
                    newStatusToUpdate={newStatusToUpdate}
                    cancelHandler={() => {
                        setChangeStatusModalIsOpen(false);
                    }}
                    updateStatusHandler={submitChangeStatusHandler}
                />
            </IModal>
            <div className={orderStyles.orderHeader}>
                <div className={orderStyles.backButton}>
                    <IButton
                        title={`${t('order.orderNumber')} ${selectedOrder?.shortId} `}
                        className={commonStyles.defaultButton}
                        endIcon={<ArrowBackIcon className={orderStyles.buttonIcon} />}
                        onClick={onBackClick}
                    />
                </div>
                <div className={orderStyles.actionsContainer}>
                    <ISelect
                        options={ordersStatusesAsISelect}
                        placeholder={activeStatus?.label || ' '}
                        onChange={onStatusChange}
                        isClearable={false}
                        value={activeStatus?.state}
                    />
                </div>
            </div>
            <div className={orderStyles.orderDataContainer}>
                <div className={orderStyles.boxContainer}>
                    <span className={orderStyles.boxTitle}>{t('order.orderDetails')}</span>
                    <Divider className={orderStyles.divider} />
                    <div className={orderStyles.boxInfoContainer}>
                        <span className={orderStyles.boxSubTitle}>{t('order.orderDate')}</span>
                        <span className={orderStyles.boxInfoText}>
                            {(selectedOrder?.dateAndTime && formatDateAndTime(selectedOrder?.dateAndTime)) || ''}
                        </span>
                    </div>
                    <div className={orderStyles.boxInfoContainer}>
                        <span className={orderStyles.boxSubTitle}>{t('order.addresseeName')}</span>
                        <span className={orderStyles.boxInfoText}>{`${selectedOrder?.user?.firstName} ${selectedOrder?.user?.lastName}`}</span>
                    </div>
                    <div className={orderStyles.boxInfoContainer}>
                        <span className={orderStyles.boxSubTitle}>{t('order.addresseePhoneNumber')}</span>
                        <span className={orderStyles.boxInfoText}>{selectedOrder?.user?.phoneNumber}</span>
                    </div>
                    <div className={orderStyles.boxInfoContainer}>
                        <span className={orderStyles.boxSubTitle}>{t('order.addresseeEmail')}</span>
                        <span className={orderStyles.boxInfoText}>{selectedOrder?.user?.email}</span>
                    </div>
                    <Divider className={orderStyles.divider} />

                    <div className={orderStyles.boxInfoContainer}>
                        <span className={orderStyles.boxSubTitle}>{t('order.shipmentMethod')}</span>
                        <span className={orderStyles.boxInfoText}>{t(`order.${selectedOrder?.shippingDetails?.deliveryMethod}`)}</span>
                    </div>

                    {selectedOrder?.shippingDetails?.deliveryMethod === DeliveryMethods.DELIVERY ? (
                        <>
                            <div className={orderStyles.boxInfoContainer}>
                                <span className={orderStyles.boxSubTitle}>{t('order.deliveryAddress')}</span>
                                <span className={orderStyles.boxInfoText}>{selectedOrder?.shippingDetails?.address}</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={orderStyles.boxInfoContainer}>
                                <span className={orderStyles.boxSubTitle}>{t('order.storeAddress')}</span>
                                <span className={orderStyles.boxInfoText}>{selectedOrder?.shippingDetails?.address}</span>
                            </div>
                        </>
                    )}
                </div>

                {!ClientConfig.isProvider && (
                    <div className={orderStyles.boxContainer}>
                        <span className={orderStyles.boxTitle}>{t('order.paymentInfo')}</span>
                        <Divider className={orderStyles.divider} />
                        <div className={orderStyles.boxInfoContainer}>
                            <span className={orderStyles.boxSubTitle}>{t('order.paymentMethod')}</span>
                            <span className={orderStyles.boxInfoText}>{selectedOrder?.paymentInformation?.paymentMethod}</span>
                        </div>
                        <div className={orderStyles.boxInfoContainer}>
                            <span className={orderStyles.boxSubTitle}>{t('order.cardHolderName')}</span>
                            <span className={orderStyles.boxInfoText}>{`${selectedOrder?.user?.firstName} ${selectedOrder?.user?.lastName}`}</span>
                        </div>
                        <div className={orderStyles.boxInfoContainer}>
                            <span className={orderStyles.boxSubTitle}>{t('order.cardHolderId')}</span>
                        </div>
                        <div className={orderStyles.boxInfoContainer}>
                            <span className={orderStyles.boxSubTitle}>{t('order.cardNumber')}</span>
                            <span className={orderStyles.boxInfoText}>{selectedOrder?.paymentInformation?.lastDigits}</span>
                        </div>
                        <Divider className={orderStyles.divider} />
                        <div className={orderStyles.boxInfoContainer}>
                            <span className={orderStyles.boxSubTitle}>{t('order.productsAmount')}</span>
                            <span className={orderStyles.boxInfoText}>{`${selectedOrder?.paymentAmount?.productsAmount?.toLocaleString()} ₪`}</span>
                        </div>
                        <div className={orderStyles.boxInfoContainer}>
                            <span className={orderStyles.boxSubTitle}>{t('order.shippingAmount')}</span>
                            <span className={orderStyles.boxInfoText}>{`${selectedOrder?.paymentAmount.shippingAmount} ₪`}</span>
                        </div>
                        <div className={orderStyles.boxInfoContainer}>
                            <span className={orderStyles.boxSubTitle}>{t('order.totalAmount')}</span>
                            <span className={orderStyles.boxInfoText}>{`${selectedOrder?.paymentAmount.totalAmount?.toLocaleString()} ₪`}</span>
                        </div>

                        {selectedOrder?.coupon?.code && (
                            <>
                                <Divider className={orderStyles.divider} />
                                <span className={orderStyles.boxTitle}>{t('order.coupon')}</span>
                                <div className={orderStyles.boxInfoContainer}>
                                    <span className={orderStyles.boxSubTitle}>{t('order.couponCode')}</span>
                                    <span className={orderStyles.boxInfoText}>{selectedOrder.coupon.code}</span>
                                </div>
                                <div className={orderStyles.boxInfoContainer}>
                                    <span className={orderStyles.boxSubTitle}>{t('order.couponDiscount')}</span>
                                    <span className={orderStyles.boxInfoText}>{`${
                                        selectedOrder.coupon.discount.type === CCoupons.FIXED
                                            ? `${EDiscount.Shekel}${selectedOrder.coupon.discount.amount}`
                                            : `${selectedOrder.coupon.discount.amount}${EDiscount.Percentage}`
                                    }`}</span>
                                </div>
                            </>
                        )}
                    </div>
                )}

                <div className={orderStyles.boxContainer}>
                    <span className={orderStyles.boxTitle}>{t('order.statusesHitsory')}</span>
                    <Divider className={orderStyles.divider} />
                    <div className={orderStyles.timeline}>
                        <ITimeline statuses={selectedOrder?.orderStatus} />
                    </div>
                </div>
            </div>
            {selectedOrder?.products && (
                <div className={orderStyles.productsContainer}>
                    {selectedOrder?.products.map((product) => (
                        <ProductInOrder key={product.product._id} product={product} />
                    ))}
                </div>
            )}
        </div>
    );
});

export default Order;
