import DiscountModel from './DiscountModel.model';

export default class CouponModel {
    _id: string = '';
    code: string = '';
    expirationDate: string = '';
    minAmount: number = 0;
    isActive: boolean = true;
    isReusable: boolean = true;
    createdAt: string = '';
    usageLimit: number = 0;
    usedCount: number = 0;
    blockedSections: string[] = [];
    blockedCategories: string[] = [];
    blockedSubCategories: string[] = [];
    blockedProducts: string[] = [];
    discount: DiscountModel = new DiscountModel();
    activateBy: string[] = [];

    constructor(coupon?: CouponModel) {
        if (coupon) {
            this._id = coupon._id;
            this.code = coupon.code;
            this.minAmount = coupon.minAmount;
            this.blockedSections = coupon.blockedSections;
            this.blockedCategories = coupon.blockedCategories;
            this.blockedSubCategories = coupon.blockedSubCategories;
            this.blockedProducts = coupon.blockedProducts;
            this.activateBy = coupon.activateBy;
            this.discount = coupon.discount;
            this.usedCount = coupon.usedCount;
            this.usageLimit = coupon.usageLimit;
            this.createdAt = coupon.createdAt;
            this.isReusable = coupon.isReusable;
            this.isActive = coupon.isActive;
            this.expirationDate = coupon.expirationDate;
        }
    }
}
