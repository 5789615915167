import ClientConfig from '../config/index';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const userPrefix = 'userManagement';

class UsersFetcher extends BaseHttpFetcher {
    async getAllUsers(): Promise<any> {
        return await this.get(`${userPrefix}/users`);
    }

    async getUserById(userId: string): Promise<any> {
        return await this.get(`${userPrefix}/users/${userId}`);
    }
}

const usersFetcherFetcherInstance = new UsersFetcher('/', ClientConfig.apiBaseHost);
export default usersFetcherFetcherInstance;
