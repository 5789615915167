import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IButton from '../../components/commonComponents/IButton/IButton';
import ITextInput from '../../components/commonComponents/ITextInput/TextInput';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import ITable from '../../components/commonComponents/table/ITable';
import { RoutesUrl } from '../../router/RoutesUrls';
import { COUPONS_STORE } from '../../stores/constants';
import rootStores from '../../stores/index';
import CouponsStore from '../../stores/coupons/Coupons.store';
import commonStyles from '../../styles/commonStyles.module.scss';
import couponsStyle from './Coupons.module.scss';
import CouponsColumns from './CouponsColumns';
import CouponModel from '../../models/order/CouponModel.model';
import { CButton, CVariant } from '../../constants/constants';
import { useMediaQuery } from 'react-responsive';
import CouponMiniCard from '../../components/couponMiniCard/CouponMiniCard';

const couponsStore = rootStores[COUPONS_STORE] as CouponsStore;

const Coupons = observer(() => {
    const [couponCodeSearch, setCouponCodeSearch] = useState<string>('');

    const { getAllCoupons, coupons, setSelectedCoupon, isLoading } = couponsStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns = CouponsColumns();

    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const filteredRows: CouponModel[] = coupons?.filter((row) => row.code.toLowerCase().includes(couponCodeSearch.toLowerCase()));

    const selectCouponHandler = (selectedProduct: CouponModel) => {
        setSelectedCoupon(selectedProduct);
        navigate(`${RoutesUrl.coupons}/${selectedProduct._id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getAllCoupons();
            } catch (error) {}
        };

        fetchData();
    }, [getAllCoupons]);

    return (
        <div className={couponsStyle.couponsContainer}>
            <div className={couponsStyle.header}>
                <IButton
                    onClick={() => {
                        setSelectedCoupon(new CouponModel());
                        navigate(RoutesUrl.createCoupon);
                    }}
                    title={t('coupons.createNewCoupon')}
                    className={`${commonStyles.defaultButton}`}
                />
                <span>{t('coupons.findCouponBy')}</span>
                <div>
                    <ITextInput
                        size={CButton.SMALL}
                        value={couponCodeSearch}
                        handleOnChange={(e) => setCouponCodeSearch(e.target.value)}
                        variant={CVariant.OUTLINED}
                        placeholder={t('coupons.couponCode')}
                    />
                </div>
            </div>
            <div className='products-data-grid-container'>
                {!isMobileScreen && (
                    <ITable columns={columns} rows={filteredRows} checkboxSelection={false} setSelectedRow={selectCouponHandler} hidePagination />
                )}
                {isMobileScreen && filteredRows.map((coupon) => <CouponMiniCard key={coupon._id} coupon={coupon} />)}
            </div>
            {<Spinner /> && isLoading}
        </div>
    );
});

export default Coupons;
