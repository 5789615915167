import { useTranslation } from 'react-i18next';
import SiteSettingsBox from '../../components/siteSettingsBox/SiteSettingsBox';
import settingsStyle from './Settings.module.scss';
import StoreSettingsForm from '../../components/settingsForms/storeSettingsForm/StoreSettingsForm';
import OrdersShippingForm from '../../components/settingsForms/ordersShippingForm/OrdersShippingForm';
import WebsiteForm from '../../components/settingsForms/websiteForm/WebsiteForm';

const Settings = () => {
    const { t } = useTranslation();
    return (
        <div className={settingsStyle.settingsContainer}>
            <div className={`${settingsStyle.settingsRow} row`}>
                <div className={`${settingsStyle.setteingsCol} col-12 col-md-6`}>
                    <SiteSettingsBox title={t('settings.shippingAndOrders')}>
                        <OrdersShippingForm />
                    </SiteSettingsBox>
                </div>
                <div className={`${settingsStyle.setteingsCol} col-12 col-md-6`}>
                    <SiteSettingsBox title={t('settings.storeSettings')}>
                        <StoreSettingsForm />
                    </SiteSettingsBox>
                </div>
            </div>
            <div className={`${settingsStyle.settingsRow} row`}>
                <div className={`${settingsStyle.setteingsCol} col-12 col-md-6`}>
                    <SiteSettingsBox title={t('settings.siteSettings')}>
                        <WebsiteForm />
                    </SiteSettingsBox>
                </div>
                <div className={`${settingsStyle.setteingsCol} col-12 col-md-6`}>
                    <SiteSettingsBox title={t('settings.additionalSettings')}>
                        <div></div>
                    </SiteSettingsBox>
                </div>
            </div>
        </div>
    );
};

export default Settings;
