import { ReactComponent as DeliverIcon } from '../../assets/icons/deliver.svg';
import { ReactComponent as PickupIcon } from '../../assets/icons/pickup.svg';

interface IProps {
    className: string;
    icon: string;
}

const DeliveryMethodIcon = (props: IProps) => {
    if (props.icon === 'delivery') {
        return <DeliverIcon className={props.className} />;
    } else return <PickupIcon className={props.className} />;
};

export default DeliveryMethodIcon;
