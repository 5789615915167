import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { CCoupons, CErrors, CToastUtil } from '../../constants/constants';
import CouponsFetcher from '../../fetchers/Coupons.fetcher';
import ToastUtil from '../../utils/ToastUtils';
import CouponModel from '../../models/order/CouponModel.model';
import { IToggleOption } from '../../interfaces/interfaces';
import { EDiscount } from '../../enums/enums';

class CouponsStore {
    @observable
    coupons: CouponModel[] = [];

    @observable
    selectedCoupon: CouponModel = new CouponModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'CouponsStore',
            properties: ['coupons', 'selectedCoupon'],
            storage: window.localStorage,
        });
    }

    @action
    setCoupons = (coupons: CouponModel[]) => (this.coupons = coupons);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    setSelectedCoupon = (selectedCoupon: CouponModel) => (this.selectedCoupon = selectedCoupon);

    @action
    getAllCoupons = async () => {
        try {
            this.setIsLoading(true);
            let { data } = await CouponsFetcher.getAllCoupons();
            const coupons: CouponModel[] = data?.map((coupon: CouponModel) => new CouponModel(coupon));
            this.setCoupons(coupons);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateCoupon = async (coupon: CouponModel) => {
        try {
            this.setIsLoading(true);

            let { data } = await CouponsFetcher.updateCoupon(coupon);

            const index = this.coupons.findIndex((coupon) => coupon._id === data.coupon._id);

            const newCoupons: CouponModel[] = [...this.coupons];
            const updatedCoupon = new CouponModel(data.coupon);

            if (index !== -1) {
                newCoupons[index] = updatedCoupon;
            } else {
                newCoupons.push(updatedCoupon);
            }

            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setCoupons(newCoupons);
            this.setSelectedCoupon(updatedCoupon);
            return true;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    createCoupon = async (coupon: CouponModel) => {
        try {
            this.setIsLoading(true);
            let { data } = await CouponsFetcher.createCoupon(coupon);
            const newCoupon = new CouponModel(data.coupon);
            const newCoupons: CouponModel[] = [...this.coupons, newCoupon];
            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setCoupons(newCoupons);
            this.setSelectedCoupon(newCoupon);
            return true;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    get getSelectedCouponDiscountAsIToggle(): IToggleOption {
        const type = this.selectedCoupon.discount.type;
        return {
            value: type,
            label: type === CCoupons.FIXED ? EDiscount.Shekel : EDiscount.Percentage,
        };
    }
}

export default CouponsStore;
