import AuthStore from './auth/Auth.store';
import FilterStore from './filter/Filter.store';
import OrdersStore from './orders/Orders.store';
import StoreInformationStore from './storeInformation/StoreInformation.store';
import UserStore from './user/User.store';
import UsersStore from './users/Users.store';
import ProductsStore from './products/Products.store';
import SectionsStore from './sections/Sections.store';
import SystemStore from './system/System.store';
import CouponsStore from './coupons/Coupons.store';

import {
    AUTH_STORE,
    FILTER_STORE,
    ORDERS_STORE,
    PRODUCTS_STORE,
    STORE_INFORMATION_STORE,
    USER_STORE,
    USERS_STORE,
    SECTIONS_STORE,
    SYSTEM_STORE,
    COUPONS_STORE,
} from './constants';

const userStore = new UserStore();
const authStore = new AuthStore(userStore);
const ordersStore = new OrdersStore();
const usersStore = new UsersStore();
const storeInformationStore = new StoreInformationStore();
const filterStore = new FilterStore();
const productsStore = new ProductsStore();
const sectionsStore = new SectionsStore();
const systemStore = new SystemStore();
const couponsStore = new CouponsStore();

const rootStores = {
    [AUTH_STORE]: authStore,
    [USER_STORE]: userStore,
    [ORDERS_STORE]: ordersStore,
    [USERS_STORE]: usersStore,
    [STORE_INFORMATION_STORE]: storeInformationStore,
    [FILTER_STORE]: filterStore,
    [PRODUCTS_STORE]: productsStore,
    [SECTIONS_STORE]: sectionsStore,
    [SYSTEM_STORE]: systemStore,
    [COUPONS_STORE]: couponsStore,
};

// Debugging purpose
if (process.env.NODE_ENV !== 'production') {
    (window as any)['stores'] = rootStores;
}

export default rootStores;
