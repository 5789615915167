import { ITableColumn } from '../../interfaces/interfaces';
import { CCoupons } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import StatusIndicator from '../../components/statusIndicator/StatusIndicator';
import { EDiscount } from '../../enums/enums';
import { formatDateOnly } from '../../utils/dateUtils';

const CouponsColumns = () => {
    const { t } = useTranslation();

    const columns: ITableColumn[] = [
        { field: CCoupons.CODE, headerName: t('coupons.couponCode'), align: 'center', headerAlign: 'center', flex: 1 },
        {
            field: CCoupons.DISCOUNT,
            headerName: t('coupons.discount'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) =>
                `${
                    e.value.type === CCoupons.FIXED
                        ? `${EDiscount.Shekel}${e.value.amount.toLocaleString()}`
                        : `${e.value.amount.toLocaleString()}${EDiscount.Percentage}`
                }`,
        },
        {
            field: CCoupons.CREATED_AT,
            headerName: t('coupons.createdAt'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => formatDateOnly(e.value),
        },
        {
            field: CCoupons.EXPIRATION_DATE,
            headerName: t('coupons.expirationDate'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => formatDateOnly(e.value),
        },

        {
            field: CCoupons.IS_ACTIVE,
            headerName: t('coupons.isActive'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => <StatusIndicator isActive={e.value} />,
        },
        {
            field: CCoupons.IS_REUSABLE,
            headerName: t('coupons.isReusable'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            minWidth: 170,
            renderCell: (e: any) => <StatusIndicator isActive={e.value} />,
        },
        {
            field: CCoupons.MIN_AMONT,
            headerName: t('coupons.minAmount'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => `${EDiscount.Shekel}${e.value.toLocaleString()}`,
            minWidth: 170,
        },
        {
            field: CCoupons.USAGE_LIMIT,
            headerName: t('coupons.usageLimit'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => e.value.toLocaleString(),
            minWidth: 170,
        },
        {
            field: CCoupons.USED_COUNT,
            headerName: t('coupons.usedCount'),
            align: 'center',
            headerAlign: 'center',
            flex: 1,
            renderCell: (e: any) => e.value.toLocaleString(),
        },
    ];

    return columns;
};

export default CouponsColumns;
