import { useNavigate } from 'react-router-dom';
import couponMiniCardStyle from './CouponMiniCard.module.scss';
import { RoutesUrl } from '../../router/RoutesUrls';
import CouponModel from '../../models/order/CouponModel.model';
import { COUPONS_STORE } from '../../stores/constants';
import rootStores from '../../stores/index';
import CouponsStore from '../../stores/coupons/Coupons.store';
import { CCoupons } from '../../constants/constants';
import { EDiscount } from '../../enums/enums';

interface IProps {
    coupon: CouponModel;
}

const couponsStore = rootStores[COUPONS_STORE] as CouponsStore;

const CouponMiniCard = (props: IProps) => {
    const { coupon } = props;

    const navigate = useNavigate();

    const { setSelectedCoupon } = couponsStore;

    const selectCouponHandler = () => {
        setSelectedCoupon(coupon);
        navigate(`${RoutesUrl.coupons}/${coupon._id}`);
    };

    return (
        <div onClick={selectCouponHandler} className={couponMiniCardStyle.couponMiniCardContainer}>
            <span>{coupon.code}</span>
            <span>
                {coupon.discount.type === CCoupons.FIXED
                    ? `${EDiscount.Shekel}${coupon.discount.amount.toLocaleString()}`
                    : `${coupon.discount.amount.toLocaleString()}${EDiscount.Percentage}`}
            </span>
        </div>
    );
};

export default CouponMiniCard;
