const productForm = {
    login: 'התחבר',
    editProduct: 'ערוך מוצר',
    cancelEditMode: 'ביטול עריכה',
    save: 'שמירת שינויים',
    productName: 'שם המוצר',
    serialNumber: 'מק"ט',
    price: 'מחיר',
    discount: 'הנחה',
    requiredField: 'שדה חובה',
    numbersOnly: 'מחיר לא תקין',
    description: 'תיאור המוצר',
    inStock: 'זמין במלאי',
    available: 'זמין',
    yes: 'כן',
    no: 'לא',
    unavailable: 'לא זמין',
    section: 'נושא',
    hot: 'מוצר חם',
    category: 'קטגוריה',
    image: 'תמונה',
    subCategory: 'תת-קטגוריה',
    invalidDiscountAmount: 'מחיר הנחה לא תקין',
    notZero: 'נא להזין סכום',
    isVisible: 'הצגת מוצר בחנות',
    visible: 'הצג',
    invisible: 'אל תציג',
    variantName: 'שם המאפיין',
    variantValueName: 'שם הערך',
    extraPrice: 'תוספת למחיר המוצר',
    delete: 'מחק',
    deleteVariant: 'מחק מאפיין',
    addValue: 'הוסף ערך',
    addVariant: 'הוסף מאפיין',
    missingFields: 'נא למלא את השדות החסרים',
    variants: 'מאפיינים',
    noVariants: 'לא קיימים מאפיינים',
};

export default productForm;
