const managementOptions = {
    managementOptions: 'אפשרויות ניהול',
    productsManagement: 'מוצרים',
    ordersManagement: 'הזמנות',
    categories: 'קטגוריות',
    customersManagement: 'לקוחות',
    coupons: 'קופונים',
    emailManagement: 'ניהול מיילים',
    settings: 'הגדרות אתר',
};

export default managementOptions;
