const couponForm = {
    notZero: 'נא להזין סכום',
    expirationDate: 'תאריך תפוגה',
    createdAt: 'תאריך יצירה',
    couponCode: 'קוד קופון',
    value: 'שווי',
    status: 'סטאטוס',
    save: 'שמירה',
    editCoupon: 'עריכת קופון',
    cancelEditMode: 'ביטול עריכה',
    active: 'פעיל',
    inActive: 'לא פעיל',
    reusable: 'שימוש חוזר בקופון',
    isReusable: 'ניתן לשימוש חוזר',
    isNotReusable: 'לא ניתן לשימוש חוזר',
    numbersOnly: 'מספרים בלבד',
    requiredField: 'שדה חובה',
    minAmount: 'סכום הזמנה מינימלי',
    usageLimit: 'כמות מקסימלית לשימוש',
    usedCount: 'מספר שימושים',
    nonNegativeNumber: 'מספרים חיוביים ושלמים בלבד ',
    noSpaces: 'נא להסיר רווחים',
};

export default couponForm;
