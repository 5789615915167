import { useState } from 'react';
import { CButton, CProducts, CVariant } from '../../constants/constants';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import variantFormStyle from './VariantForm.module.scss';
import { useTranslation } from 'react-i18next';
import IButton from '../commonComponents/IButton/IButton';
import commonStyles from '../../styles/commonStyles.module.scss';
import { ISelectOption, IToggleOption } from '../../interfaces/interfaces';
import IToggle from '../commonComponents/IToggle/IToggle';
import VariantOptionModel from '../../models/product/VariantOption.model';

interface IProps {
    type: string;
    variantOption: VariantOptionModel;
    onDeleteOption: (variantOption: VariantOptionModel) => void;
    onUpdateOption: (variantOption: VariantOptionModel) => void;
}

const VariantOption = (props: IProps) => {
    const { type, onDeleteOption, onUpdateOption, variantOption } = props;

    const { t } = useTranslation();

    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedOption = { ...variantOption, value: e.target.value };
        onUpdateOption(updatedOption);
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedOption = { ...variantOption, extraPrice: Number(e.target.value) };
        onUpdateOption(updatedOption);
    };

    const handleStockChange = (option: IToggleOption) => {
        const updatedOption = { ...variantOption, inStock: option.value === CProducts.IN_STOCK };
        onUpdateOption(updatedOption);
    };

    const stockOptions: ISelectOption[] = [
        { value: CProducts.IN_STOCK, label: t('productForm.available') },
        { value: CProducts.OUT_OF_STOCK, label: t('productForm.unavailable') },
    ];

    return (
        <div className={variantFormStyle.variantOptionContainer}>
            <div>
                <ITextInput
                    inputLabel={type}
                    size={CButton.SMALL}
                    handleOnChange={handleValueChange}
                    variant={CVariant.OUTLINED}
                    value={variantOption.value}
                />
            </div>
            <div className={variantFormStyle.priceContainer}>
                <ITextInput
                    inputLabel={t('productForm.extraPrice')}
                    size={CButton.SMALL}
                    handleOnChange={handlePriceChange}
                    variant={CVariant.OUTLINED}
                    value={String(variantOption.extraPrice)}
                    showCurrency={true}
                />
            </div>
            <div className={variantFormStyle.inStockContainer}>
                <IToggle
                    options={stockOptions}
                    defaultSelected={{
                        value: variantOption.inStock ? CProducts.IN_STOCK : CProducts.OUT_OF_STOCK,
                        label: variantOption.inStock ? t('productForm.available') : t('productForm.unavailable'),
                    }}
                    onChange={handleStockChange}
                />
            </div>
            <div className={variantFormStyle.deleteButtonContainer}>
                <IButton
                    title={`${t('productForm.delete')} ${type}`}
                    className={commonStyles.defaultButton}
                    onClick={() => onDeleteOption(variantOption)}
                />
            </div>
        </div>
    );
};

export default VariantOption;
