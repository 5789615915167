import SectionCollapse from '../../components/sectionCollapse/SectionCollapse';
import categoriesStyle from './Categories.module.scss';
import rootStores from '../../stores/index';
import { SECTIONS_STORE } from '../../stores/constants';
import SectionsStore from '../../stores/sections/Sections.store';
import { observer } from 'mobx-react';
import IButton from '../../components/commonComponents/IButton/IButton';
import { useTranslation } from 'react-i18next';
import commonStyles from '../../styles/commonStyles.module.scss';
import { useEffect, useState } from 'react';
import AddCategoryForm from '../../components/addCategoryForm/AddCategoryForm';
import SectionModel from '../../models/topics/Section.model';
import Spinner from '../../components/commonComponents/spinner/Spinner';

const sectionsStore = rootStores[SECTIONS_STORE] as SectionsStore;

const Categories = observer(() => {
    const { sections, createSection, getSections, isLoading } = sectionsStore;
    const [isAddSectionMode, setIsAddSectionMode] = useState<boolean>(false);

    const { t } = useTranslation();

    const submitAddSectionFormHandler = async (sectionName: string) => {
        await createSection(
            new SectionModel({
                sectionId: '',
                sectionName,
            })
        );
        setIsAddSectionMode(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getSections();
            } catch (error) {}
        };

        fetchData();
    }, [getSections]);
    return (
        <div className={`${categoriesStyle.categoriesContainer} ${isLoading ? 'blur-background' : ''}`}>
            <div className={categoriesStyle.addSectionContainer}>
                <IButton
                    title={t('categories.addSection')}
                    className={commonStyles.defaultButton}
                    onClick={() => {
                        setIsAddSectionMode(true);
                    }}
                />
                {isAddSectionMode && (
                    <AddCategoryForm
                        submitFormHandler={submitAddSectionFormHandler}
                        closeFormHandler={() => {
                            setIsAddSectionMode(false);
                        }}
                    />
                )}
            </div>
            {sections?.map((section) => (
                <div className={categoriesStyle.sectionContainer} key={section.sectionId}>
                    <SectionCollapse section={section} />
                </div>
            ))}
            {isLoading && <Spinner />}
        </div>
    );
});

export default Categories;
