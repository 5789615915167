import ProductModel from '../../models/product/Product.model';
import productMiniCardStyle from './ProductMiniCard.module.scss';

interface IProps {
    product: ProductModel;
    selectProductHandler: (product: ProductModel) => void;
}

const ProductMiniCard = (props: IProps) => {
    const { product, selectProductHandler } = props;

    return (
        <div
            className={productMiniCardStyle.productMiniCardContainer}
            onClick={() => {
                selectProductHandler(product);
            }}
        >
            <div className={productMiniCardStyle.imageContainer}>
                <img src={product.images[0]} alt={product.name} className={productMiniCardStyle.productImage} />
            </div>
            <div className={productMiniCardStyle.titleContainer}>
                <p>{product.serialNumber}</p>
                <p>{product.name}</p>
            </div>
        </div>
    );
};

export default ProductMiniCard;
