import VariantOptionModel from './VariantOption.model';

export default class ProductsVariantModel {
    id: string = '';

    type: string = '';

    label: string = '';

    options: VariantOptionModel[] = [];

    constructor(productsVariant?: ProductsVariantModel) {
        if (productsVariant) {
            this.id = productsVariant.id;
            this.type = productsVariant.type;
            this.label = productsVariant.label;
            this.options = productsVariant.options.map((option) => new VariantOptionModel(option));
        }
    }
}
