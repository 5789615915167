import { useTranslation } from 'react-i18next';
import welcomeMessageTemplateStyle from './WelcomeMessageTemplate.module.scss';

interface IProps {
    contextMessage: string;
    subjectMessage: string;
}

const WelcomeMessageTemplate = (props: IProps) => {
    const { contextMessage, subjectMessage } = props;
    const { t } = useTranslation();

    return (
        <div className={welcomeMessageTemplateStyle.welcomeMessageTemplateContainer}>
            <div className={welcomeMessageTemplateStyle.subjectContainer}>
                <span>
                    {t('welcomeMessageForm.subject')}: {subjectMessage}
                </span>
            </div>
            <span>{t('welcomeMessageForm.viewOnly')}</span>
            <div className={welcomeMessageTemplateStyle.messageContainer}>
                <div className={welcomeMessageTemplateStyle.titleContainer}>
                    <span>{t('welcomeMessageForm.welcomeTitle')}</span>
                </div>
                <div className={welcomeMessageTemplateStyle.contextMessageContainer}>
                    <span>{contextMessage}</span>
                </div>
                <div className={welcomeMessageTemplateStyle.buttonContainer}>
                    <div className={welcomeMessageTemplateStyle.button}>
                        <span>{t('welcomeMessageForm.siteButton')}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeMessageTemplate;
