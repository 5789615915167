import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { observer } from 'mobx-react';
import './ITable.customization.css';
import tableStyles from './ITable.module.scss';

interface IProps {
    columns: GridColDef[];
    rows: any[];
    selectedRows?: any;
    setSelectedRow?: any;
    setSelectedRows?: any;
    setSelectedTableItems?: any;
    hidePagination?: boolean;
    checkboxSelection?: boolean;
}

const ITable = observer((props: IProps) => {
    const { columns, rows, selectedRows, setSelectedRow, setSelectedRows, hidePagination, checkboxSelection } = props;

    const handleSelectionChange = (newSelectionIds: GridRowSelectionModel) => setSelectedRows(newSelectionIds);

    const handleCellClick = (param, event) => param.field === 'menu' && event.stopPropagation();

    const dataGridKey = rows.length;

    return (
        <div className={tableStyles.container}>
            <DataGrid
                key={dataGridKey}
                className={tableStyles.table}
                getRowId={(row) => row._id}
                rows={rows}
                columns={columns}
                checkboxSelection={checkboxSelection}
                disableRowSelectionOnClick={true}
                rowSelectionModel={selectedRows}
                onCellClick={handleCellClick}
                onRowSelectionModelChange={handleSelectionChange}
                onRowClick={(rows) => setSelectedRow && setSelectedRow(rows.row)}
                hideFooterPagination={hidePagination}
                disableColumnMenu={true}
                rowHeight={80}
                sx={{
                    '& .MuiDataGrid-cell': {},
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                        color: 'none',
                    },
                }}
            />
        </div>
    );
});

export default ITable;
