import { ITableColumn } from '../../interfaces/interfaces';
import { CUsers } from '../../constants/constants';
import { useTranslation } from 'react-i18next';
import { formatDateOnly } from '../../utils/dateUtils';

const UsersColumns = () => {
    const { t } = useTranslation();

    const columns: ITableColumn[] = [
        { field: CUsers.FIRST_NAME, headerName: t('users.firstName'), flex: 1, align: 'center', headerAlign: 'center' },
        {
            field: CUsers.LAST_NAME,
            headerName: t('users.lastName'),
            flex: 1,
            align: 'center',
            headerAlign: 'center',
            renderCell: (e: any) => (e.value ? e.value : '--'),
        },
        { field: CUsers.EMAIL, headerName: t('users.email'), flex: 1, align: 'center', headerAlign: 'center' },
        { field: CUsers.PHONE_NUMBER, headerName: t('users.phoneNumber'), flex: 1, align: 'center', headerAlign: 'center' },
        {
            field: CUsers.DATE_OF_BIRTH,
            headerName: t('users.dateOfBirth'),
            flex: 1,
            renderCell: (e: any) => formatDateOnly(e.value),
            align: 'center',
            headerAlign: 'center',
        },
    ];

    return columns;
};

export default UsersColumns;
