const welcomeMessageForm = {
    subject: 'נושא',
    contextMessage: 'תוכן ההודעה',
    siteButton: 'מעבר לאתר',
    welcomeTitle: 'ברוכים הבאים, (שם הלקוח)',
    requiredField: 'שדה חובה',
    update: 'עדכן',
    viewOnly: '*תצוגה מקדימה של ההודעה הנשלחת בדואר אלקטרוני*',
};

export default welcomeMessageForm;
