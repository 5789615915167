const user = {
    back: 'חזרה',
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    email: 'דואר אלקטרוני',
    phoneNumber: 'מספר טלפון',
    addresses: 'כתובות למשלוח',
    dateOfBirth: 'תאריך לידה',
    ordersHistory: 'היסטוריית הזמנות',
    ordersNotFound: 'לא נמצאו הזמנות',
    password: 'סיסמא',
};

export default user;
