import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import couponFormStyles from './CouponForm.module.scss';
import { useTranslation } from 'react-i18next';
import IButton from '../commonComponents/IButton/IButton';
import commonStyles from '../../styles/commonStyles.module.scss';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import StatusIndicator from '../statusIndicator/StatusIndicator';
import rootStores from '../../stores';
import { COUPONS_STORE } from '../../stores/constants';
import CouponsStore from '../../stores/coupons/Coupons.store';
import IToggle from '../commonComponents/IToggle/IToggle';
import { IToggleOption } from '../../interfaces/interfaces';
import { CVariant, CButton, CCoupons } from '../../constants/constants';
import { EDiscount } from '../../enums/enums';
import { formatDateOnly } from '../../utils/dateUtils';
import CouponModel from '../../models/order/CouponModel.model';
import DiscountModel from '../../models/order/DiscountModel.model';
import IDatePicker from '../commonComponents/datePicker/IDatePicker';
import dayjs from 'dayjs';

const couponsStore = rootStores[COUPONS_STORE] as CouponsStore;

const CouponForm = observer(() => {
    const { selectedCoupon, getSelectedCouponDiscountAsIToggle, updateCoupon, createCoupon } = couponsStore;

    const isCreatedMode = selectedCoupon.code ? false : true;
    const [isEditMode, setIsEditMode] = useState<boolean>(isCreatedMode ? true : false);

    const { t } = useTranslation();

    const isReusableOptions: IToggleOption[] = [
        { value: CCoupons.IS_REUSABLE, label: t('couponForm.isReusable') },
        { value: CCoupons.IS_NOT_REUSABLE, label: t('couponForm.isNotReusable') },
    ];

    const isActiveOptions: IToggleOption[] = [
        { value: CCoupons.IS_ACTIVE, label: t('couponForm.active') },
        { value: CCoupons.IS_INACTIVE, label: t('couponForm.inActive') },
    ];

    const discountOptions: IToggleOption[] = [
        { label: EDiscount.Percentage, value: CCoupons.PERCENTAGE },
        { label: EDiscount.Shekel, value: CCoupons.FIXED },
    ];

    const validationSchema = yup.object().shape({
        code: yup.string().required(t('couponForm.requiredField')).matches(/^\S*$/, t('couponForm.noSpaces')),
        expirationDate: yup.date().nullable().required(t('couponForm.requiredField')),
        usageLimit: yup
            .number()
            .integer(t('couponForm.nonNegativeNumber'))
            .typeError(t('couponForm.numbersOnly'))
            .min(0, t('couponForm.nonNegativeNumber'))
            .test('not-zero', t('couponForm.notZero'), (value) => {
                return value !== 0;
            })
            .required(t('couponForm.requiredField')),
        minAmount: yup
            .number()
            .typeError(t('couponForm.numbersOnly'))
            .min(0, t('couponForm.nonNegativeNumber'))
            .required(t('couponForm.requiredField')),
        discountAmount: yup
            .number()
            .typeError(t('couponForm.numbersOnly'))
            .min(0, t('couponForm.nonNegativeNumber'))
            .test('not-zero', t('couponForm.notZero'), (value) => {
                return value !== 0;
            })
            .required(t('couponForm.requiredField'))
            .test('valid-discount-amount', t('couponForm.invalidDiscountAmount'), function (value) {
                const { discountType } = this.parent;
                if (discountType === CCoupons.PERCENTAGE) {
                    return value < 100;
                }
                return true;
            }),
    });

    const formik = useFormik({
        initialValues: {
            code: selectedCoupon.code,
            discountAmount: selectedCoupon.discount.amount,
            discountType: selectedCoupon.discount.type,
            expirationDate: new Date(selectedCoupon.expirationDate),
            minAmount: selectedCoupon.minAmount,
            isActive: selectedCoupon.isActive,
            isReusable: selectedCoupon.isReusable,
            usageLimit: selectedCoupon.usageLimit,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const coupon = new CouponModel({
                _id: isCreatedMode ? '' : selectedCoupon._id,
                code: isCreatedMode ? values.code : selectedCoupon.code,
                activateBy: [],
                blockedCategories: [],
                blockedProducts: [],
                blockedSections: [],
                blockedSubCategories: [],
                discount: new DiscountModel({
                    type: isCreatedMode ? values.discountType : selectedCoupon.discount.type,
                    amount: isCreatedMode ? Number(values.discountAmount) : selectedCoupon.discount.amount,
                }),
                createdAt: isCreatedMode ? '' : selectedCoupon.createdAt,
                expirationDate: isCreatedMode ? values.expirationDate.toISOString() : selectedCoupon.expirationDate,
                usedCount: isCreatedMode ? 0 : selectedCoupon.usedCount,
                isActive: values.isActive,
                isReusable: values.isReusable,
                minAmount: Number(values.minAmount),
                usageLimit: Number(values.usageLimit),
            });

            const success = isCreatedMode ? await createCoupon(coupon) : await updateCoupon(coupon);
            if (success) {
                setIsEditMode(false);
            }
        },
    });

    const onDiscountTypeChanged = (option: IToggleOption) => {
        formik.setFieldValue(CCoupons.DISCOUNT_TYPE, option.value);
    };
    const onIsReusableChanged = (option: IToggleOption) => {
        formik.setFieldValue(CCoupons.IS_REUSABLE, option.value === CCoupons.IS_REUSABLE ? true : false);
    };
    const onIsActiveChanged = (option: IToggleOption) => {
        formik.setFieldValue(CCoupons.IS_ACTIVE, option.value === CCoupons.IS_ACTIVE ? true : false);
    };

    useEffect(() => {
        formik.setValues({
            code: selectedCoupon.code,
            discountAmount: selectedCoupon.discount.amount,
            discountType: selectedCoupon.discount.type,
            expirationDate: new Date(selectedCoupon.expirationDate),
            minAmount: selectedCoupon.minAmount,
            isActive: selectedCoupon.isActive,
            isReusable: selectedCoupon.isReusable,
            usageLimit: selectedCoupon.usageLimit,
        });
    }, [isEditMode]);

    return (
        <div className={couponFormStyles.couponFormContainer}>
            <form onSubmit={formik.handleSubmit}>
                <div className={couponFormStyles.actionsContainer}>
                    {!isCreatedMode && (
                        <IButton
                            title={isEditMode ? t('couponForm.cancelEditMode') : t('couponForm.editCoupon')}
                            className={commonStyles.defaultButton}
                            onClick={() => {
                                setIsEditMode(!isEditMode);
                            }}
                        />
                    )}
                    {isEditMode && (
                        <IButton
                            title={t('couponForm.save')}
                            className={`${commonStyles.defaultButton} ${couponFormStyles.saveButton}`}
                            type={CButton.SUBMIT}
                        />
                    )}
                </div>
                <div className={`${couponFormStyles.inputsRowContainer} row`}>
                    <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={couponFormStyles.fieldTitleContainer}>
                            <span>{t('couponForm.couponCode')}</span>
                        </div>
                        <div className={couponFormStyles.inputContainer}>
                            {isEditMode && isCreatedMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CCoupons.CODE)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.code && Boolean(formik.errors.code)}
                                    helperText={formik.touched.code && formik.errors.code}
                                    value={formik.values.code}
                                    inputProps={{ maxLength: '10' }}
                                />
                            ) : (
                                <span>{selectedCoupon.code}</span>
                            )}
                        </div>
                    </div>
                    <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={couponFormStyles.fieldTitleContainer}>
                            <span>{t('couponForm.value')}</span>
                        </div>
                        <div className={couponFormStyles.inputContainer}>
                            {isEditMode && isCreatedMode ? (
                                <>
                                    <div style={{ flex: 1 }}>
                                        <ITextInput
                                            size={CButton.SMALL}
                                            handleOnChange={formik.handleChange(CCoupons.DISCOUNT_AMOUNT)}
                                            variant={CVariant.OUTLINED}
                                            error={formik.touched.discountAmount && Boolean(formik.errors.discountAmount)}
                                            helperText={formik.touched.discountAmount && formik.errors.discountAmount}
                                            value={formik.values.discountAmount}
                                        />
                                    </div>
                                    <div>
                                        <IToggle
                                            options={discountOptions}
                                            defaultSelected={getSelectedCouponDiscountAsIToggle}
                                            onChange={onDiscountTypeChanged}
                                        />
                                    </div>
                                </>
                            ) : (
                                <span>
                                    {selectedCoupon.discount.type === CCoupons.PERCENTAGE
                                        ? `${selectedCoupon.discount.amount}${EDiscount.Percentage}`
                                        : `${EDiscount.Shekel}${selectedCoupon.discount.amount}`}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={couponFormStyles.fieldTitleContainer}>
                            <span>{t('couponForm.expirationDate')}</span>
                        </div>
                        <div className={couponFormStyles.inputContainer}>
                            {isEditMode && isCreatedMode ? (
                                <IDatePicker
                                    value={dayjs(formik.values.expirationDate)}
                                    disablePast
                                    handleOnChange={(value) => formik.setFieldValue(CCoupons.EXPIRATION_DATE, value)}
                                    helperText={
                                        formik.touched.expirationDate && formik.errors.expirationDate
                                            ? formik.values.expirationDate
                                                ? t('couponForm.requiredField')
                                                : t('couponForm.requiredField')
                                            : ''
                                    }
                                />
                            ) : (
                                <span>{formatDateOnly(selectedCoupon.expirationDate)}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${couponFormStyles.inputsRowContainer} row`}>
                    <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={couponFormStyles.fieldTitleContainer}>
                            <span>{t('couponForm.minAmount')}</span>
                        </div>
                        <div className={couponFormStyles.inputContainer}>
                            {isEditMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CCoupons.MIN_AMONT)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.minAmount && Boolean(formik.errors.minAmount)}
                                    helperText={formik.touched.minAmount && formik.errors.minAmount}
                                    value={formik.values.minAmount}
                                    showCurrency={true}
                                    inputProps={{ maxLength: '5' }}
                                />
                            ) : (
                                <span>
                                    {EDiscount.Shekel}
                                    {selectedCoupon.minAmount.toLocaleString()}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={couponFormStyles.fieldTitleContainer}>
                            <span>{t('couponForm.usageLimit')}</span>
                        </div>
                        <div className={couponFormStyles.inputContainer}>
                            {isEditMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CCoupons.USAGE_LIMIT)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.usageLimit && Boolean(formik.errors.usageLimit)}
                                    helperText={formik.touched.usageLimit && formik.errors.usageLimit}
                                    value={formik.values.usageLimit}
                                    inputProps={{ maxLength: '5' }}
                                />
                            ) : (
                                <span>{selectedCoupon.usageLimit.toLocaleString()}</span>
                            )}
                        </div>
                    </div>
                    <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={couponFormStyles.fieldTitleContainer}>
                            <span>{t('couponForm.reusable')}</span>
                        </div>
                        <div className={couponFormStyles.inputContainer}>
                            {isEditMode ? (
                                <>
                                    <div>
                                        <IToggle
                                            options={isReusableOptions}
                                            defaultSelected={{
                                                value: selectedCoupon.isReusable ? CCoupons.IS_REUSABLE : CCoupons.IS_NOT_REUSABLE,
                                                label: selectedCoupon.isReusable ? t('couponForm.isReusable') : t('couponForm.isNotReusable'),
                                            }}
                                            onChange={onIsReusableChanged}
                                        />
                                    </div>
                                </>
                            ) : (
                                <StatusIndicator isActive={selectedCoupon.isReusable} />
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${couponFormStyles.inputsRowContainer} row`}>
                    <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={couponFormStyles.fieldTitleContainer}>
                            <span>{t('couponForm.status')}</span>
                        </div>
                        <div className={couponFormStyles.inputContainer}>
                            {isEditMode ? (
                                <>
                                    <div>
                                        <IToggle
                                            options={isActiveOptions}
                                            defaultSelected={{
                                                value: selectedCoupon.isActive ? CCoupons.IS_ACTIVE : CCoupons.IS_INACTIVE,
                                                label: selectedCoupon.isActive ? t('couponForm.active') : t('couponForm.inActive'),
                                            }}
                                            onChange={onIsActiveChanged}
                                        />
                                    </div>
                                </>
                            ) : (
                                <StatusIndicator isActive={selectedCoupon.isActive} />
                            )}
                        </div>
                    </div>

                    {!isCreatedMode && (
                        <>
                            <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                                <div className={couponFormStyles.fieldTitleContainer}>
                                    <span>{t('couponForm.usedCount')}</span>
                                </div>
                                <div className={couponFormStyles.inputContainer}>
                                    <span>{selectedCoupon.usedCount.toLocaleString()}</span>
                                </div>
                            </div>
                            <div className={`${couponFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                                <div className={couponFormStyles.fieldTitleContainer}>
                                    <span>{t('couponForm.createdAt')}</span>
                                </div>
                                <div className={couponFormStyles.inputContainer}>
                                    <span>{formatDateOnly(selectedCoupon.createdAt)}</span>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </form>
        </div>
    );
});

export default CouponForm;
