import React, { useState } from 'react';
import ordersShippingFormStyle from './OrdersShippingForm.module.scss';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import SettingsFormInput from '../settingsFormInput/SettingsFormInput';
import { COrdersShippingForm } from '../../../constants/constants';
import { EDiscount } from '../../../enums/enums';
import IButton from '../../commonComponents/IButton/IButton';
import commonStyles from '../../../styles/commonStyles.module.scss';
import { observer } from 'mobx-react';
import rootStores from '../../../stores';
import { SYSTEM_STORE } from '../../../stores/constants';
import SystemStore from '../../../stores/system/System.store';
import UpdateSystemFieldsPayload from '../../../models/system/UpdateSystemFieldsPayload.model';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const OrdersShippingForm = observer(() => {
    const {
        system: { ordering },
        updateSystemSettingsByFields,
    } = systemStore;

    const { t } = useTranslation();

    const shippingFee = COrdersShippingForm.SHIPPING_FEE;
    const minAmountForFreeShipping = COrdersShippingForm.MIN_AMOUNT_FOR_FREE_SHIPPING;
    const maxDeliveryDays = COrdersShippingForm.MAX_DELIVERY_DAYS;

    const [editModes, setEditModes] = useState({
        [shippingFee]: false,
        [minAmountForFreeShipping]: false,
        [maxDeliveryDays]: false,
    });

    const validationSchema = {
        [shippingFee]: yup.object().shape({
            [shippingFee]: yup.string().required(t('settings.requiredField')).matches(/^\d+$/, t('settings.onlyNumbersAllowed')),
        }),
        [maxDeliveryDays]: yup.object().shape({
            [maxDeliveryDays]: yup.string().required(t('settings.requiredField')).matches(/^\d+$/, t('settings.onlyNumbersAllowed')),
        }),
        [minAmountForFreeShipping]: yup.object().shape({
            [minAmountForFreeShipping]: yup.string().required(t('settings.requiredField')).matches(/^\d+$/, t('settings.onlyNumbersAllowed')),
        }),
    };

    const openFormHandler = (fieldName: string) => {
        setEditModes({
            ...setEditModes,
            [fieldName]: true,
        });
    };

    const closeFormHandler = (fieldName: string) => {
        setEditModes({
            ...setEditModes,
            [fieldName]: false,
        });
    };

    const submitFormHandler = async (fieldToUpdate: string, updatedValue: string) => {
        closeFormHandler(fieldToUpdate);
        await updateSystemSettingsByFields(
            new UpdateSystemFieldsPayload({
                updates: {
                    [`${COrdersShippingForm.ORDERING}.${fieldToUpdate}`]: parseFloat(updatedValue),
                },
            })
        );
    };

    return (
        <div className={ordersShippingFormStyle.ordersShippingFormContainer}>
            <div className={`${ordersShippingFormStyle.ordersShippingFormRow} row`}>
                <div className={`${ordersShippingFormStyle.ordersShippingFormCol} col-5`}>
                    <span>{t('ordersShippingForm.shippingFee')}</span>
                </div>
                <div className={`${ordersShippingFormStyle.ordersShippingFormCol} col-7`}>
                    {editModes.shippingFee ? (
                        <SettingsFormInput
                            submitFormHandler={submitFormHandler}
                            closeFormHandler={closeFormHandler}
                            validationSchema={validationSchema.shippingFee}
                            fieldName={shippingFee}
                            defaultValue={ordering[shippingFee]}
                            showCurrency={true}
                        />
                    ) : (
                        <div className={ordersShippingFormStyle.valueContainer}>
                            <span>
                                {EDiscount.Shekel}
                                {ordering[shippingFee]}
                            </span>
                            <IButton
                                className={commonStyles.textButton}
                                title={t('settings.change')}
                                onClick={() => {
                                    openFormHandler(shippingFee);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={`${ordersShippingFormStyle.ordersShippingFormRow} row`}>
                <div className={`${ordersShippingFormStyle.ordersShippingFormCol} col-5`}>
                    <span>{t('ordersShippingForm.minAmountForFreeShipping')}</span>
                </div>
                <div className={`${ordersShippingFormStyle.ordersShippingFormCol} col-7`}>
                    {editModes.minAmountForFreeShipping ? (
                        <SettingsFormInput
                            submitFormHandler={submitFormHandler}
                            closeFormHandler={closeFormHandler}
                            validationSchema={validationSchema.minAmountForFreeShipping}
                            fieldName={minAmountForFreeShipping}
                            defaultValue={ordering[minAmountForFreeShipping]}
                            showCurrency={true}
                        />
                    ) : (
                        <div className={ordersShippingFormStyle.valueContainer}>
                            <span>
                                {EDiscount.Shekel}
                                {ordering[minAmountForFreeShipping]}
                            </span>
                            <IButton
                                className={commonStyles.textButton}
                                title={t('settings.change')}
                                onClick={() => {
                                    openFormHandler(minAmountForFreeShipping);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className={`${ordersShippingFormStyle.ordersShippingFormRow} row`}>
                <div className={`${ordersShippingFormStyle.ordersShippingFormCol} col-5`}>
                    <span>{t('ordersShippingForm.maxDeliveryDays')}</span>
                </div>
                <div className={`${ordersShippingFormStyle.ordersShippingFormCol} col-7`}>
                    {editModes.maxDeliveryDays ? (
                        <SettingsFormInput
                            submitFormHandler={submitFormHandler}
                            closeFormHandler={closeFormHandler}
                            validationSchema={validationSchema.maxDeliveryDays}
                            fieldName={maxDeliveryDays}
                            defaultValue={ordering[maxDeliveryDays]}
                        />
                    ) : (
                        <div className={ordersShippingFormStyle.valueContainer}>
                            <span>
                                {ordering[maxDeliveryDays]} {t('settings.days')}
                            </span>
                            <IButton
                                className={commonStyles.textButton}
                                title={t('settings.change')}
                                onClick={() => {
                                    openFormHandler(maxDeliveryDays);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default OrdersShippingForm;
