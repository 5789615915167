import OrderConfirmationMessageModel from './OrderConfirmationMessageModel';
import WelcomeMessageModel from './WelcomeMessageModel';

export default class EmailModel {
    welcome: WelcomeMessageModel = new WelcomeMessageModel();
    orderConfirmation: OrderConfirmationMessageModel = new OrderConfirmationMessageModel();
    constructor(emailModel?: EmailModel) {
        if (emailModel) {
            this.welcome = emailModel.welcome;
            this.orderConfirmation = emailModel.orderConfirmation;
        }
    }
}
