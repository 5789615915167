import { BallTriangle } from 'react-loader-spinner';
import spinnerStyle from './Spinner.module.scss';

const Spinner = () => {
    return (
        <div className={spinnerStyle.spinnerContainer}>
            <BallTriangle height={100} width={100} radius={5} color={'#d67a00'} visible={true} />
        </div>
    );
};

export default Spinner;
