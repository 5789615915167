import EnvUtils, { EnvKeys } from '../utils/envUtils';

const env = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_ENV);
const isProvider = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_IS_PROVIDER) === 'true';
const PROD_SERVER = EnvUtils.getEnvVariable(EnvKeys.REACT_APP_PROD_SERVER);

interface IConfigEnvironment {
    apiBaseHost: string;
    isProvider: boolean;
}

export interface IClientConfig extends IConfigEnvironment {}

const LOCAL_PORT = '4000';

const local: IConfigEnvironment = {
    apiBaseHost: `http://localhost:${LOCAL_PORT}/api`,
    isProvider,
};

const prod: IConfigEnvironment = {
    apiBaseHost: `https://${PROD_SERVER}/api`,
    isProvider,
};

const envConfigs = {
    local,
    prod,
};

if (env !== 'prod') console.log('Client started with env', env);

const ClientConfig: IClientConfig = {
    ...envConfigs[env as keyof typeof envConfigs],
};

export default ClientConfig;
