import informationCardStyle from './InformationCard.module.scss';
import { ReactNode } from 'react';

interface IProps {
    icon: ReactNode;
    number: string;
    label: string;
    onClick?: any;
    routeUrl?: string;
}

const InformationCard = (props: IProps) => {
    const { icon, label, number, onClick, routeUrl } = props;

    return (
        <div className={informationCardStyle.informationCardContainer} onClick={() => onClick && onClick(routeUrl)}>
            <div className={`${informationCardStyle.detailsContainer} row`}>
                <span>{icon}</span> <span>{number}</span>
            </div>
            <div className={`${informationCardStyle.titleContainer} row`}>
                <span>{label}</span>
            </div>
        </div>
    );
};

export default InformationCard;
