export default class CreateUserModel {
    email: string = '';
    firstName: string = '';
    lastName: string = '';
    phoneNumber: string = '';
    password: string = '';

    constructor(createUser?: CreateUserModel) {
        if (createUser) {
            this.email = createUser.email;
            this.firstName = createUser.firstName;
            this.lastName = createUser.lastName;
            this.phoneNumber = createUser.phoneNumber;
            this.password = createUser.password;
        }
    }
}
