import { useTranslation } from 'react-i18next';
import welcomeMessageFormStyle from './WelcomeMessageForm.module.scss';
import WelcomeMessageTemplate from './welcomeMessageTemplate/WelcomeMessageTemplate';
import rootStores from '../../../stores';
import { SYSTEM_STORE } from '../../../stores/constants';
import SystemStore from '../../../stores/system/System.store';
import * as yup from 'yup';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import ITextInput from '../../commonComponents/ITextInput/TextInput';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/IButton/IButton';
import { CButton, CVariant, CWellcomeMessageForm } from '../../../constants/constants';
import UpdateSystemFieldsPayload from '../../../models/system/UpdateSystemFieldsPayload.model';
import { useEffect } from 'react';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const WelcomeMessageForm = observer(() => {
    const {
        system: {
            email: { welcome },
        },
        updateSystemSettingsByFields,
    } = systemStore;

    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        subject: yup.string().required(t('welcomeMessageForm.requiredField')),
        contextMessage: yup.string().required(t('welcomeMessageForm.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            subject: welcome.subject,
            contextMessage: welcome.contextMessage,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const prefex = CWellcomeMessageForm.PREFEX;
            await updateSystemSettingsByFields(
                new UpdateSystemFieldsPayload({
                    updates: {
                        [`${prefex}.${CWellcomeMessageForm.SUBJECT}`]: values.subject,
                        [`${prefex}.${CWellcomeMessageForm.CONTEXT_MESSAGE}`]: values.contextMessage,
                    },
                })
            );
        },
    });

    useEffect(() => {
        formik.setValues({
            subject: welcome.subject,
            contextMessage: welcome.contextMessage,
        });
    }, [welcome]);

    return (
        <div className={welcomeMessageFormStyle.welcomeMessageFormContainer}>
            <div className={`${welcomeMessageFormStyle.contextMessage} row`}>
                <div className={`${welcomeMessageFormStyle.formContainer} col-12 col-md-6`}>
                    <form className={welcomeMessageFormStyle.form} onSubmit={formik.handleSubmit}>
                        <div className={welcomeMessageFormStyle.inputContainer}>
                            <ITextInput
                                size={CButton.SMALL}
                                handleOnChange={formik.handleChange(CWellcomeMessageForm.SUBJECT)}
                                variant={CVariant.OUTLINED}
                                error={formik.touched.subject && Boolean(formik.errors.subject)}
                                helperText={formik.touched.subject && formik.errors.subject}
                                value={formik.values.subject}
                                inputLabel={t('welcomeMessageForm.subject')}
                                inputProps={{ maxLength: '60' }}
                            />
                        </div>
                        <div className={welcomeMessageFormStyle.inputContainer}>
                            <ITextInput
                                size={CButton.SMALL}
                                handleOnChange={formik.handleChange(CWellcomeMessageForm.CONTEXT_MESSAGE)}
                                variant={CVariant.OUTLINED}
                                error={formik.touched.contextMessage && Boolean(formik.errors.contextMessage)}
                                helperText={formik.touched.contextMessage && formik.errors.contextMessage}
                                value={formik.values.contextMessage}
                                multiline={true}
                                inputProps={{ maxLength: '350' }}
                                inputLabel={t('welcomeMessageForm.contextMessage')}
                            />
                        </div>
                        <div className={welcomeMessageFormStyle.buttonContainer}>
                            <IButton className={commonStyles.defaultButton} title={t('welcomeMessageForm.update')} type={CButton.SUBMIT} />
                        </div>
                    </form>
                </div>
                <div className={`${welcomeMessageFormStyle.previewContainer} col-12 col-md-6`}>
                    <WelcomeMessageTemplate contextMessage={formik.values.contextMessage} subjectMessage={formik.values.subject} />
                </div>
            </div>
        </div>
    );
});

export default WelcomeMessageForm;
