import { useTranslation } from 'react-i18next';
import orderConfirmationMessageTemplateStyle from './OrderConfirmationMessageTemplate.module.scss';
import rootStores from '../../../../stores';
import { STORE_INFORMATION_STORE } from '../../../../stores/constants';
import StoreInformationStore from '../../../../stores/storeInformation/StoreInformation.store';
import { COrderConfirmationMessageForm } from '../../../../constants/constants';
import { Divider } from '@mui/material';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

interface IProps {
    footerMessage: string;
    subjectMessage: string;
}

const OrderConfirmationMessageTemplate = (props: IProps) => {
    const {
        storeInformation: { storeName, information },
    } = storeInformationStore;

    const storePhone = information.find((type) => type.type === COrderConfirmationMessageForm.PHONE)?.value;
    const storeAddress = information.filter((type) => type.type === COrderConfirmationMessageForm.ADDRESS);
    const { footerMessage, subjectMessage } = props;
    const { t } = useTranslation();

    return (
        <div className={orderConfirmationMessageTemplateStyle.orderConfirmationMessageTemplateContainer}>
            <div className={orderConfirmationMessageTemplateStyle.subjectContainer}>
                <span>
                    {t('orderConfirmationMessageForm.subject')}: {subjectMessage}
                </span>
            </div>
            <span>{t('orderConfirmationMessageForm.viewOnly')}</span>
            <div className={orderConfirmationMessageTemplateStyle.pageContainer}>
                <div className={orderConfirmationMessageTemplateStyle.headerContainer}>
                    <p>{storeName}</p>
                    {storeAddress?.map((address, i) => (
                        <p key={i}>{address.value}</p>
                    ))}
                    <p>{storePhone}</p>
                </div>
                <div className={orderConfirmationMessageTemplateStyle.bodyContainer}>
                    <p>{t('orderConfirmationMessageForm.thankYou')}</p>
                    <p>{t('orderConfirmationMessageForm.dateAndTime')}</p>
                    <p>{t('orderConfirmationMessageForm.customer')}</p>
                    <p>{t('orderConfirmationMessageForm.orderId')}</p>
                    <p>{t('orderConfirmationMessageForm.deliveryMethod')}</p>
                    <p>{t('orderConfirmationMessageForm.address')}</p>
                    <p>{t('orderConfirmationMessageForm.paymentMethod')}</p>
                    <Divider />
                    <div className={orderConfirmationMessageTemplateStyle.productsHeader}>
                        <span>{t('orderConfirmationMessageForm.product')}</span>
                        <span>{t('orderConfirmationMessageForm.quantity')}</span>
                        <span>{t('orderConfirmationMessageForm.price')}</span>
                    </div>
                    <p>{t('orderConfirmationMessageForm.productsList')}</p>
                    <Divider />

                    <p>{t('orderConfirmationMessageForm.totalProductsPrice')}</p>
                    <p>{t('orderConfirmationMessageForm.shippingFee')}</p>
                    <p>{t('orderConfirmationMessageForm.totalAmount')}</p>
                </div>
                <div className={orderConfirmationMessageTemplateStyle.footerContainer}>
                    <span>{footerMessage}</span>
                </div>
            </div>
        </div>
    );
};

export default OrderConfirmationMessageTemplate;
