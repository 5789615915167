import dropDownStyle from './DropDown.module.scss';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ISelectOption } from '../../../interfaces/interfaces';

interface IProps {
    value: string;
    options: ISelectOption[];
    onSelectOption: (option: ISelectOption) => void;
    className?: string;
}

const DropDown = (props: IProps) => {
    const { value, options, onSelectOption, className } = props;

    return (
        <Select
            className={`${dropDownStyle.dropDown} ${className} custom-drop-down`}
            value={value === undefined || value === null || options.length === 0 ? '' : value}
        >
            {options?.map((option, i) => (
                <MenuItem onClick={() => onSelectOption(option)} key={i} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </Select>
    );
};

export default DropDown;
