import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import mainLayoutStyle from './MainLayout.module.scss';

import Spinner from '../../../components/commonComponents/spinner/Spinner';
import rootStores from '../../../stores';
import { STORE_INFORMATION_STORE, SYSTEM_STORE } from '../../../stores/constants';
import StoreInformationStore from '../../../stores/storeInformation/StoreInformation.store';

import SystemStore from '../../../stores/system/System.store';
import { RoutesUrl } from '../../../router/RoutesUrls';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;
const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const MainLayout = observer(() => {
    const { getStoreInformation, isLoading: storeInformationIsLoading } = storeInformationStore;
    const { getSystemSettings, isLoading: systemIsLoading } = systemStore;
    const isLoading = storeInformationIsLoading || systemIsLoading;

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getStoreInformation();
            } catch (error) {}
            try {
                await getSystemSettings();
            } catch (error) {}
        };

        fetchData();

        if (location.pathname === RoutesUrl.welcome) {
            navigate(RoutesUrl.dashboard);
        }
    }, [getStoreInformation, navigate]);

    return (
        <div className={`${mainLayoutStyle.mainLayoutContainer} ${isLoading ? 'blur-background' : ''}`}>
            <Header />
            <Outlet />
            <Footer />
            {isLoading && <Spinner />}
        </div>
    );
});

export default MainLayout;
