import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ISelectOption } from '../../interfaces/interfaces';
import rootStores from '../../stores';
import { FILTER_STORE, ORDERS_STORE } from '../../stores/constants';
import FilterStore from '../../stores/filter/Filter.store';
import OrdersStore from '../../stores/orders/Orders.store';
import commonStyle from '../../styles/commonStyles.module.scss';
import IButton from '../commonComponents/IButton/IButton';
import ISelect from '../commonComponents/ISelect/ISelect';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import filterStyle from './OrdersFilter.module.scss';

const orderStore = rootStores[ORDERS_STORE] as OrdersStore;
const filterStore = rootStores[FILTER_STORE] as FilterStore;

const OrdersFilter = observer(() => {
    const { ordersStatusesAsISelect, getOrdersByFilter } = orderStore;
    const { orderFilter, setOrderFilter } = filterStore;
    const { t } = useTranslation();

    const handleStatusOnChange = (option: ISelectOption) => {
        if (!option) setOrderFilter({ ...orderFilter, status: undefined });
        else
            setOrderFilter({
                ...orderFilter,
                status: option,
            });
    };

    const onSearchSubmit = () => getOrdersByFilter(orderFilter);

    const handleOrderOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') getOrdersByFilter(orderFilter);
    };

    const handleOrderNumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value;
        setOrderFilter({ ...orderFilter, orderId: value });
    };

    return (
        <div className={filterStyle.filterContainer}>
            <div className={filterStyle.filterInputs}>
                <div className={filterStyle.searchByOrderNum}>
                    <ITextInput
                        size={'small'}
                        variant={'outlined'}
                        value={orderFilter.orderId}
                        error={undefined}
                        helperText={undefined}
                        placeholder={t('ordersFilter.searchByOrderNum')}
                        handleOnChange={handleOrderNumChange}
                        onkeyDown={handleOrderOnKeyDown}
                    />
                </div>
                <div className={filterStyle.searchByStatus}>
                    <ISelect
                        options={ordersStatusesAsISelect}
                        placeholder={t('ordersFilter.searchByStatus')}
                        onChange={handleStatusOnChange}
                        value={orderFilter.status}
                        isClearable
                    />
                </div>
                <IButton title={t('ordersFilter.submitButtonSearch')} className={commonStyle.defaultButton} onClick={onSearchSubmit} />
            </div>
        </div>
    );
});

export default OrdersFilter;
