const products = {
    createNewProduct: 'יצירת מוצר חדש',
    serialNumber: 'מק"ט',
    name: 'שם',
    section: 'נושא',
    category: 'קטגוריה',
    subCategory: 'תת-קטגוריה',
    price: 'מחיר',
    discount: 'הנחה',
    updatedPrice: 'מחיר לאחר הנחה',
    inStock: 'זמין במלאי',
    hot: 'מוצר חם',
    searchSerialNumber: 'חפש לפי מק"ט',
    searchProductName: 'חפש לפי שם מוצר',
    findProductsBy: 'חפש מוצר לפי',
    isVisible: 'מוצג בחנות',
    image: 'תמונה',
};

export default products;
