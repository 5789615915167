export default class ProductsSubCategoryModel {
    subCategoryId: string = '';

    label: string = '';

    constructor(productsSubCategory?: ProductsSubCategoryModel) {
        if (productsSubCategory) {
            this.subCategoryId = productsSubCategory.subCategoryId;
            this.label = productsSubCategory.label;
        }
    }
}
