import { GridColDef } from '@mui/x-data-grid';
import ILink from '../../components/commonComponents/link/ILink';
import DeliveryMethodIcon from '../../components/deliveryMethodIcon/DeliveryMethodIcon';
import OrderStatusInTable from '../../components/orderStatusInTable/OrderStatusInTable';
import PaymentMethodIcon from '../../components/paymentMethodIcon/PaymentMethodIcon';
import StatusIndicator from '../../components/statusIndicator/StatusIndicator';
import { formatDateAndTime } from '../../utils/dateUtils';
import iconStyles from '../../components/paymentMethodIcon/PaymentMethodIcon.module.scss';
import { EDiscount } from '../../enums/enums';
import columnsStyle from './columnsStyle.module.scss';

const providerColumns = (onLinkClick: any, handleOnStatusChange: any): GridColDef[] => [
    {
        field: '_id',
        headerAlign: 'center',
        align: 'center',
        type: 'text',
        flex: 1,
        headerName: 'הזמנה',
        renderCell: (params) => <ILink onClick={onLinkClick} text={params.row.shortId} row={params.row} /> || '--',
    },
    {
        field: 'firstName',
        headerAlign: 'center',
        flex: 1,
        align: 'center',
        headerName: 'שם פרטי',
        renderCell: (params) => params.row.user.firstName || '--',
    },
    {
        field: 'lastName',
        headerAlign: 'center',
        flex: 1,
        align: 'center',
        headerName: 'שם משפחה',
        renderCell: (params) => params.row.user.lastName || '--',
    },
    {
        field: 'date',
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
        flex: 1,
        headerName: 'תאריך הזמנה',
        renderCell: (params) => formatDateAndTime(params.value) || '--',
    },
    {
        field: 'orderStatus',
        minWidth: 200,
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        headerName: 'סטטוס',
        renderCell: (params) => (
            <OrderStatusInTable
                className={columnsStyle.statusButton}
                statuses={params.value}
                handleOnStatusChange={handleOnStatusChange}
                row={params.row}
            />
        ),
    },
    {
        field: 'deliveryMethod',
        headerAlign: 'center',
        flex: 1,
        align: 'center',
        headerName: 'משלוח/איסוף',
        renderCell: (params) => <DeliveryMethodIcon className={iconStyles.tableRowIcon} icon={params.value} />,
    },
];

export default providerColumns;
