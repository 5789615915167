import ClientConfig from '../config/index';
import { ILoginPayload } from '../interfaces/interfaces';
import CreateUserModel from '../models/user/CreateUser.model';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const storeInformationPrefix = 'authManagement';

class AuthFetcher extends BaseHttpFetcher {
    async login(payload: ILoginPayload): Promise<any> {
        return await this.post(`${storeInformationPrefix}/adminManagementLogin`, payload);
    }

    async createCustomer(user: CreateUserModel): Promise<any> {
        return await this.post(`${storeInformationPrefix}/createCustomer`, user);
    }
}

const loginFetcherInstance = new AuthFetcher('/', ClientConfig.apiBaseHost);
export default loginFetcherInstance;
