import { ReactNode } from 'react';
import managementOptionStyle from './ManagementOption.module.scss';

interface IProps {
    icon: ReactNode;
    label: string;
    onClick?: any;
    routeUrl?: string;
}

const ManagementOption = (props: IProps) => {
    const { icon, label, onClick, routeUrl } = props;

    return (
        <div className={managementOptionStyle.optionContainer} onClick={() => onClick && onClick(routeUrl)}>
            <div className={`${managementOptionStyle.iconContainer} row`}>{icon}</div>
            <div className={`${managementOptionStyle.textContainer} row`}>{label}</div>
        </div>
    );
};

export default ManagementOption;
