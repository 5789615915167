export default class WelcomeMessageModel {
    contextMessage: string = '';
    subject: string = '';

    constructor(welcomeMessage?: WelcomeMessageModel) {
        if (welcomeMessage) {
            this.contextMessage = welcomeMessage.contextMessage;
            this.subject = welcomeMessage.subject;
        }
    }
}
