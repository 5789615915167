import iImageUpload from '../components/commonComponents/IImageUpload/IImageUpload.i18n.he';
import header from '../components/header/Header.i18n.he';
import loginForm from '../components/loginForm/LoginForm.i18n.he';
import managementOptions from '../components/managementOptions/ManagementOptions.i18n.he';
import ordersFilter from '../components/ordersFilter/OrdersFilter.i18n.he';
import orderStatusInTable from '../components/orderStatusInTable/OrderStatusInTable.i18n.he';
import productForm from '../components/productForm/ProductForm.i18n.he';
import storeStats from '../components/storeStats/StoreStats.i18n.he';
import categories from '../pages/categories/Categories.i18n.he';
import dashboard from '../pages/dashboard/dashboard.i18n.he';
import order from '../pages/order/Order.i18n.he';
import product from '../pages/product/Product.i18n.he';
import products from '../pages/products/Products.i18n.he';
import user from '../pages/user/User.i18n.he';
import users from '../pages/users/Users.i18n.he';
import settings from '../pages/settings/Settings.i18n.he';
import ordersShippingForm from '../components/settingsForms/ordersShippingForm/OrdersShippingForm.i18n.he';
import websiteForm from '../components/settingsForms/websiteForm/WebsiteForm.i18n.he';
import storeSettingsForm from '../components/settingsForms/storeSettingsForm/StoreSettingsForm.i18n.he';
import messages from '../pages/messages/Messages.i18n.he';
import welcomeMessageForm from '../components/messagesForms/welcomeMessageForm/WelcomeMessageForm.i18n.he';
import orderConfirmationMessageForm from '../components/messagesForms/orderConfirmationMessageForm/OrderConfirmationMessageForm.i18n.he';
import orders from '../pages/orders/Orders.i18n.he';
import coupons from '../pages/coupons/Coupons.i18n.he';
import coupon from '../pages/coupon/Coupon.i18n.he';
import couponForm from '../components/couponForm/CouponForm.i18n.he';
import userForm from '../components/userForm/UserForm.i18n.he';
import sideMenu from '../components/sideMenu/SideMenu.i18n.he';

export const heDictionary = {
    managementOptions,
    dashboard,
    storeStats,
    loginForm,
    users,
    user,
    header,
    orderStatusInTable,
    ordersFilter,
    products,
    product,
    productForm,
    iImageUpload,
    categories,
    order,
    settings,
    ordersShippingForm,
    websiteForm,
    storeSettingsForm,
    messages,
    welcomeMessageForm,
    orderConfirmationMessageForm,
    orders,
    coupons,
    coupon,
    couponForm,
    userForm,
    sideMenu,
};
