const categories = {
    active: 'פעיל',
    disabled: 'לא פעיל',
    addSubCategory: 'הוסף תת-קטגוריה חדשה ל{{category}}',
    addCategory: 'הוסף קטגוריה חדשה ל{{section}}',
    addSection: 'הוספת נושא חדש',
    edit: 'עריכה',
    save: 'שמירה',
    cancel: 'ביטול',
    requiredField: 'שדה חובה',
    add: 'הוספה',
    categoryNotAvailable: '(קטגוריה לא פעילה)',
    sectionNotAvailable: '(נושא לא פעיל)',
};

export default categories;
