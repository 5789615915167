import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { CErrors, CProducts, CToastUtil } from '../../constants/constants';
import { EDiscount } from '../../enums/enums';
import ProductFetcher from '../../fetchers/Product.fetcher';
import ProductsFetcher from '../../fetchers/Products.fetcher';
import { ISelectOption, IToggleOption } from '../../interfaces/interfaces';
import FilterProductsParamModel from '../../models/filter/FilterProductsParam.model';
import ProductModel from '../../models/product/Product.model';
import ToastUtil from '../../utils/ToastUtils';

class ProductsStore {
    @observable
    products: ProductModel[] = [];

    @observable
    selectedProduct: ProductModel = new ProductModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'ProductsStore',
            properties: ['products', 'selectedProduct'],
            storage: window.localStorage,
        });
    }

    @action
    setProducts = (products: ProductModel[]) => (this.products = products);

    @action
    setSelectedProduct = (selectedProduct: ProductModel) => (this.selectedProduct = selectedProduct);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    getProductsByFilters = async (params: FilterProductsParamModel) => {
        try {
            this.setIsLoading(true);
            let {
                data: { products },
            } = await ProductsFetcher.getProductsByFilters(params);
            this.setProducts(products);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateProduct = async (product: ProductModel, file: any) => {
        try {
            this.setIsLoading(true);
            let { data } = await ProductFetcher.updateProduct(product, file);

            const index = this.products.findIndex((prod) => prod._id === data.product._id);

            const newProducts: ProductModel[] = [...this.products];
            const updatedProduct = new ProductModel(data.product);
            if (index !== -1) {
                newProducts[index] = updatedProduct;
            } else {
                newProducts.push(updatedProduct);
            }

            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setProducts(newProducts);
            this.setSelectedProduct(updatedProduct);
            return updatedProduct;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return this.selectedProduct;
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    createProduct = async (product: ProductModel, file: any) => {
        try {
            this.setIsLoading(true);
            let { data } = await ProductFetcher.createProduct(product, file);
            const newProduct = new ProductModel(data.product);
            const newProducts: ProductModel[] = [...this.products, newProduct];
            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setProducts(newProducts);
            this.setSelectedProduct(newProduct);
            return newProduct;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return new ProductModel();
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getProductById = async (id: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await ProductFetcher.getProductById(id);
            this.setSelectedProduct(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    get getSelectedProductSectionAsISelect(): ISelectOption[] {
        return this.selectedProduct?.sections?.map((section) => ({
            value: section.sectionId,
            label: section.label,
        }));
    }

    @computed
    get getSelectedProductCategoriesAsISelect(): ISelectOption[] {
        return this.selectedProduct?.categories?.map((category) => ({
            value: category.categoryId,
            label: category.label,
        }));
    }

    @computed
    get getSelectedProductSubCategoriesAsISelect(): ISelectOption[] {
        return this.selectedProduct?.subCategories?.map((subCategory) => ({
            value: subCategory.subCategoryId,
            label: subCategory.label,
        }));
    }

    @computed
    get getSelectedProductDiscountAsIToggle(): IToggleOption {
        const type = this.selectedProduct.discount.type;
        return {
            value: type !== '' ? type : CProducts.FIXED,
            label: type === CProducts.FIXED ? EDiscount.Shekel : EDiscount.Percentage,
        };
    }
}

export default ProductsStore;
