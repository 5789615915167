const users = {
    firstName: 'שם פרטי',
    lastName: 'שם משפחה',
    email: 'דואר אלקטרוני',
    phoneNumber: 'מספר טלפון',
    dateOfBirth: 'תאריך לידה',
    findUserBy: 'חפש לקוח לפי:',
    createNewUser: 'יצירת לקוח חדש',
};

export default users;
