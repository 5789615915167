export const CStatistics = {
    SALARY: 'Salary',
    INCOMING: 'Incoming',
};

export const CUsers = {
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    PASSWORD: 'password',
    EMAIL: 'email',
    PHONE_NUMBER: 'phoneNumber',
    DATE_OF_BIRTH: 'dateOfBirth',
};

export const COrdersShippingForm = {
    SHIPPING_FEE: 'shippingFee',
    MIN_AMOUNT_FOR_FREE_SHIPPING: 'minAmountForFreeShipping',
    MAX_DELIVERY_DAYS: 'maxDeliveryDays',
    ORDERING: 'ordering',
};

export const CWebsiteForm = {
    MIN_AGE_FOR_REGISTRATION: 'minAgeForRegistration',
    LEGALCY: 'legalcy',
};

export const CWellcomeMessageForm = {
    SUBJECT: 'subject',
    CONTEXT_MESSAGE: 'contextMessage',
    PREFEX: 'email.welcome',
};

export const COrderConfirmationMessageForm = {
    SUBJECT: 'subject',
    FOOTER_MESSAGE: 'footerMessage',
    PREFEX: 'email.orderConfirmation',
    PHONE: 'phone',
    ADDRESS: 'address',
    COMMENT: 'comment',
};

export const CStoreSettingsForm = {
    STORE_NAME: 'storeName',
};

export const CCategories = {
    SECTION_NAME: 'sectionName',
    CATEGORY_NAME: 'categoryName',
    SUB_CATEGORY_NAME: 'subCategoryName',
    DISABLED: 'disabled',
    ACTIVE: 'active',
};

export const CVariant = {
    OUTLINED: 'outlined' as const,
};

export const CButton = {
    SMALL: 'small' as const,
    SUBMIT: 'submit' as const,
    BUTTON: 'button' as const,
    RESET: 'reset' as const,
};

export const CErrors = {
    SYSTEM: 'שגיאת מערכת',
};

export const CToastUtil = {
    UPDATED_SUCCESSFULLY: 'עודכן בהצלחה!',
    CREATED_SUCCESSFULLY: 'לקוח נוצר בהצלחה',
    IMAGE_UPLOADED_SUCCESSFULLY: 'התמונה עודכנה בהצלחה',
};

export const CProducts = {
    SERIAL_NUMBER: 'serialNumber',
    PRODUCT_NAME: 'productName',
    NAME: 'name',
    SECTIONS: 'sections',
    CATEGORIES: 'categories',
    SUB_CATEGORIES: 'subCategories',
    PRICE: 'price',
    IMAGE: 'image',
    DISCOUNT: 'discount',
    DISCOUNT_TYPE: 'discountType',
    DISCOUNT_AMOUNT: 'discountAmount',
    UPDATED_PRICE: 'updatedPrice',
    IN_STOCK: 'inStock',
    OUT_OF_STOCK: 'outOfStock',
    HOT: 'hot',
    NOT_HOT: 'notHot',
    PERCENTAGE: 'percentage',
    FIXED: 'fixed',
    IS_VISIBLE: 'isVisible',
    IS_INVISIBLE: 'isInvisible',
};

export const CCoupons = {
    CODE: 'code',
    DISCOUNT: 'discount',
    FIXED: 'fixed',
    PERCENTAGE: 'percentage',
    EXPIRATION_DATE: 'expirationDate',
    IS_ACTIVE: 'isActive',
    IS_INACTIVE: 'isInactive',
    IS_REUSABLE: 'isReusable',
    IS_NOT_REUSABLE: 'isNotReusable',
    MIN_AMONT: 'minAmount',
    USAGE_LIMIT: 'usageLimit',
    USED_COUNT: 'usedCount',
    CREATED_AT: 'createdAt',
    DISCOUNT_TYPE: 'discountType',
    DISCOUNT_AMOUNT: 'discountAmount',
};
