import productVariantsViewStyle from './ProductVariantsView.module.scss';
import ProductsVariantModel from '../../models/product/ProductsVariant.model';
import { useTranslation } from 'react-i18next';
import { EDiscount } from '../../enums/enums';

interface IProps {
    productVariants: ProductsVariantModel[];
}

const ProductVariantsView = (props: IProps) => {
    const { productVariants } = props;

    const { t } = useTranslation();

    return (
        <div className={productVariantsViewStyle.productVariantsViewContainer}>
            <h5>{t('productForm.variants')}</h5>
            {productVariants.length === 0 ? (
                <h6>{t('productForm.noVariants')}</h6>
            ) : (
                productVariants.map((productsVariant, i) => (
                    <div className={productVariantsViewStyle.variantContainer} key={i}>
                        <h6>{productsVariant.type}</h6>
                        <ul>
                            {productsVariant.options.map((option, i) => (
                                <li key={i}>
                                    <div className={productVariantsViewStyle.optionContainer}>
                                        <span>{option.value}</span>
                                        <span>|</span>
                                        <span>{option.inStock ? t('productForm.available') : t('productForm.unavailable')}</span>
                                        <span>|</span>
                                        <span>{`${t('productForm.extraPrice')}: ${EDiscount.Shekel}${option.extraPrice}`}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))
            )}
        </div>
    );
};

export default ProductVariantsView;
