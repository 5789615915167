import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import ProductForm from '../../components/productForm/ProductForm';
import { RoutesUrl } from '../../router/RoutesUrls';
import { PRODUCTS_STORE } from '../../stores/constants';
import rootStores from '../../stores/index';
import ProductsStore from '../../stores/products/Products.store';
import ToastUtils from '../../utils/ToastUtils';
import productStyles from './Product.module.scss';

const productsStore = rootStores[PRODUCTS_STORE] as ProductsStore;

const Product = observer(() => {
    const { isLoading, selectedProduct, getProductById } = productsStore;

    const { productId } = useParams();

    const [isCreatedMode, setIsCreateMode] = useState<boolean>(productId ? false : true);
    console.log('isCreatedMode:', isCreatedMode);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const getProductFromParams = async () => {
        if (!productId) ToastUtils.error('Something went wrong...');
        else {
            await getProductById(productId);
            setIsCreateMode(false);
        }
    };

    useEffect(() => {
        if (!selectedProduct._id && productId) getProductFromParams();
    }, []);

    useEffect(() => {
        if (productId) {
            setIsCreateMode(false);
        }
    }, [productId]);

    const backButtonHandler = () => navigate(RoutesUrl.products);

    return (
        <div className={`${productStyles.productContainer} ${isLoading ? 'blur-background' : ''}`}>
            <div className={`${productStyles.headerContainer}`}>
                <div
                    className={`${productStyles.backButtonContainer}`}
                    onClick={() => {
                        backButtonHandler();
                    }}
                >
                    <span>{t('product.back')}</span>
                    <BackIcon className={productStyles.icon} />
                </div>
            </div>
            <ProductForm isCreatedMode={isCreatedMode} />
            {isLoading && <Spinner />}
        </div>
    );
});

export default Product;
