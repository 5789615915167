import bcrypt from 'bcryptjs';
import ToastUtil from '../utils/ToastUtils';

export const hashPassword = async (password: string): Promise<string> => {
    try {
        return await bcrypt.hash(password, process.env.REACT_APP_BCRYPT_SALT);
    } catch (error) {
        ToastUtil.error('Error hashing password');
        console.log(error);
        throw new Error('Error hashing password');
    }
};
