import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';
import { EDiscount } from '../../../enums/enums';
import textInputStyle from './TextInput.module.scss';

interface IProps {
    size: 'small' | 'medium';
    variant: 'outlined' | 'standard' | 'filled';
    handleOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number | boolean;
    error?: boolean | undefined;
    helperText?: string | false | undefined;
    inputLabel?: string;
    type?: string;
    showCurrency?: boolean;
    inputProps?: any;
    placeholder?: string;
    onkeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    multiline?: boolean;
}

const ITextInput = observer((props: IProps) => {
    const { size, handleOnChange, variant, multiline, inputProps, value, error, helperText, type, inputLabel, placeholder, showCurrency, onkeyDown } =
        props;
    const maxLength = inputProps?.maxLength;

    return (
        <div className={`${textInputStyle.textInputContainer}`}>
            {inputLabel && <InputLabel className={textInputStyle.textInputLabel}>{inputLabel}</InputLabel>}
            <TextField
                size={size}
                onChange={handleOnChange}
                variant={variant}
                inputProps={inputProps}
                InputProps={
                    showCurrency
                        ? {
                              startAdornment: <InputAdornment position='start'>{EDiscount.Shekel}</InputAdornment>,
                          }
                        : {}
                }
                value={value}
                error={error}
                helperText={helperText}
                type={type}
                placeholder={placeholder}
                FormHelperTextProps={{
                    sx: {
                        textAlign: 'right',
                    },
                }}
                onKeyDown={onkeyDown}
                multiline={multiline}
                rows={multiline ? 4 : 1}
            />
            {maxLength && (
                <InputLabel className={textInputStyle.maxLengthLabel}>
                    <span>{`${value.toString().length}/${maxLength}`}</span>
                </InputLabel>
            )}
        </div>
    );
});

export default ITextInput;
