import { observer } from 'mobx-react';
import { CButton } from '../../../constants/constants';
import iButtonStyles from './IButton.module.scss';

interface IProps {
    onClick?: any;
    title: string;
    type?: 'button' | 'submit' | 'reset' | undefined;
    disabled?: boolean;
    className: string;
    endIcon?: any;
}

const IButton = observer((props: IProps) => {
    const { title, onClick, type, disabled, className, endIcon } = props;

    return (
        <button
            className={`${className} ${disabled ? iButtonStyles.disabled : ''}`}
            onClick={onClick}
            type={type || CButton.BUTTON}
            disabled={disabled}
        >
            {endIcon ? endIcon : null}
            {title}
        </button>
    );
});

export default IButton;
