import { action, makeObservable, observable } from 'mobx';
import OrderFilterModel from '../../models/order/OrderFilter.model';
import FilterProductsParamModel from '../../models/filter/FilterProductsParam.model';

class FilterStore {
    @observable
    orderFilter: OrderFilterModel = new OrderFilterModel();

    @observable
    productsFilter: FilterProductsParamModel = new FilterProductsParamModel();

    constructor() {
        makeObservable(this);
    }

    @action
    setOrderFilter = (orderFilter: OrderFilterModel) => (this.orderFilter = orderFilter);

    @action
    setProductsFilter = (productsFilter: FilterProductsParamModel) => (this.productsFilter = productsFilter);
}

export default FilterStore;
