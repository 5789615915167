import ClientConfig from '../config/index';
import CouponModel from '../models/order/CouponModel.model';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const couponsPrefix = 'couponsManagement';

class CouponsFetchers extends BaseHttpFetcher {
    async getAllCoupons(): Promise<any> {
        return await this.get(`${couponsPrefix}/coupons`);
    }

    async updateCoupon(coupon: CouponModel): Promise<any> {
        return await this.patch(`${couponsPrefix}/coupons/${coupon._id}`, { updatedCoupon: coupon });
    }

    async createCoupon(coupon: CouponModel): Promise<any> {
        return await this.post(`${couponsPrefix}/coupons/create`, { coupon });
    }
}

const couponsFetcherInstance = new CouponsFetchers('/', ClientConfig.apiBaseHost);
export default couponsFetcherInstance;
