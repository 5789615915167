import MenuItemModel from '../../../models/dashboard/MenuItem.model';
import { RoutesUrl } from '../../../router/RoutesUrls';
import { ReactComponent as CategoriesIcon } from '../../../assets/icons/categories.svg';
import { ReactComponent as CustomersIcon } from '../../../assets/icons/customers.svg';
import { ReactComponent as OrdersManagementIcon } from '../../../assets/icons/orders-management.svg';
import { ReactComponent as ProductsManagmentIcon } from '../../../assets/icons/products-management.svg';

const providerMenuItems: MenuItemModel[] = [
    {
        icon: <ProductsManagmentIcon />,
        label: 'managementOptions.productsManagement',
        routeUrl: RoutesUrl.products,
    },
    {
        icon: <OrdersManagementIcon />,
        label: 'managementOptions.ordersManagement',
        routeUrl: RoutesUrl.orders,
    },
    {
        icon: <CategoriesIcon />,
        label: 'managementOptions.categories',
        routeUrl: RoutesUrl.categories,
    },
    {
        icon: <CustomersIcon />,
        label: 'managementOptions.customersManagement',
        routeUrl: RoutesUrl.users,
    },
];

export default providerMenuItems;
