import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import iImageUploadStyles from './IImageUpload.module.scss';

import { CloseCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { observer } from 'mobx-react';
import rootStores from '../../../stores';
import { STORE_INFORMATION_STORE } from '../../../stores/constants';
import StoreInformationStore from '../../../stores/storeInformation/StoreInformation.store';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

interface IProps {
    onSelectImage: (image: any) => void;
    selectedProductImage: string;
}

const IImagePicker = observer((props: IProps) => {
    const { onSelectImage, selectedProductImage } = props;
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [previewImage, setPreviewImage] = useState<string | null>(selectedProductImage);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { t } = useTranslation();

    const {
        storeInformation: { defaultProductImage },
    } = storeInformationStore;

    const handleBeforeUpload = (file) => {
        return false; // Prevent automatic upload
    };

    const handleFileChange = async ({ file }) => {
        setSelectedImage(file.name);
        onSelectImage(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewImage(reader.result as string);
        };
        reader.readAsDataURL(file);
    };

    const handleClear = (event) => {
        event.stopPropagation();
        setSelectedImage(selectedProductImage);
        setPreviewImage(selectedProductImage);
    };

    let imageName = selectedImage?.split('/') || [];

    return (
        <div className={`${iImageUploadStyles.iImageUploadContainer} row`}>
            <Upload
                name='file'
                multiple={false}
                onChange={handleFileChange}
                beforeUpload={handleBeforeUpload}
                showUploadList={false}
                accept='image/*'
                className={`${iImageUploadStyles.columnContainer} col-12 col-md-6`}
            >
                <div className={`${iImageUploadStyles.inputContainer}`}>
                    <Button icon={<UploadOutlined />}>בחירת קובץ</Button>

                    {selectedImage && (
                        <div className={iImageUploadStyles.iImageName}>
                            <span>{imageName[imageName.length - 1]}</span>
                            <CloseCircleOutlined className={iImageUploadStyles.clearIcon} onClick={handleClear} />
                        </div>
                    )}
                </div>
            </Upload>

            {errorMessage && <p className={iImageUploadStyles.error}>{errorMessage}</p>}
            <div className={`${iImageUploadStyles.imageContainer} ${iImageUploadStyles.columnContainer} col-12 col-md-6`}>
                <img src={previewImage ? previewImage : defaultProductImage} alt='' className={iImageUploadStyles.selectedImage} />
            </div>
        </div>
    );
});

export default IImagePicker;
