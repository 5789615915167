export enum EMonth {
    January = 'ינ',
    February = 'פב',
    March = 'מרץ',
    April = 'אפ',
    May = 'מאי',
    June = 'יוני',
    July = 'יולי',
    August = 'אוג',
    September = 'ספ',
    October = 'אוק',
    November = 'נוב',
    December = 'דצמ',
}

export enum EStatistics {
    Salary = 'הזמנות',
    Incoming = 'הכנסות',
}

export enum EDiscount {
    Shekel = '₪',
    Percentage = '%',
}

export enum DeliveryMethods {
    PICKUP = 'selfPickup',
    DELIVERY = 'delivery',
}

export enum StatusesOptions {
    NEW = 'new',
    CANCEL = 'cancel',
    PENDING = 'pending',
    COMPLETED = 'completed',
}
