export default class ProductsCategoryModel {
    categoryId: string = '';

    label: string = '';

    constructor(productsCategory?: ProductsCategoryModel) {
        if (productsCategory) {
            this.categoryId = productsCategory.categoryId;
            this.label = productsCategory.label;
        }
    }
}
