export default class DashboardStatisticsModel {
    numberOfNewOrders: number = 0;

    numberOfProducts: number = 0;

    numberOfCustomers: number = 0;

    totalIncoming: number = 0.0;

    inStorePayments: number = 0.0;

    onlinePayments: number = 0.0;

    yearsForStatistics: string[] = [];

    constructor(dashboardStatistics?: DashboardStatisticsModel) {
        if (dashboardStatistics) {
            this.numberOfNewOrders = dashboardStatistics.numberOfNewOrders;
            this.numberOfProducts = dashboardStatistics.numberOfProducts;
            this.numberOfCustomers = dashboardStatistics.numberOfCustomers;
            this.totalIncoming = dashboardStatistics.totalIncoming;
            this.inStorePayments = dashboardStatistics.inStorePayments;
            this.onlinePayments = dashboardStatistics.onlinePayments;
            this.yearsForStatistics = dashboardStatistics.yearsForStatistics;
        }
    }
}
