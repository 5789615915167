import statusStyle from './StatusIndicator.module.scss';

interface IProps {
    isActive: boolean;
}

const StatusIndicator = (props: IProps) => {
    const { isActive } = props;
    return isActive ? (
        <div className={`${statusStyle.statusIndicator} ${statusStyle.charged}`}></div>
    ) : (
        <div className={`${statusStyle.statusIndicator} ${statusStyle.notCharged}`}></div>
    );
};

export default StatusIndicator;
