import { RowData } from '../../interfaces/interfaces';
import UserModel from '../user/User.model';
import OrdersStatus from './OrdersStatus.model';

export default class OrderTableRow {
    _id: string = '';

    shortId: string = '';

    user: UserModel = new UserModel();

    paymentAmount: string = '';

    date: string = '';

    orderStatus: OrdersStatus = new OrdersStatus();

    paymentMethod: string = '';

    isCharged: boolean = false;

    isInvoiced: boolean = false;

    deliveryMethod: string = '';

    source: string = '';

    constructor(order?: RowData) {
        if (order) {
            this._id = order?._id;
            this.shortId = order?.shortId;
            this.user = order?.user;
            this.paymentAmount = order?.paymentAmount?.totalAmount?.toLocaleString().toString();
            this.date = order?.dateAndTime;
            this.orderStatus = order?.orderStatus;
            this.paymentMethod = order?.paymentInformation?.paymentMethod;
            this.isCharged = order?.paymentInformation?.isCharged;
            this.isInvoiced = order?.paymentInformation?.isInvoiced;
            this.deliveryMethod = order?.shippingDetails?.deliveryMethod;
        }
    }
}
