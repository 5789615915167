import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { CErrors } from '../../constants/constants';
import OrdersFetcher from '../../fetchers/Orders.fetcher';
import { ISelectOption } from '../../interfaces/interfaces';
import OrderModel from '../../models/order/Order.model';
import OrderFilterModel from '../../models/order/OrderFilter.model';
import OrdersPerMonthModel from '../../models/order/OrdersPerMonth.model';
import OrdersStatus from '../../models/order/OrdersStatus.model';
import OrderTableRow from '../../models/order/OrderTableRow.model';
import PaymentsOrdersModel from '../../models/order/PaymentsOrdersModel.model';
import ToastUtil from '../../utils/ToastUtils';

class OrdersStore {
    @observable
    orders: OrderModel[] = [];

    @observable
    selectedUserOrders: OrderModel[] = [];

    @observable
    ordersStatuses: OrdersStatus[] = [];

    @observable
    tableRows: OrderTableRow[] = [];

    @observable
    offset: number = 0;

    @observable
    isLoading: boolean = false;

    @observable
    selectedOrder?: OrderModel;

    @observable
    selectedOrders: OrderModel[] = [];

    @observable
    numberOfOrdersPerYear: OrdersPerMonthModel[] = [];

    @observable
    paymentsOrders: PaymentsOrdersModel = new PaymentsOrdersModel();

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'OrdersStore',
            properties: ['orders'],
            storage: window.localStorage,
        });
    }

    @action
    setOrders = (orders: OrderModel[]) => (this.orders = orders);

    @action
    setSelectedUserOrders = (selectedUserOrders: OrderModel[]) => (this.selectedUserOrders = selectedUserOrders);

    @action
    setStatusesOptions = (statuses: OrdersStatus[]) => (this.ordersStatuses = statuses);

    @action
    setOffset = (offset: number) => (this.offset = offset);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    setTableRows = (orders: OrderModel[]) => {
        let rows: OrderTableRow[] = [];
        orders.forEach((order: OrderModel) => rows.push(new OrderTableRow(order)));
        this.tableRows = rows;
    };

    @action
    setSelectedOrder = (order: OrderModel) => (this.selectedOrder = new OrderModel(order));

    @action
    setNumberOfOrdersPerYear = (numberOfOrdersPerYear: OrdersPerMonthModel[]) => (this.numberOfOrdersPerYear = numberOfOrdersPerYear);

    @action
    setSelectedOrders = (orders: OrderModel[]) => (this.selectedOrders = orders);

    @action
    setPaymentsOrders = (paymentsOrders: PaymentsOrdersModel) => (this.paymentsOrders = paymentsOrders);

    @action
    getOrdersByUserId = async (userId: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await OrdersFetcher.getsOrdersByUserId(userId);
            this.setSelectedUserOrders(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getOrderByOrderId = async (orderId: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await OrdersFetcher.getOrderByOrderId(orderId);
            this.setSelectedOrder(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : 'Some error occurred.';
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getNumberOfOrdersByYear = async (year: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await OrdersFetcher.getNumberOfOrdersByYear(year);
            this.setNumberOfOrdersPerYear(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getPaymentsOrdersByYear = async (year: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await OrdersFetcher.getPaymentsOrdersByYear(year);
            this.setPaymentsOrders(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getOrdersByFilter = async (filter: OrderFilterModel) => {
        try {
            this.setIsLoading(true);
            let { data } = await OrdersFetcher.getOrdersByFilter(filter);
            this.setOrders(data);
            this.setTableRows(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getOrderStatusesOptions = async () => {
        try {
            this.setIsLoading(true);
            let { data } = await OrdersFetcher.getOrderStatusesOptions();
            this.setStatusesOptions(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateOrderStatus = async (status: OrdersStatus, order: OrderModel) => {
        try {
            this.setIsLoading(true);

            const { data } = await OrdersFetcher.updateOrderStatus(status, order._id);

            let newOrders: OrderModel[] = this.orders.map((o) => {
                if (order._id === o._id) order.orderStatus = data.updatedStatuses;

                return o;
            });

            if (newOrders) this.setOrders(newOrders);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    get ordersStatusesAsISelect(): ISelectOption[] {
        return this.ordersStatuses.map((status) => ({
            value: status.state,
            label: status.label,
        }));
    }
}

export default OrdersStore;
