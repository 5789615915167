import { observer } from 'mobx-react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { IToggleOption } from '../../../interfaces/interfaces';
import { useState } from 'react';
import iToggleStyles from './IToggle.module.scss';

interface IProps {
    options: IToggleOption[];
    defaultSelected: IToggleOption;
    onChange: (option: IToggleOption) => void;
}

const IToggle = observer((props: IProps) => {
    const { options, defaultSelected, onChange } = props;
    const [selected, setSelected] = useState<IToggleOption>(defaultSelected);

    const handleChange = (event: any) => {
        const {
            target: { value, textContent },
        } = event;
        const selectedOption: IToggleOption = { label: textContent, value: value };
        setSelected(selectedOption);
        onChange(selectedOption);
    };

    return (
        <ToggleButtonGroup
            value={selected.value}
            exclusive
            onChange={(e) => {
                handleChange(e);
            }}
            className={iToggleStyles.iToggleContainer}
        >
            {options?.map((option) => (
                <ToggleButton
                    sx={{
                        '&.Mui-selected': {
                            backgroundColor: '#949495',
                        },
                    }}
                    key={option.value}
                    value={option.value}
                >
                    {option.label}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
});

export default IToggle;
