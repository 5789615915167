import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg';
import { ReactComponent as ShopIcon } from '../../assets/icons/shop-icon.svg';
import headerStyle from './Header.module.scss';
import { useMediaQuery } from 'react-responsive';
import Fade from '@mui/material/Fade';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import useScrollToTopHook from '../../hooks/useScrollToTopHook';
import { RoutesUrl } from '../../router/RoutesUrls';
import rootStores from '../../stores';
import { STORE_INFORMATION_STORE, AUTH_STORE, USER_STORE } from '../../stores/constants';
import UserStore from '../../stores/user/User.store';
import AuthStore from '../../stores/auth/Auth.store';
import StoreInformationStore from '../../stores/storeInformation/StoreInformation.store';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import SideMenu from '../sideMenu/SideMenu';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;
const authStore = rootStores[AUTH_STORE] as AuthStore;
const userStore = rootStores[USER_STORE] as UserStore;

const Navbar = observer(() => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const isSettingsMenuOpen = Boolean(anchorEl);

    const {
        storeInformation: { storeName },
    } = storeInformationStore;

    const {
        user: { firstName },
    } = userStore;

    const { logout } = authStore;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const clickCloseHandler = () => {
        setAnchorEl(null);
    };

    const logoutHandler = () => {
        setAnchorEl(null);
        logout();
        navigate(RoutesUrl.login);
    };

    useScrollToTopHook();

    return (
        <header className={headerStyle.navBarContainer}>
            {isMenuOpen && <SideMenu onCloseHandler={() => setIsMenuOpen(false)} />}
            <div className={headerStyle.shopContainer}>
                {!isMobileScreen && <ShopIcon onClick={() => navigate(RoutesUrl.dashboard)} className={headerStyle.icon} />}
                {isMobileScreen && <MenuIcon onClick={() => setIsMenuOpen(true)} className={headerStyle.icon} />}
                <span>{storeName}</span>
            </div>
            <div className={headerStyle.connectedUserContainer}>
                <span>{`${t('header.welcome')} ${firstName} `}</span>
                <Button
                    id='fade-button'
                    aria-controls={isSettingsMenuOpen ? 'fade-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={isSettingsMenuOpen ? 'true' : undefined}
                    onClick={handleClick}
                >
                    <SettingsIcon className={headerStyle.settingsIcon} />
                </Button>
                <Menu
                    id='fade-menu'
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={isSettingsMenuOpen}
                    onClose={clickCloseHandler}
                    TransitionComponent={Fade}
                >
                    <MenuItem onClick={logoutHandler}>{t('header.logout')}</MenuItem>
                </Menu>
            </div>
        </header>
    );
});

export default Navbar;
