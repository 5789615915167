export default class AddressModel {
    address: string = '';
    address2: string = '';
    city: string = '';
    zipCode: string = '';

    constructor(a?: AddressModel) {
        if (a) {
            this.address = a.address;
            this.address2 = a.address2;
            this.city = a.city;
            this.zipCode = a.zipCode;
        }
    }
}
