import { createBrowserRouter } from 'react-router-dom';

import { ReactNode } from 'react';
import LoginPage from '../pages/auth/login/LoginPage';
import Categories from '../pages/categories/Categories';
import Dashboard from '../pages/dashboard/Dashboard';
import MainLayout from '../pages/layout/mainLayout/MainLayout';
import ManagementLayout from '../pages/layout/managementLayout/ManagementLayout';
import Messages from '../pages/messages/Messages';
import Order from '../pages/order/Order';
import Orders from '../pages/orders/Orders';
import Products from '../pages/products/Products';
import Product from '../pages/product/Product';
import Settings from '../pages/settings/Settings';
import User from '../pages/user/User';
import Users from '../pages/users/Users';
import AuthWrapper from './AuthRouter';
import { RoutesUrl } from './RoutesUrls';
import Coupons from '../pages/coupons/Coupons';
import Coupon from '../pages/coupon/Coupon';

const something: ReactNode = <div>asdfasdf</div>;

const router = createBrowserRouter([
    {
        path: RoutesUrl.welcome,
        element: (
            <AuthWrapper>
                <MainLayout />
            </AuthWrapper>
        ),
        errorElement: something,
        children: [
            {
                path: RoutesUrl.dashboard,
                element: <Dashboard />,
            },
            {
                path: RoutesUrl.management,
                element: <ManagementLayout />,
                children: [
                    {
                        path: RoutesUrl.orders,
                        element: <Orders />,
                    },
                    {
                        path: RoutesUrl.order,
                        element: <Order />,
                    },
                    {
                        path: RoutesUrl.users,
                        element: <Users />,
                    },
                    {
                        path: RoutesUrl.user,
                        element: <User />,
                    },
                    {
                        path: RoutesUrl.createUser,
                        element: <User />,
                    },
                    {
                        path: RoutesUrl.coupons,
                        element: <Coupons />,
                    },
                    {
                        path: RoutesUrl.coupon,
                        element: <Coupon />,
                    },
                    {
                        path: RoutesUrl.createCoupon,
                        element: <Coupon />,
                    },
                    {
                        path: RoutesUrl.products,
                        element: <Products />,
                    },
                    {
                        path: RoutesUrl.product,
                        element: <Product />,
                    },
                    {
                        path: RoutesUrl.createProduct,
                        element: <Product />,
                    },
                    {
                        path: RoutesUrl.settings,
                        element: <Settings />,
                    },
                    {
                        path: RoutesUrl.messages,
                        element: <Messages />,
                    },
                    {
                        path: RoutesUrl.categories,
                        element: <Categories />,
                    },
                ],
            },
        ],
    },
    {
        path: RoutesUrl.login,
        element: <LoginPage />,
    },
]);

export default router;
