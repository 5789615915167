export default class PaymentsOrdersModel {
    onlinePayments: number = 0;

    inStorePayments: number = 0;

    constructor(paymentsOrders?: PaymentsOrdersModel) {
        if (paymentsOrders) {
            this.onlinePayments = paymentsOrders.onlinePayments;
            this.inStorePayments = paymentsOrders.inStorePayments;
        }
    }
}
