import { observer } from 'mobx-react';
import { useState } from 'react';
import OrderModel from '../../models/order/Order.model';
import OrdersStatus from '../../models/order/OrdersStatus.model';
import rootStores from '../../stores';
import { ORDERS_STORE } from '../../stores/constants';
import OrdersStore from '../../stores/orders/Orders.store';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu, MenuItem } from '@mui/material';
import IButton from '../commonComponents/IButton/IButton';
import { ISelectOption } from '../../interfaces/interfaces';

const ordersStore = rootStores[ORDERS_STORE] as OrdersStore;

interface IProps {
    row: OrderModel;
    statuses: OrdersStatus[];
    className: string;
    handleOnStatusChange: any;
}

const OrderStatusInTable = observer((props: IProps) => {
    const { ordersStatuses } = ordersStore;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const _onStatusChange = (status: OrdersStatus) => {
        handleClose();
        props.handleOnStatusChange(status, props.row);
    };

    const activeStatus = props.statuses.find((s) => s.active);

    return (
        <>
            <IButton onClick={handleClick} endIcon={<KeyboardArrowDownIcon />} title={activeStatus?.label || ''} className={props.className} />
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {ordersStatuses?.map((status, index) => {
                    return (
                        <MenuItem key={`${index}_${props.row._id}`} onClick={() => _onStatusChange(status)} disableRipple sx={{ minWidth: 150 }}>
                            {status?.label}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
});

export default OrderStatusInTable;
