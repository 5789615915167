import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import settingsFormInputStyle from './SettingsFormInput.module.scss';
import { CButton, CVariant } from '../../../constants/constants';
import ITextInput from '../../commonComponents/ITextInput/TextInput';
import commonStyles from '../../../styles/commonStyles.module.scss';
import IButton from '../../commonComponents/IButton/IButton';

interface IProps {
    submitFormHandler: (fieldToUpdate: string, updatedValue: string) => void;
    closeFormHandler: (fieldName: string) => void;
    validationSchema: any;
    fieldName: string;
    defaultValue: string;
    showCurrency?: boolean;
}

const SettingsFormInput = (props: IProps) => {
    const { submitFormHandler, closeFormHandler, showCurrency, validationSchema, fieldName, defaultValue } = props;
    const { t } = useTranslation();

    const formik = useFormik({
        initialValues: {
            [fieldName]: defaultValue,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            submitFormHandler(fieldName, values[fieldName]);
        },
    });
    const cancelButtonHandler = () => {
        formik.resetForm();
        closeFormHandler(fieldName);
    };
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className={settingsFormInputStyle.settingsFormInputContainer}>
                <div className={settingsFormInputStyle.inputContainer}>
                    <ITextInput
                        size={CButton.SMALL}
                        handleOnChange={formik.handleChange(fieldName)}
                        variant={CVariant.OUTLINED}
                        error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                        helperText={formik.touched[fieldName] && formik.errors[fieldName]}
                        value={formik.values[fieldName]}
                        showCurrency={showCurrency}
                    />
                </div>
                <div className={settingsFormInputStyle.actionsContainer}>
                    <IButton title={t('settings.update')} className={commonStyles.textButton} type={CButton.SUBMIT} />
                    <IButton title={t('settings.cancel')} className={commonStyles.textButton} onClick={cancelButtonHandler} />
                </div>
            </div>
        </form>
    );
};

export default SettingsFormInput;
