import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DoneIcon from '@mui/icons-material/Done';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import Typography from '@mui/material/Typography';
import { StatusesOptions } from '../../../enums/enums';
import OrdersStatus from '../../../models/order/OrdersStatus.model';
import { formatDateAndTime } from '../../../utils/dateUtils';

interface IProps {
    statuses?: OrdersStatus[];
}

const StatusesTimeline = (props: IProps) => {
    const { statuses } = props;
    return (
        <Timeline position='alternate'>
            {statuses?.map((element, index) => (
                <TimelineItem key={index}>
                    <TimelineOppositeContent sx={{ m: 'auto 0' }} variant='body2' color='text.secondary'>
                        {formatDateAndTime(element.createdAt!)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                            {index === 0 ? (
                                <FiberNewOutlinedIcon />
                            ) : element.state === StatusesOptions.COMPLETED || element.state === StatusesOptions.CANCEL ? (
                                <DoneIcon />
                            ) : (
                                <ArrowDownwardIcon />
                            )}
                        </TimelineDot>
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: '30px' }}>
                        <Typography variant='h6' component='span'>
                            {element.label}
                        </Typography>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </Timeline>
    );
};

export default StatusesTimeline;
