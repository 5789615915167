import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ClientConfig from '../../config/index';
import managementOptionsStyle from './ManagementOptions.module.scss';
import ManagementOption from './managementOption/ManagementOption';
import menuItemsList from './menuItems/menuItems';
import providerMenuItemsList from './menuItems/providerMenuItems';
const ManagementOptions = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (routeURL: string) => navigate(routeURL);

    const menuItems = !ClientConfig.isProvider ? menuItemsList : providerMenuItemsList;

    return (
        <div className={managementOptionsStyle.managementOptionsContainer}>
            <div className={`${managementOptionsStyle.titleContainer} row`}>
                <span>{t('managementOptions.managementOptions')}</span>
            </div>
            <div className={`${managementOptionsStyle.optionsContainer} row`}>
                {menuItems?.map((item, i) => (
                    <ManagementOption key={i} onClick={handleNavigate} icon={item.icon} label={t(item.label)} routeUrl={item.routeUrl} />
                ))}
            </div>
        </div>
    );
};

export default ManagementOptions;
