import { observer } from 'mobx-react';
import ClientConfig from '../../config/index';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import ITable from '../../components/commonComponents/table/ITable';
import OrdersFilter from '../../components/ordersFilter/OrdersFilter';
import { RowData } from '../../interfaces/interfaces';
import OrderModel from '../../models/order/Order.model';
import OrdersStatus from '../../models/order/OrdersStatus.model';
import { FILTER_STORE, ORDERS_STORE, USER_STORE } from '../../stores/constants';
import FilterStore from '../../stores/filter/Filter.store';
import rootStores from '../../stores/index';
import OrdersStore from '../../stores/orders/Orders.store';
import UserStore from '../../stores/user/User.store';
import ordersStyles from './Orders.module.scss';
import columns from './columns';
import providerColumns from './providerColumns';
import { RoutesUrl } from '../../router/RoutesUrls';
import { useMediaQuery } from 'react-responsive';
import IModal from '../../components/commonComponents/IModal/IModal';
import ChangeOrderStatusContext from '../../components/commonComponents/IModal/changeOrderStatusContext/ChangeOrderStatusContext';
import OrderMiniCard from '../../components/orderMiniCard/OrderMiniCard';

const orderStore = rootStores[ORDERS_STORE] as OrdersStore;
const filterStore = rootStores[FILTER_STORE] as FilterStore;
const userStore = rootStores[USER_STORE] as UserStore;

const Orders = observer(() => {
    const [changeStatusModalIsOpen, setChangeStatusModalIsOpen] = useState<boolean>(false);
    const [selectedOrderToUpdate, setSelectedOrderToUpdate] = useState<OrderModel>(new OrderModel());
    const [newStatusToUpdate, setNewStatusToUpdate] = useState<OrdersStatus>(new OrdersStatus());

    const {
        tableRows,
        selectedOrders,
        isLoading,
        getOrdersByFilter,
        setSelectedOrder,
        setSelectedOrders,
        getOrderStatusesOptions,
        updateOrderStatus,
    } = orderStore;
    const { user } = userStore;
    const { orderFilter } = filterStore;

    const navigate = useNavigate();

    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getOrdersByFilter(orderFilter);
            } catch (error) {}
        };

        fetchData();
    }, [getOrdersByFilter, orderFilter.status]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getOrderStatusesOptions();
            } catch (error) {}
        };

        fetchData();
    }, [getOrderStatusesOptions]);

    const onOrderLinkClicked = (row: RowData) => navigate(`${RoutesUrl.orders}/${row._id}`);

    const handleOnStatusChange = async (selectedStatus: OrdersStatus, order: OrderModel) => {
        const currentStatus = order?.orderStatus?.find((o) => o.active);

        if (selectedStatus.state !== currentStatus?.state) {
            const status: OrdersStatus = new OrdersStatus({
                state: selectedStatus.state,
                label: selectedStatus.label,
                createdBy: user.id,
                comment: '',
            });

            setSelectedOrderToUpdate(order);
            setNewStatusToUpdate(status);

            setChangeStatusModalIsOpen(true);
        }
    };

    const submitChangeStatusHandler = async (comment: string) => {
        newStatusToUpdate.comment = comment;
        setChangeStatusModalIsOpen(false);
        await updateOrderStatus(newStatusToUpdate, selectedOrderToUpdate);
    };

    const cancelChangeStatus = () => {
        setSelectedOrderToUpdate(new OrderModel());
        setNewStatusToUpdate(new OrdersStatus());
        setChangeStatusModalIsOpen(false);
    };

    return (
        <div className={`${ordersStyles.orders}  ${isLoading ? 'blur-background' : ''}`}>
            {isLoading && <Spinner />}
            <IModal isOpen={changeStatusModalIsOpen} onClose={cancelChangeStatus}>
                <ChangeOrderStatusContext
                    newStatusToUpdate={newStatusToUpdate}
                    cancelHandler={() => {
                        setChangeStatusModalIsOpen(false);
                    }}
                    updateStatusHandler={submitChangeStatusHandler}
                />
            </IModal>
            <OrdersFilter />
            {!isMobileScreen && (
                <ITable
                    columns={
                        !ClientConfig.isProvider
                            ? columns(onOrderLinkClicked, handleOnStatusChange)
                            : providerColumns(onOrderLinkClicked, handleOnStatusChange)
                    }
                    rows={tableRows}
                    checkboxSelection={false}
                    selectedRows={selectedOrders}
                    setSelectedRow={setSelectedOrder}
                    setSelectedRows={setSelectedOrders}
                    hidePagination
                />
            )}
            {isMobileScreen && tableRows.map((order) => <OrderMiniCard key={order._id} order={order} />)}
        </div>
    );
});

export default Orders;
