import React, { useState } from 'react';
import websiteFormStyle from './WebsiteForm.module.scss';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import SettingsFormInput from '../settingsFormInput/SettingsFormInput';
import { CWebsiteForm } from '../../../constants/constants';
import IButton from '../../commonComponents/IButton/IButton';
import commonStyles from '../../../styles/commonStyles.module.scss';
import { observer } from 'mobx-react';
import rootStores from '../../../stores';
import { SYSTEM_STORE } from '../../../stores/constants';
import SystemStore from '../../../stores/system/System.store';
import UpdateSystemFieldsPayload from '../../../models/system/UpdateSystemFieldsPayload.model';

const systemStore = rootStores[SYSTEM_STORE] as SystemStore;

const WebsiteForm = observer(() => {
    const {
        system: { legalcy },
        updateSystemSettingsByFields,
    } = systemStore;

    const { t } = useTranslation();

    const minAgeForRegistration = CWebsiteForm.MIN_AGE_FOR_REGISTRATION;

    const [editModes, setEditModes] = useState({
        [minAgeForRegistration]: false,
    });

    const validationSchema = {
        [minAgeForRegistration]: yup.object().shape({
            [minAgeForRegistration]: yup.string().required(t('settings.requiredField')).matches(/^\d+$/, t('settings.onlyNumbersAllowed')),
        }),
    };

    const openFormHandler = (fieldName: string) => {
        setEditModes({
            ...setEditModes,
            [fieldName]: true,
        });
    };

    const closeFormHandler = (fieldName: string) => {
        setEditModes({
            ...setEditModes,
            [fieldName]: false,
        });
    };

    const submitFormHandler = async (fieldToUpdate: string, updatedValue: string) => {
        closeFormHandler(fieldToUpdate);
        const prefex = CWebsiteForm.LEGALCY;
        await updateSystemSettingsByFields(
            new UpdateSystemFieldsPayload({
                updates: {
                    [`${prefex}.${fieldToUpdate}`]: parseFloat(updatedValue),
                },
            })
        );
    };

    return (
        <div className={websiteFormStyle.websiteFormContainer}>
            <div className={`${websiteFormStyle.websiteFormRow} row`}>
                <div className={`${websiteFormStyle.websiteFormCol} col-5`}>
                    <span>{t('websiteForm.minAgeForRegistration')}</span>
                </div>
                <div className={`${websiteFormStyle.websiteFormCol} col-7`}>
                    {editModes.minAgeForRegistration ? (
                        <SettingsFormInput
                            submitFormHandler={submitFormHandler}
                            closeFormHandler={closeFormHandler}
                            validationSchema={validationSchema.minAgeForRegistration}
                            fieldName={minAgeForRegistration}
                            defaultValue={legalcy[minAgeForRegistration]}
                        />
                    ) : (
                        <div className={websiteFormStyle.valueContainer}>
                            <span>{legalcy[minAgeForRegistration]}</span>
                            <IButton
                                className={commonStyles.textButton}
                                title={t('settings.change')}
                                onClick={() => {
                                    openFormHandler(minAgeForRegistration);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default WebsiteForm;
