export default class OrdersStatus {
    state: string = '';
    label: string = '';
    active?: boolean = false;
    createdAt?: string = '';
    createdBy: string = '';
    comment: string = '';

    constructor(ordersStatus?: OrdersStatus) {
        if (ordersStatus) {
            this.state = ordersStatus.state;
            this.label = ordersStatus.label;
            this.active = ordersStatus.active;
            this.createdAt = ordersStatus.createdAt;
            this.createdBy = ordersStatus.createdBy;
            this.comment = ordersStatus.comment;
        }
    }
}
