import bitLogo from '../../assets/icons/bit_logo.png';
import { ReactComponent as CashIcon } from '../../assets/icons/cash.svg';
import { ReactComponent as CreditCardIcon } from '../../assets/icons/credit_card.svg';

interface IProps {
    icon: string;
    className: string;
}

const PaymentMethodIcon = (props: IProps) => {
    if (props.icon === 'card') {
        return <CreditCardIcon className={props.className} />;
    } else if (props.icon === 'card') return <img src={bitLogo} alt='Bit' className={props.className} />;
    else if (props.icon === 'cash') return <CashIcon className={props.className} />;
    else return null;
};

export default PaymentMethodIcon;
