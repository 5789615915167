export default class PaymentInformationModel {
    lastDigits: string = '';
    paymentMethod: string = 'cash | card';
    isCharged: boolean = false; 
    isInvoiced: boolean = false; 

    constructor(pim?: PaymentInformationModel) {
        if (pim) {
            this.lastDigits = pim.lastDigits;
            this.paymentMethod = pim.paymentMethod;
            this.isCharged = pim.isCharged;
            this.isInvoiced = pim.isInvoiced;
        }
    }
}
