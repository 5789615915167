export default class CategoryModel {
    categoryId: string = '';

    categoryName: string = '';

    subCategories?: CategoryModel[];

    disabled?: boolean;

    sectionId?: string;

    parentCategory?: string;

    constructor(category?: CategoryModel) {
        if (category) {
            this.categoryId = category.categoryId;
            this.categoryName = category.categoryName;
            this.subCategories = category.subCategories?.map((subCategory) => new CategoryModel(subCategory));
            this.disabled = category.disabled;
            this.sectionId = category.sectionId;
            this.parentCategory = category.parentCategory;
        }
    }
}
