import { BaseHttpFetcher } from './BaseHttpFetcher';
import ClientConfig from '../config/index';
import SectionModel from '../models/topics/Section.model';
import CategoryModel from '../models/topics/Category.model';

const sectionsPrefix = 'sectionsManagement';

class SectionsFetcher extends BaseHttpFetcher {
    async getSections(): Promise<any> {
        return await this.get(`${sectionsPrefix}/sections`);
    }

    async updateSection(section: SectionModel): Promise<any> {
        return await this.patch(`${sectionsPrefix}/section/${section.sectionId}`, { updatedSection: section });
    }

    async updateCategory(category: CategoryModel): Promise<any> {
        return await this.patch(`${sectionsPrefix}/category/${category.categoryId}`, { updatedCategory: category });
    }

    async updateSubCategory(subCategory: CategoryModel): Promise<any> {
        return await this.patch(`${sectionsPrefix}/subCategory/${subCategory.categoryId}`, { updatedSubCategory: subCategory });
    }

    async createSection(section: SectionModel): Promise<any> {
        return await this.post(`${sectionsPrefix}/section`, { newSection: section });
    }

    async createCategory(category: CategoryModel): Promise<any> {
        return await this.post(`${sectionsPrefix}/category`, { newCategory: category });
    }
}

const sectionsFetcherInstance = new SectionsFetcher('/', ClientConfig.apiBaseHost);
export default sectionsFetcherInstance;
