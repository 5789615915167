import { action, makeObservable, observable } from 'mobx';
import SystemFetcher from '../../fetchers/System.fetcher';
import ToastUtil from '../../utils/ToastUtils';
import SystemModel from '../../models/system/System.model';
import { CErrors, CToastUtil } from '../../constants/constants';
import UpdateSystemFieldsPayload from '../../models/system/UpdateSystemFieldsPayload.model';

class SystemStore {
    @observable
    system: SystemModel = new SystemModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    setSystem = (system: SystemModel) => (this.system = system);

    @action
    setIsLoading = (value: boolean) => (this.isLoading = value);

    @action
    getSystemSettings = async () => {
        try {
            this.setIsLoading(true);
            const { data } = await SystemFetcher.getSystemSettings();
            this.setSystem(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateSystemSettingsByFields = async (payload: UpdateSystemFieldsPayload) => {
        try {
            this.setIsLoading(true);
            const { data } = await SystemFetcher.updateSystemSettingsByFields(payload);
            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setSystem(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default SystemStore;
