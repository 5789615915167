import { observer } from 'mobx-react';
import { COUPONS_STORE } from '../../stores/constants';
import CouponsStore from '../../stores/coupons/Coupons.store';
import rootStores from '../../stores/index';
import couponStyles from './Coupon.module.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import { useNavigate } from 'react-router-dom';
import { RoutesUrl } from '../../router/RoutesUrls';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import CouponForm from '../../components/couponForm/CouponForm';

const couponsStore = rootStores[COUPONS_STORE] as CouponsStore;

const Coupon = observer(() => {
    const { isLoading } = couponsStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const backButtonHandler = () => {
        navigate(RoutesUrl.coupons);
    };

    return (
        <div className={`${couponStyles.couponContainer} ${isLoading ? 'blur-background' : ''}`}>
            <div className={`${couponStyles.headerContainer}`}>
                <div
                    className={`${couponStyles.backButtonContainer}`}
                    onClick={() => {
                        backButtonHandler();
                    }}
                >
                    <span>{t('coupon.back')}</span>
                    <BackIcon className={couponStyles.icon} />
                </div>
            </div>
            <CouponForm />
            {isLoading && <Spinner />}
        </div>
    );
});

export default Coupon;
