import ClientConfig from '../config/index';
import FilterProductsParamModel from '../models/filter/FilterProductsParam.model';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const productsPrefix = 'productsManagement';

class ProductsFetchers extends BaseHttpFetcher {
    async getProductsByFilters(params: FilterProductsParamModel): Promise<any> {
        return await this.get(`${productsPrefix}/products`, {}, params);
    }
}

const productsFetcherInstance = new ProductsFetchers('/', ClientConfig.apiBaseHost);
export default productsFetcherInstance;
