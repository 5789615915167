import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import managementLayoutStyle from '../pages/layout/managementLayout/ManagementLayout.module.scss';

const useScrollToTopHook = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        const managementLayoutContainer = document.querySelector(`.${managementLayoutStyle.top}`);
        if (managementLayoutContainer) {
            setTimeout(() => {
                managementLayoutContainer.scrollIntoView(true);
            }, 100);
        }
    }, [pathname]);
};

export default useScrollToTopHook;
