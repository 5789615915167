import { useState } from 'react';
import { CButton, CVariant } from '../../constants/constants';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import variantFormStyle from './VariantForm.module.scss';
import { useTranslation } from 'react-i18next';
import IButton from '../commonComponents/IButton/IButton';
import commonStyles from '../../styles/commonStyles.module.scss';
import VariantOption from './VariantOption';
import VariantOptionModel from '../../models/product/VariantOption.model';
import ProductsVariantModel from '../../models/product/ProductsVariant.model';

interface IProps {
    productsVariant: ProductsVariantModel;
    onDeleteVariant: (productsVariant: ProductsVariantModel) => void;
    onUpdateVariant: (productsVariant: ProductsVariantModel) => void;
}

const VariantForm = (props: IProps) => {
    const { onDeleteVariant, productsVariant, onUpdateVariant } = props;

    const { t } = useTranslation();

    const deleteVariantOptionHandler = (variantOption: VariantOptionModel) => {
        const updatedOptions = productsVariant.options.filter((option) => option.id !== variantOption.id);
        const updatedProductsVariantOptions: ProductsVariantModel = { ...productsVariant, options: updatedOptions };
        onUpdateVariant(updatedProductsVariantOptions);
    };

    const updateVariantOptionHandler = (updatedOption: VariantOptionModel) => {
        const updatedOptions = productsVariant.options.map((option) => (option.id === updatedOption.id ? updatedOption : option));
        const updatedProductsVariantOptions: ProductsVariantModel = { ...productsVariant, options: updatedOptions };
        onUpdateVariant(updatedProductsVariantOptions);
    };

    const addVariantOptionHandler = (variantOption: VariantOptionModel) => {
        const updatedOptions = [...productsVariant.options, variantOption];
        const updatedProductsVariantOptions: ProductsVariantModel = { ...productsVariant, options: updatedOptions };
        onUpdateVariant(updatedProductsVariantOptions);
    };

    const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedProductsVariant = { ...productsVariant, type: e.target.value, label: e.target.value };
        onUpdateVariant(updatedProductsVariant);
    };

    return (
        <div className={variantFormStyle.variantFormFontainer}>
            <div className={variantFormStyle.headerContainer}>
                <div>
                    <ITextInput
                        inputLabel={t('productForm.variantName')}
                        size={CButton.SMALL}
                        handleOnChange={handleTypeChange}
                        variant={CVariant.OUTLINED}
                        value={productsVariant.type}
                    />
                </div>
                <div className={variantFormStyle.buttonContainer}>
                    <IButton
                        title={t('productForm.addValue')}
                        className={commonStyles.defaultButton}
                        disabled={!productsVariant.type}
                        onClick={() => {
                            addVariantOptionHandler(
                                new VariantOptionModel({
                                    id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
                                    extraPrice: 0,
                                    inStock: true,
                                    value: '',
                                })
                            );
                        }}
                    />
                    <IButton
                        title={t('productForm.deleteVariant')}
                        className={commonStyles.defaultButton}
                        onClick={() => {
                            onDeleteVariant(productsVariant);
                        }}
                    />
                </div>
            </div>
            {productsVariant.options.map((variantOption, i) => (
                <VariantOption
                    type={productsVariant.type}
                    key={i}
                    onDeleteOption={deleteVariantOptionHandler}
                    onUpdateOption={updateVariantOptionHandler}
                    variantOption={variantOption}
                />
            ))}
        </div>
    );
};

export default VariantForm;
