import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import ClientConfig from '../../config/index';
import { CButton, CProducts, CVariant } from '../../constants/constants';
import { EDiscount } from '../../enums/enums';
import { useNavigate } from 'react-router-dom';
import { ISelectOption, IToggleOption } from '../../interfaces/interfaces';
import ProductModel from '../../models/product/Product.model';
import ProductsCategoryModel from '../../models/product/ProductsCategory.model';
import ProductsSectionModel from '../../models/product/ProductsSection.model';
import ProductsSubCategoryModel from '../../models/product/ProductsSubCategory.model';
import ProductsVariantModel from '../../models/product/ProductsVariant.model';
import rootStores from '../../stores';
import { PRODUCTS_STORE, SECTIONS_STORE, STORE_INFORMATION_STORE } from '../../stores/constants';
import ProductsStore from '../../stores/products/Products.store';
import SectionsStore from '../../stores/sections/Sections.store';
import StoreInformationStore from '../../stores/storeInformation/StoreInformation.store';
import commonStyles from '../../styles/commonStyles.module.scss';
import IButton from '../commonComponents/IButton/IButton';
import IImageUpload from '../commonComponents/IImageUpload/IImageUpload';
import IModal from '../commonComponents/IModal/IModal';
import IMultipleSelect from '../commonComponents/IMultipleSelect/IMultipleSelect';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import IToggle from '../commonComponents/IToggle/IToggle';
import StatusIndicator from '../statusIndicator/StatusIndicator';
import ProductVariantsView from '../variantForm/ProductVariantsView';
import VariantForm from '../variantForm/VariantForm';
import productFormStyles from './ProductForm.module.scss';
import { RoutesUrl } from '../../router/RoutesUrls';

const sectionsStore = rootStores[SECTIONS_STORE] as SectionsStore;
const productsStore = rootStores[PRODUCTS_STORE] as ProductsStore;
const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

interface IProps {
    isCreatedMode: boolean;
}

const ProductForm = observer((props: IProps) => {
    const { isCreatedMode } = props;

    const { getSectionsAsISelect, getCategoriesBySectionIdsAsISelect, getSubCategoriesByCategoryIdsAsISelect } = sectionsStore;

    const { t } = useTranslation();

    const {
        storeInformation: { defaultProductImage },
    } = storeInformationStore;

    const {
        getSelectedProductSectionAsISelect,
        getSelectedProductCategoriesAsISelect,
        getSelectedProductSubCategoriesAsISelect,
        getSelectedProductDiscountAsIToggle,
        updateProduct,
        createProduct,
        selectedProduct,
    } = productsStore;

    useEffect(() => {
        setIsEditMode(isCreatedMode);
    }, [isCreatedMode]);

    const navigate = useNavigate();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(isCreatedMode);
    const [productVariants, setProductVariants] = useState<ProductsVariantModel[]>(selectedProduct.variants);
    const [file, setFile] = useState<any>();

    const stockOptions: ISelectOption[] = [
        { value: CProducts.IN_STOCK, label: t('productForm.available') },
        { value: CProducts.OUT_OF_STOCK, label: t('productForm.unavailable') },
    ];

    const hotOptions: ISelectOption[] = [
        { value: CProducts.HOT, label: t('productForm.yes') },
        { value: CProducts.NOT_HOT, label: t('productForm.no') },
    ];

    const discountOptions: IToggleOption[] = [
        { label: EDiscount.Percentage, value: CProducts.PERCENTAGE },
        { label: EDiscount.Shekel, value: CProducts.FIXED },
    ];

    const isVisbleOptions: IToggleOption[] = [
        { value: CProducts.IS_VISIBLE, label: t('productForm.visible') },
        { value: CProducts.IS_INVISIBLE, label: t('productForm.invisible') },
    ];

    const isVariantsValid = () => {
        let validVariants = true;
        for (let i = 0; i < productVariants.length; i++) {
            if (productVariants[i].options.length === 0 || productVariants[i].type.length === 0) {
                validVariants = false;
            } else {
                for (let j = 0; j < productVariants[i].options.length; j++) {
                    if (productVariants[i].options[j].value.length === 0) {
                        validVariants = false;
                    }
                }
            }
        }
        return validVariants;
    };

    const validationSchema = yup.object().shape({
        productName: yup.string().required(t('productForm.requiredField')),
        serialNumber: yup.string().required(t('productForm.requiredField')),
        price: yup.string().when([], {
            is: () => !ClientConfig.isProvider,
            then: (schema) =>
                schema
                    .required(t('productForm.requiredField'))
                    .test('not-zero', t('productForm.notZero'), (value) => {
                        return value !== '0' && !value?.startsWith('0');
                    })
                    .matches(/^\d+(\.\d{1,2})?$/, {
                        message: t('productForm.numbersOnly'),
                        excludeEmptyString: true,
                    }),
        }),
        description: yup.string().required(t('productForm.requiredField')),
        inStock: yup.boolean().required(t('productForm.requiredField')),
        hot: yup.boolean().required(t('productForm.requiredField')),
        isVisible: yup.boolean().required(t('productForm.requiredField')),
        sections: yup.array().required(t('productForm.requiredField')).min(1, t('productForm.sectionsNotEmpty')),
        categories: yup.array().required(t('productForm.requiredField')).min(1, t('productForm.sectionsNotEmpty')),
        subCategories: yup.array(),
        discountAmount: yup
            .string()
            .matches(/^\d+(\.\d{1,2})?$/, {
                message: t('productForm.numbersOnly'),
                excludeEmptyString: true,
            })
            .test('not-zero', t('productForm.notZero'), (value) => {
                return !isNaN(Number(value)) && value !== '';
            })
            .required(t('productForm.requiredField'))
            .test('valid-discount-amount', t('productForm.invalidDiscountAmount'), function (value) {
                const { discountType, price } = this.parent;
                if (discountType === CProducts.FIXED) {
                    return Number(value) <= Number(price);
                } else if (discountType === CProducts.PERCENTAGE) {
                    const discountValue = Number(value);
                    return !isNaN(discountValue) && discountValue < 100;
                }
                return true;
            }),
    });

    const formik = useFormik({
        initialValues: {
            serialNumber: selectedProduct.serialNumber,
            productName: selectedProduct.name,
            price: selectedProduct.price,
            description: selectedProduct.description,
            inStock: selectedProduct.inStock,
            hot: selectedProduct.hot,
            isVisible: selectedProduct.isVisible,
            discountAmount: selectedProduct.discount.amount,
            discountType: selectedProduct.discount.type,
            image: isCreatedMode ? defaultProductImage : selectedProduct.images[0],
            sections: getSelectedProductSectionAsISelect,
            categories: getSelectedProductCategoriesAsISelect,
            subCategories: getSelectedProductSubCategoriesAsISelect,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (isVariantsValid()) {
                const updatedProduct: ProductModel = new ProductModel({
                    _id: selectedProduct._id,
                    serialNumber: values.serialNumber,
                    name: values.productName,
                    price: Number(values.price),
                    sections: values.sections?.map((section) => new ProductsSectionModel({ sectionId: section.value, label: section.label })),
                    categories: values.categories?.map(
                        (category) => new ProductsCategoryModel({ categoryId: category.value, label: category.label })
                    ),
                    subCategories: values.subCategories?.map(
                        (subCategory) => new ProductsSubCategoryModel({ subCategoryId: subCategory.value, label: subCategory.label })
                    ),
                    hot: values.hot,
                    inStock: values.inStock,
                    discount: {
                        amount: Number(values.discountAmount),
                        type: values.discountType,
                    },
                    description: values.description,
                    variants: productVariants.map((productsVariant) => new ProductsVariantModel(productsVariant)),
                    images: [defaultProductImage],
                    isVisible: values.isVisible,
                });
                const newProduct: ProductModel = isCreatedMode
                    ? await createProduct(updatedProduct, file)
                    : await updateProduct(updatedProduct, file);
                if (newProduct._id) {
                    setIsEditMode(false);
                    navigate(`${RoutesUrl.products}/${newProduct._id}`);
                }
            } else {
                setIsModalOpen(true);
            }
        },
    });

    const deleteProductsVariantHandler = (productsVariant: ProductsVariantModel) => {
        setProductVariants(productVariants.filter((option) => option.id !== productsVariant.id));
    };

    const updateProductsVariantHandler = (updatedProductsVariant: ProductsVariantModel) => {
        setProductVariants(productVariants.map((option) => (option.id === updatedProductsVariant.id ? updatedProductsVariant : option)));
    };

    useEffect(() => {
        formik.setValues({
            serialNumber: selectedProduct.serialNumber,
            productName: selectedProduct.name,
            price: selectedProduct.price,
            description: selectedProduct.description,
            inStock: selectedProduct.inStock,
            hot: selectedProduct.hot,
            discountAmount: selectedProduct.discount.amount,
            discountType: selectedProduct.discount.type,
            image: isCreatedMode ? defaultProductImage : selectedProduct.images[0],
            sections: getSelectedProductSectionAsISelect,
            categories: getSelectedProductCategoriesAsISelect,
            subCategories: getSelectedProductSubCategoriesAsISelect,
            isVisible: selectedProduct.isVisible,
        });
        setProductVariants(selectedProduct.variants);
    }, [isEditMode]);

    return (
        <div className={productFormStyles.productFormContainer}>
            <IModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <h6>{t('productForm.missingFields')}</h6>
            </IModal>
            <form onSubmit={formik.handleSubmit}>
                <div className={productFormStyles.actionsContainer}>
                    {!isCreatedMode && (
                        <IButton
                            title={isEditMode ? t('productForm.cancelEditMode') : t('productForm.editProduct')}
                            className={commonStyles.defaultButton}
                            onClick={() => setIsEditMode(!isEditMode)}
                        />
                    )}
                    {isEditMode && (
                        <IButton
                            title={t('productForm.save')}
                            className={`${commonStyles.defaultButton} ${productFormStyles.saveButton}`}
                            type={CButton.SUBMIT}
                        />
                    )}
                </div>
                <div className={`${productFormStyles.inputsRowContainer} row`}>
                    <div className={`${productFormStyles.inputsColumnContainer} col`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.image')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <IImageUpload selectedProductImage={selectedProduct.images[0]} onSelectImage={(file) => setFile(file)} />
                            ) : (
                                <img src={selectedProduct.images[0]} alt='' className={productFormStyles.image} />
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${productFormStyles.inputsRowContainer} row`}>
                    <div className={`${productFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.serialNumber')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CProducts.SERIAL_NUMBER)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.serialNumber && Boolean(formik.errors.serialNumber)}
                                    helperText={formik.touched.serialNumber && formik.errors.serialNumber}
                                    value={formik.values.serialNumber}
                                />
                            ) : (
                                <span>{selectedProduct.serialNumber}</span>
                            )}
                        </div>
                    </div>
                    <div className={`${productFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.productName')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CProducts.PRODUCT_NAME)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.productName && Boolean(formik.errors.productName)}
                                    helperText={formik.touched.productName && formik.errors.productName}
                                    value={formik.values.productName}
                                />
                            ) : (
                                <span>{selectedProduct.name}</span>
                            )}
                        </div>
                    </div>
                    {!ClientConfig.isProvider && (
                        <div className={`${productFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                            <div className={productFormStyles.fieldTitleContainer}>
                                <span>{t('productForm.price')}</span>
                            </div>
                            <div className={productFormStyles.inputContainer}>
                                {isEditMode ? (
                                    <ITextInput
                                        size={CButton.SMALL}
                                        handleOnChange={formik.handleChange(CProducts.PRICE)}
                                        variant={CVariant.OUTLINED}
                                        error={formik.touched.price && Boolean(formik.errors.price)}
                                        helperText={formik.touched.price && formik.errors.price}
                                        value={formik.values.price}
                                        showCurrency={true}
                                    />
                                ) : (
                                    <span>
                                        {EDiscount.Shekel}
                                        {selectedProduct.price.toLocaleString()}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={`${productFormStyles.inputsRowContainer} row`}>
                    <div className={`${productFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.section')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <IMultipleSelect
                                    options={getSectionsAsISelect}
                                    required={true}
                                    defaultSelected={formik.values.sections}
                                    onSelect={(sections: ISelectOption[]) => formik.setFieldValue(CProducts.SECTIONS, sections)}
                                />
                            ) : (
                                <div>
                                    {formik.values.sections?.map((productSection) => (
                                        <p key={productSection.value}>{productSection.label}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${productFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.category')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <IMultipleSelect
                                    options={getCategoriesBySectionIdsAsISelect(formik.values.sections)}
                                    defaultSelected={formik.values.categories}
                                    required={true}
                                    onSelect={(categories: ISelectOption[]) => formik.setFieldValue(CProducts.CATEGORIES, categories)}
                                />
                            ) : (
                                <div>
                                    {formik.values.categories?.map((productCategory) => (
                                        <p key={productCategory.value}>{productCategory.label}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={`${productFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.subCategory')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <IMultipleSelect
                                    options={getSubCategoriesByCategoryIdsAsISelect(formik.values.categories)}
                                    required={false}
                                    defaultSelected={formik.values.subCategories}
                                    onSelect={(subCategories: ISelectOption[]) => formik.setFieldValue(CProducts.SUB_CATEGORIES, subCategories)}
                                />
                            ) : (
                                <div>
                                    {formik.values.subCategories?.map((productSubCategory) => (
                                        <p key={productSubCategory.value}>{productSubCategory.label}</p>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className={`${productFormStyles.inputsRowContainer} row`}>
                    <div className={`${productFormStyles.inputsColumnContainer} ${!ClientConfig.isProvider ? 'col-12 col-md-3' : 'col-12 col-md-4'}`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.isVisible')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <IToggle
                                    options={isVisbleOptions}
                                    defaultSelected={{
                                        value: selectedProduct.isVisible ? CProducts.IS_VISIBLE : CProducts.IS_INVISIBLE,
                                        label: selectedProduct.isVisible ? t('productForm.visible') : t('productForm.invisible'),
                                    }}
                                    onChange={(option: IToggleOption) =>
                                        formik.setFieldValue(CProducts.IS_VISIBLE, option.value === CProducts.IS_VISIBLE ? true : false)
                                    }
                                />
                            ) : (
                                <StatusIndicator isActive={selectedProduct.isVisible} />
                            )}
                        </div>
                    </div>
                    <div className={`${productFormStyles.inputsColumnContainer} ${!ClientConfig.isProvider ? 'col-12 col-md-3' : 'col-12 col-md-4'}`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.hot')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <IToggle
                                    options={hotOptions}
                                    defaultSelected={{
                                        value: formik.values.hot ? CProducts.HOT : CProducts.NOT_HOT,
                                        label: formik.values.hot ? t('productForm.yes') : t('productForm.no'),
                                    }}
                                    onChange={(option: IToggleOption) =>
                                        formik.setFieldValue(CProducts.HOT, option.value === CProducts.HOT ? true : false)
                                    }
                                />
                            ) : (
                                <StatusIndicator isActive={selectedProduct.hot} />
                            )}
                        </div>
                    </div>
                    <div className={`${productFormStyles.inputsColumnContainer} ${!ClientConfig.isProvider ? 'col-12 col-md-3' : 'col-12 col-md-4'}`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.inStock')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <IToggle
                                    options={stockOptions}
                                    defaultSelected={{
                                        value: formik.values.inStock ? CProducts.IN_STOCK : CProducts.OUT_OF_STOCK,
                                        label: formik.values.inStock ? t('productForm.available') : t('productForm.unavailable'),
                                    }}
                                    onChange={(option: IToggleOption) =>
                                        formik.setFieldValue(CProducts.IN_STOCK, option.value === CProducts.IN_STOCK ? true : false)
                                    }
                                />
                            ) : (
                                <span>
                                    <StatusIndicator isActive={selectedProduct.inStock} />
                                </span>
                            )}
                        </div>
                    </div>
                    {!ClientConfig.isProvider && (
                        <div className={`${productFormStyles.inputsColumnContainer} col-12 col-md-3`}>
                            <div className={productFormStyles.fieldTitleContainer}>
                                <span>{t('productForm.discount')}</span>
                            </div>
                            <div className={productFormStyles.inputContainer}>
                                {isEditMode ? (
                                    <>
                                        <div style={{ flex: 1 }}>
                                            <ITextInput
                                                size={CButton.SMALL}
                                                handleOnChange={formik.handleChange(CProducts.DISCOUNT_AMOUNT)}
                                                variant={CVariant.OUTLINED}
                                                error={formik.touched.discountAmount && Boolean(formik.errors.discountAmount)}
                                                helperText={formik.touched.discountAmount && formik.errors.discountAmount}
                                                value={formik.values.discountAmount}
                                            />
                                        </div>
                                        <div>
                                            <IToggle
                                                options={discountOptions}
                                                defaultSelected={getSelectedProductDiscountAsIToggle}
                                                onChange={(option: IToggleOption) => formik.setFieldValue(CProducts.DISCOUNT_TYPE, option.value)}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <span>
                                        {selectedProduct.discount.type === CProducts.PERCENTAGE ? EDiscount.Percentage : EDiscount.Shekel}
                                        {selectedProduct.discount.amount}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                {isEditMode ? (
                    <div className={productFormStyles.productVariantsContainer}>
                        <div>
                            <IButton
                                title={t('productForm.addVariant')}
                                className={commonStyles.defaultButton}
                                onClick={() => {
                                    setProductVariants([
                                        ...productVariants,
                                        new ProductsVariantModel({
                                            id: `${Date.now()}-${Math.floor(Math.random() * 10000)}`,
                                            type: '',
                                            label: '',
                                            options: [],
                                        }),
                                    ]);
                                }}
                            />
                        </div>
                        <div>
                            {productVariants.map((productsVariant, i) => (
                                <VariantForm
                                    productsVariant={productsVariant}
                                    key={i}
                                    onDeleteVariant={deleteProductsVariantHandler}
                                    onUpdateVariant={updateProductsVariantHandler}
                                />
                            ))}
                        </div>
                    </div>
                ) : (
                    <ProductVariantsView productVariants={selectedProduct.variants} />
                )}
                <div className={`${productFormStyles.inputsRowContainer} row`}>
                    <div className={`${productFormStyles.inputsColumnContainer} col`}>
                        <div className={productFormStyles.fieldTitleContainer}>
                            <span>{t('productForm.description')}</span>
                        </div>
                        <div className={productFormStyles.inputContainer}>
                            {isEditMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange('description')}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.description && Boolean(formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                    value={formik.values.description}
                                    multiline={true}
                                    inputProps={{ maxLength: '350' }}
                                />
                            ) : (
                                <span>{selectedProduct.description}</span>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
});

export default ProductForm;
