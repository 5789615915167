import { action, computed, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import SectionsFetcher from '../../fetchers/Sections.fetcher';
import ToastUtil from '../../utils/ToastUtils';
import SectionModel from '../../models/topics/Section.model';
import { ISelectOption } from '../../interfaces/interfaces';
import CategoryModel from '../../models/topics/Category.model';
import { CErrors, CToastUtil } from '../../constants/constants';

class SectionsStore {
    @observable
    sections: SectionModel[] = [];

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, { name: 'SectionsStore', properties: ['sections'], storage: window.localStorage });
    }

    @action
    setSections = (sections: SectionModel[]) => (this.sections = sections);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    getSections = async () => {
        try {
            this.setIsLoading(true);
            const { data } = await SectionsFetcher.getSections();
            this.setSections(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    addCategoryIntoSection(newSections: SectionModel[], newCategory: CategoryModel) {
        const sectionToAddCategory = newSections.find((section) => section.sectionId === newCategory.sectionId);

        if (sectionToAddCategory) {
            sectionToAddCategory.categories?.push(newCategory);
        }
    }

    @action
    addSubCategoryIntoCategory(newSections: SectionModel[], newCategory: CategoryModel) {
        const sectionToUpdate = newSections.find((section) => section.sectionId === newCategory.sectionId);

        if (!sectionToUpdate) return;

        const categoryToUpdate = sectionToUpdate.categories?.find((category) => category.categoryId === newCategory.parentCategory);
        if (!categoryToUpdate) return;

        if (categoryToUpdate) {
            categoryToUpdate.subCategories?.push(newCategory);
        }
    }

    @action
    updateCategoryInSection(newSections: SectionModel[], updatedCategory: CategoryModel) {
        const sectionToUpdate = newSections.find((section) => section.sectionId === updatedCategory.sectionId);
        if (!sectionToUpdate) return;

        const categoryIndex = sectionToUpdate.categories?.findIndex((category) => category.categoryId === updatedCategory.categoryId);
        if (categoryIndex !== undefined && categoryIndex !== -1 && sectionToUpdate.categories) {
            sectionToUpdate.categories[categoryIndex] = updatedCategory;
        }
    }

    @action
    updateSubCategoryInCategory(newSections: SectionModel[], updatedSubCategory: CategoryModel) {
        const sectionToUpdate = newSections.find((section) => section.sectionId === updatedSubCategory.sectionId);
        if (!sectionToUpdate) return;

        const categoryToUpdate = sectionToUpdate.categories?.find((category) => category.categoryId === updatedSubCategory.parentCategory);
        if (!categoryToUpdate) return;

        const subCategoryIndex = categoryToUpdate.subCategories?.findIndex((subCat) => subCat.categoryId === updatedSubCategory.categoryId);
        if (subCategoryIndex !== undefined && subCategoryIndex !== -1 && categoryToUpdate.subCategories) {
            categoryToUpdate.subCategories[subCategoryIndex] = updatedSubCategory;
        }
    }

    @action
    updateSection = async (section: SectionModel) => {
        try {
            this.setIsLoading(true);
            let { data } = await SectionsFetcher.updateSection(section);

            const newSections: SectionModel[] = [...this.sections];
            const updatedSection: SectionModel = data.section;

            const sectionIndex = newSections.findIndex((sec) => sec.sectionId === updatedSection.sectionId);

            if (sectionIndex !== -1) {
                newSections[sectionIndex] = updatedSection;
            }
            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setSections(newSections);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateCategory = async (category: CategoryModel) => {
        try {
            this.setIsLoading(true);
            let { data } = await SectionsFetcher.updateCategory(category);

            const newSections: SectionModel[] = [...this.sections];
            const updatedCategory: CategoryModel = data.category;

            this.updateCategoryInSection(newSections, updatedCategory);
            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setSections(newSections);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateSubCategory = async (subCategory: CategoryModel) => {
        try {
            this.setIsLoading(true);
            let { data } = await SectionsFetcher.updateSubCategory(subCategory);

            const newSections: SectionModel[] = [...this.sections];
            const updatedSubCategory: CategoryModel = data.subCategory;

            this.updateSubCategoryInCategory(newSections, updatedSubCategory);
            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setSections(newSections);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    createSection = async (section: SectionModel) => {
        try {
            this.setIsLoading(true);
            let { data } = await SectionsFetcher.createSection(section);

            const newSections: SectionModel[] = [...this.sections, data.section];
            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setSections(newSections);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    createCategory = async (category: CategoryModel) => {
        try {
            this.setIsLoading(true);
            let { data } = await SectionsFetcher.createCategory(category);

            const newSections: SectionModel[] = [...this.sections];
            const newCategory: CategoryModel = data.category;

            newCategory.parentCategory
                ? this.addSubCategoryIntoCategory(newSections, newCategory)
                : this.addCategoryIntoSection(newSections, newCategory);

            ToastUtil.success(CToastUtil.UPDATED_SUCCESSFULLY);
            this.setSections(newSections);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @computed
    get getSectionsAsISelect(): ISelectOption[] {
        return this.sections?.map((section) => ({
            value: section.sectionId,
            label: section.sectionName,
        }));
    }

    @action
    getCategoriesBySectionIdsAsISelect = (selectedSections: ISelectOption[]): ISelectOption[] => {
        const selectedSectionIds = selectedSections.map((section) => section.value);
        const categories: ISelectOption[] = [];

        selectedSectionIds.forEach((sectionId) => {
            const section = this.sections.find((section) => section.sectionId === sectionId);
            if (section) {
                section.categories?.forEach((category) => {
                    categories.push({ value: category.categoryId, label: category.categoryName });
                });
            }
        });

        return categories;
    };

    @action
    getSubCategoriesByCategoryIdsAsISelect = (selectedCategories: ISelectOption[]): ISelectOption[] => {
        const selectedCategoryIds = selectedCategories.map((category) => category.value);
        const subCategories: ISelectOption[] = [];

        selectedCategoryIds.forEach((categoryId) => {
            this.sections.forEach((section) => {
                const category = section.categories?.find((category) => category.categoryId === categoryId);
                if (category) {
                    category.subCategories?.forEach((subCategory) => {
                        subCategories.push({ value: subCategory.categoryId, label: subCategory.categoryName });
                    });
                }
            });
        });

        return subCategories;
    };
}

export default SectionsStore;
