export default class ProductsSectionModel {
    sectionId: string = '';

    label: string = '';

    constructor(productsSection?: ProductsSectionModel) {
        if (productsSection) {
            this.sectionId = productsSection.sectionId;
            this.label = productsSection.label;
        }
    }
}
