import { useTranslation } from 'react-i18next';
import messagesStyle from './Messages.module.scss';
import { useState } from 'react';
import { observer } from 'mobx-react';
import WelcomeMessageForm from '../../components/messagesForms/welcomeMessageForm/WelcomeMessageForm';
import OrderConfirmationMessageForm from '../../components/messagesForms/orderConfirmationMessageForm/OrderConfirmationMessageForm';

const Messages = observer(() => {
    const { t } = useTranslation();

    const [activeMessageForm, setActiveMessageForm] = useState<string>(t('messages.welcome'));

    return (
        <div className={messagesStyle.messagesContainer}>
            <div className={messagesStyle.messagesTopMenuContainer}>
                <span
                    className={`${messagesStyle.menuItem} ${activeMessageForm === t('messages.welcome') ? messagesStyle.active : ''} `}
                    onClick={() => {
                        setActiveMessageForm(t('messages.welcome'));
                    }}
                >
                    {t('messages.welcome')}
                </span>
                <span
                    className={`${messagesStyle.menuItem} ${activeMessageForm === t('messages.orderConformation') ? messagesStyle.active : ''} `}
                    onClick={() => {
                        setActiveMessageForm(t('messages.orderConformation'));
                    }}
                >
                    {t('messages.orderConformation')}
                </span>
            </div>
            <div className={messagesStyle.messagesContextContainer}>
                {activeMessageForm === t('messages.welcome') && <WelcomeMessageForm />}
                {activeMessageForm === t('messages.orderConformation') && <OrderConfirmationMessageForm />}
            </div>
        </div>
    );
});

export default Messages;
