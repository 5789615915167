export default class DiscountModel {
    type: string = 'fixed';
    amount: number = 0;

    constructor(discount?: DiscountModel) {
        if (discount) {
            this.type = discount.type;
            this.amount = discount.amount;
        }
    }
}
