const orderConfirmationMessageForm = {
    subject: 'נושא',
    footerMessage: 'כותרת תחתונה',
    siteButton: 'מעבר לאתר',
    requiredField: 'שדה חובה',
    update: 'עדכן',
    viewOnly: '*תצוגה מקדימה של ההודעה הנשלחת בדואר אלקטרוני*',
    thankYou: 'תודה על רכישתך באתר שלנו',
    dateAndTime: '(תאריך ושעת הרכישה)',
    customer: 'לקוח: (שם הלקוח)',
    orderId: 'מספר הזמנה: (מספר הזמנה)',
    deliveryMethod: 'שיטת משלוח: (שיטת משלוח)',
    address: 'כתובת: (כתובת לאיסוף או למשלוח)',
    paymentMethod: 'צורת תשלום: (שולם באתר או תשלום בחנות)',
    productsList: '(רשימת המוצרים בהזמנה)',
    totalProductsPrice: 'סה"כ מחיר המוצרים כולל מעמ: (מחיר סה"כ המוצרים)',
    shippingFee: 'דמי משלוח: (סכום דמי משלוח)',
    totalAmount: 'סה"כ: (סה"כ מחיר ההזמנה)',
    product: 'פריט',
    quantity: 'כמות',
    price: 'מחיר',
};

export default orderConfirmationMessageForm;
