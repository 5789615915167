import { useState } from 'react';
import storeSettingsFormStyle from './StoreSettingsForm.module.scss';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { CStoreSettingsForm } from '../../../constants/constants';
import IButton from '../../commonComponents/IButton/IButton';
import commonStyles from '../../../styles/commonStyles.module.scss';
import { observer } from 'mobx-react';
import rootStores from '../../../stores';
import { STORE_INFORMATION_STORE } from '../../../stores/constants';
import StoreInformationStore from '../../../stores/storeInformation/StoreInformation.store';
import UpdateSystemFieldsPayload from '../../../models/system/UpdateSystemFieldsPayload.model';
import SettingsFormInput from '../settingsFormInput/SettingsFormInput';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

const StoreSettingsForm = observer(() => {
    const { storeInformation, updateStoreInformationByFields } = storeInformationStore;

    const { t } = useTranslation();

    const storeName = CStoreSettingsForm.STORE_NAME;

    const [editModes, setEditModes] = useState({
        [storeName]: false,
    });

    const validationSchema = {
        [storeName]: yup.object().shape({
            [storeName]: yup.string().required(t('settings.requiredField')),
        }),
    };

    const openFormHandler = (fieldName: string) => {
        setEditModes({
            ...setEditModes,
            [fieldName]: true,
        });
    };

    const closeFormHandler = (fieldName: string) => {
        setEditModes({
            ...setEditModes,
            [fieldName]: false,
        });
    };

    const submitFormHandler = async (fieldToUpdate: string, updatedValue: string) => {
        closeFormHandler(fieldToUpdate);
        const prefex = '';
        await updateStoreInformationByFields(
            new UpdateSystemFieldsPayload({
                updates: {
                    [`${fieldToUpdate}`]: updatedValue,
                },
            })
        );
    };

    return (
        <div className={storeSettingsFormStyle.storeSettingsFormContainer}>
            <div className={`${storeSettingsFormStyle.storeSettingsFormRow} row`}>
                <div className={`${storeSettingsFormStyle.storeSettingsFormCol} col-5`}>
                    <span>{t('storeSettingsForm.storeName')}</span>
                </div>
                <div className={`${storeSettingsFormStyle.storeSettingsFormCol} col-7`}>
                    {editModes.storeName ? (
                        <SettingsFormInput
                            submitFormHandler={submitFormHandler}
                            closeFormHandler={closeFormHandler}
                            validationSchema={validationSchema.storeName}
                            fieldName={storeName}
                            defaultValue={storeInformation.storeName}
                        />
                    ) : (
                        <div className={storeSettingsFormStyle.valueContainer}>
                            <span>{storeInformation.storeName}</span>
                            <IButton
                                className={commonStyles.textButton}
                                title={t('settings.change')}
                                onClick={() => {
                                    openFormHandler(storeName);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});

export default StoreSettingsForm;
