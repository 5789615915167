import React from 'react';
import iModalStyle from './IModal.module.scss';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

const IModal = (props: IProps) => {
    const { isOpen, onClose, children } = props;
    return (
        <Modal open={isOpen} onClose={onClose}>
            <div className={iModalStyle.iModalContainer}>
                <div className={iModalStyle.header}>
                    <CloseIcon onClick={onClose} className={iModalStyle.closeButton} />
                </div>
                <div className={iModalStyle.context}>{children}</div>
            </div>
        </Modal>
    );
};

export default IModal;
