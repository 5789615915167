import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import UserModel from '../../models/user/User.model';
import UsersFetcher from '../../fetchers/Users.fetcher';
import ToastUtil from '../../utils/ToastUtils';
import { CErrors } from '../../constants/constants';

class UsersStore {
    @observable
    users: UserModel[] = [];

    @observable
    selectedUser: UserModel = new UserModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
        makePersistable(this, {
            name: 'UsersStore',
            properties: ['selectedUser'],
            storage: window.localStorage,
        });
    }

    @action
    setSelectedUser = (selectedUser: UserModel) => (this.selectedUser = selectedUser);

    @action
    setIsLoading = (value: boolean) => (this.isLoading = value);

    @action
    setUsers = (users: UserModel[]) => (this.users = users);

    @action
    getAllUsers = async () => {
        try {
            this.setIsLoading(true);
            let { data } = await UsersFetcher.getAllUsers();
            this.setUsers(data.map((user: UserModel) => new UserModel(user)));
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getSelectedUserById = async (userId: string) => {
        try {
            this.setIsLoading(true);
            let { data } = await UsersFetcher.getUserById(userId);
            this.setSelectedUser(new UserModel(data));
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default UsersStore;
