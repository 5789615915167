import { Navigate } from 'react-router-dom';
import rootStores from '../stores';
import AuthStore from '../stores/auth/Auth.store';
import { AUTH_STORE } from '../stores/constants';
import { RoutesUrl } from './RoutesUrls';

const authStore = rootStores[AUTH_STORE] as AuthStore;

const AuthWrapper = ({ children }) => {
    const { isLoggedIn } = authStore;

    return isLoggedIn ? children : <Navigate to={RoutesUrl.login} replace />;
};

export default AuthWrapper;
