import { BaseHttpFetcher } from './BaseHttpFetcher';
import ClientConfig from '../config/index';
import UpdateSystemFieldsPayload from '../models/system/UpdateSystemFieldsPayload.model';

const storeInformationPrefix = 'storeManagement';

class StoreInformationFetchers extends BaseHttpFetcher {
    async getStoreInformation(): Promise<any> {
        return await this.get(`${storeInformationPrefix}/info`);
    }

    async getDashboardStatistics(): Promise<any> {
        return await this.get(`${storeInformationPrefix}/dashboard`);
    }

    async updateStoreInformationByFields(payload: UpdateSystemFieldsPayload): Promise<any> {
        return await this.patch(`${storeInformationPrefix}/storeInformation`, payload.updates);
    }
}

const storeInformationFetcherInstance = new StoreInformationFetchers('/', ClientConfig.apiBaseHost);
export default storeInformationFetcherInstance;
