export const RoutesUrl = {
    welcome: '/',
    dashboard: '/dashboard',
    management: '/management',
    login: '/login',
    orders: '/management/orders',
    order: '/management/orders/:orderId',
    users: '/management/users',
    user: '/management/users/:userId',
    createUser: '/management/users/create',
    products: '/management/products',
    product: '/management/products/:productId',
    createProduct: '/management/products/create',
    settings: '/management/settings',
    messages: '/management/messages',
    coupons: '/management/coupons',
    coupon: '/management/coupons/:couponId',
    createCoupon: '/management/coupons/create',
    categories: '/management/categories',
};
