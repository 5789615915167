import { observer } from 'mobx-react';
import userStyles from './User.module.scss';
import { ReactComponent as UserProfileIcon } from '../../assets/icons/user-profile.svg';
import rootStores from '../../stores';
import { ORDERS_STORE, USERS_STORE } from '../../stores/constants';
import OrdersStore from '../../stores/orders/Orders.store';
import UsersStore from '../../stores/users/Users.store';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/back.svg';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import { EDiscount } from '../../enums/enums';
import { RoutesUrl } from '../../router/RoutesUrls';
import { formatDateAndTime } from '../../utils/dateUtils';
import UserForm from '../../components/userForm/UserForm';

const usersStore = rootStores[USERS_STORE] as UsersStore;
const ordersStore = rootStores[ORDERS_STORE] as OrdersStore;

const User = observer(() => {
    const { isLoading: authIsLoading } = ordersStore;
    const { selectedUser, getSelectedUserById, isLoading: usersIsLoading } = usersStore;
    const { getOrdersByUserId, selectedUserOrders, isLoading: ordersIsLoading } = ordersStore;
    const isLoading = usersIsLoading || ordersIsLoading || authIsLoading;

    const isCreatedMode = selectedUser.id ? false : true;

    const params = useParams();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const backButtonHandler = () => {
        navigate(RoutesUrl.users);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!selectedUser?.id && params.userId) await getSelectedUserById(params.userId);
                if (params.userId) await getOrdersByUserId(params.userId);
            } catch (error) {}
        };

        fetchData();
    }, [getSelectedUserById, getOrdersByUserId]);

    return (
        <div className={`${userStyles.userContainer} ${isLoading ? 'blur-background' : ''}`}>
            <div className={userStyles.userCardContainer}>
                <div className={`${userStyles.headerContainer} row`}>
                    <div className={`${userStyles.profileImageContainer}`}>
                        <UserProfileIcon className={userStyles.icon} />
                    </div>
                    <div
                        onClick={() => {
                            backButtonHandler();
                        }}
                        className={`${userStyles.backButtonContainer}`}
                    >
                        <span>{t('user.back')}</span>
                        <BackIcon className={userStyles.icon} />
                    </div>
                </div>
                <div className={`${userStyles.bodyContainer} row`}>
                    <div className={`${userStyles.informationContainer} col-12 col-md-6`}>
                        <UserForm isCreatedMode={isCreatedMode} />
                        {!isCreatedMode && selectedUser.shippingAddresses.length !== 0 && (
                            <>
                                <div className={userStyles.userInfo}>
                                    <span className={userStyles.title}>{t('user.addresses')}</span>
                                </div>
                                <div className={userStyles.userInfo}>
                                    <ul>
                                        {selectedUser.shippingAddresses?.map((address, i) => (
                                            <li key={i}>{`${address.address}, ${address.city}, ${address.zipCode}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        )}
                    </div>
                    {!isCreatedMode && (
                        <div className={`${userStyles.statisticsContainer} col-12 col-md-6`}>
                            <span className={userStyles.ordersHistoryTitle}>{t('user.ordersHistory')}</span>
                            {selectedUserOrders?.map((order) => {
                                const activeStatus = order.orderStatus.find((s) => s.active);
                                return (
                                    <div key={order.shortId} className={userStyles.orderInformation}>
                                        <span onClick={() => navigate(`${RoutesUrl.orders}/${order._id}`)} className={userStyles.orderId}>
                                            {order.shortId}
                                        </span>
                                        <span>{formatDateAndTime(order.dateAndTime)}</span>
                                        <span>{`${EDiscount.Shekel}${order?.paymentAmount?.totalAmount?.toLocaleString()}`}</span>
                                        <span>{activeStatus?.label}</span>
                                    </div>
                                );
                            })}
                            {!selectedUserOrders.length && (
                                <div>
                                    <span>{t('user.ordersNotFound')}</span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            {isLoading && <Spinner />}
        </div>
    );
});

export default User;
