import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import InformationCard from '../../components/informationCard/InformationCard';
import ManagementOptions from '../../components/managementOptions/ManagementOptions';
import dashboardStyle from './Dashboard.module.scss';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import GroupIcon from '@mui/icons-material/Group';
import StoreStats from '../../components/storeStats/StroreStats';
import PaidIcon from '@mui/icons-material/Paid';
import StorePieCharts from '../../components/storePieCharts/StorePieCharts';
import { RoutesUrl } from '../../router/RoutesUrls';

import rootStores from '../../stores';
import { STORE_INFORMATION_STORE } from '../../stores/constants';
import StoreInformationStore from '../../stores/storeInformation/StoreInformation.store';
import { observer } from 'mobx-react';
import { useEffect } from 'react';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import { EDiscount } from '../../enums/enums';

const storeInformationStore = rootStores[STORE_INFORMATION_STORE] as StoreInformationStore;

const Dashboard = observer(() => {
    const { dashboardStatistics, getdashboardStatistics, isLoading } = storeInformationStore;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleNavigate = (routeURL: string) => navigate(routeURL);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getdashboardStatistics();
            } catch (error) {}
        };

        fetchData();
    }, [getdashboardStatistics]);

    return (
        <div className={dashboardStyle.dashboardContainer}>
            <div className={dashboardStyle.panelContainer}>
                <div className='row'>
                    <div className={`${dashboardStyle.infoCardContainer} col-12 col-md-3`}>
                        <InformationCard
                            icon={<ShoppingCartIcon />}
                            label={t('dashboard.waitingOrders')}
                            number={`${dashboardStatistics.numberOfNewOrders}`}
                            onClick={handleNavigate}
                            routeUrl={RoutesUrl.orders}
                        />
                    </div>
                    <div className={`${dashboardStyle.infoCardContainer} col-12 col-md-3`}>
                        <InformationCard
                            icon={<Inventory2Icon />}
                            label={t('dashboard.productsInStore')}
                            number={`${dashboardStatistics.numberOfProducts}`}
                            onClick={handleNavigate}
                            routeUrl={RoutesUrl.products}
                        />
                    </div>
                    <div className={`${dashboardStyle.infoCardContainer} col-12 col-md-3`}>
                        <InformationCard
                            icon={<GroupIcon />}
                            label={t('dashboard.usersInStore')}
                            number={`${dashboardStatistics.numberOfCustomers}`}
                            onClick={handleNavigate}
                            routeUrl={RoutesUrl.users}
                        />
                    </div>
                    <div className={`${dashboardStyle.infoCardContainer} col-12 col-md-3`}>
                        <InformationCard
                            icon={<PaidIcon />}
                            label={t('dashboard.incoming')}
                            number={`${EDiscount.Shekel}${dashboardStatistics.totalIncoming?.toLocaleString()}`}
                        />
                    </div>
                </div>
                <div className={`${dashboardStyle.storeStatsContainer} row`}>
                    <div className='col-12 col-md-6'>
                        <StoreStats yearsForStatistics={dashboardStatistics.yearsForStatistics} />
                    </div>
                    <div className={`${dashboardStyle.pieChartsCoontainer} col-12 col-md-6`}>
                        <StorePieCharts yearsForStatistics={dashboardStatistics.yearsForStatistics} />
                    </div>
                </div>
                <div className={`${dashboardStyle.panelOptionsContainer}`}>
                    <ManagementOptions />
                </div>
            </div>
            {isLoading && <Spinner />}
        </div>
    );
});

export default Dashboard;
