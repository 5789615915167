import ClientConfig from '../config/index';
import OrderFilterModel from '../models/order/OrderFilter.model';
import OrdersStatus from '../models/order/OrdersStatus.model';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const ordersManagmetPrefix = 'ordersManagement';
const orderStatusesManagement = 'orderStatusesManagement';

class OrdersFetcher extends BaseHttpFetcher {
    async getsOrdersByUserId(userId: string): Promise<any> {
        return await this.get(`${ordersManagmetPrefix}/orders/${userId}`);
    }

    async getOrderByOrderId(orderId: string): Promise<any> {
        return await this.get(`${ordersManagmetPrefix}/order/${orderId}`);
    }

    async getNumberOfOrdersByYear(year: string): Promise<any> {
        return await this.get(`${ordersManagmetPrefix}/getOrdersByYear`, {}, { year });
    }

    async getPaymentsOrdersByYear(year: string): Promise<any> {
        return await this.get(`${ordersManagmetPrefix}/getPaymentsOrdersByYear`, {}, { year });
    }

    async getOrdersByFilter(filter: OrderFilterModel): Promise<any> {
        return await this.get(`${ordersManagmetPrefix}/getOrdersByFilter`, {}, filter);
    }

    async createOrder(orderDetails: object): Promise<any> {
        return await this.post(`${ordersManagmetPrefix}/createOrder`, {
            orderDetails,
        });
    }

    async getOrderStatusesOptions(): Promise<any> {
        return await this.get(`${orderStatusesManagement}`);
    }

    async updateOrderStatus(status: OrdersStatus, orderId: string): Promise<any> {
        return await this.put(`${ordersManagmetPrefix}/updateOrderStatus`, { orderId, status });
    }
}

const ordersFetcherInstance = new OrdersFetcher('/', ClientConfig.apiBaseHost);
export default ordersFetcherInstance;
