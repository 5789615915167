export default class VariantOptionModel {
    id: string = '';

    value: string = '';

    extraPrice: number = 0;

    inStock: boolean = true;

    constructor(variantOption?: VariantOptionModel) {
        if (variantOption) {
            this.id = variantOption.id;
            this.value = variantOption.value;
            this.extraPrice = variantOption.extraPrice;
            this.inStock = variantOption.inStock;
        }
    }
}
