import { useTranslation } from 'react-i18next';
import storeStatsStyle from './StoreStats.module.scss';
import { EMonth, EStatistics } from '../../enums/enums';
import ICharts from '../commonComponents/ICharts/ICharts';
import { useEffect, useState } from 'react';
import DropDown from '../commonComponents/dropDown/DropDown';
import { CStatistics } from '../../constants/constants';
import rootStores from '../../stores';
import { ORDERS_STORE } from '../../stores/constants';
import OrdersStore from '../../stores/orders/Orders.store';
import { observer } from 'mobx-react';
import Spinner from '../commonComponents/spinner/Spinner';
import { ISelectOption, IStoreStatisticsCharts } from '../../interfaces/interfaces';

const ordersStore = rootStores[ORDERS_STORE] as OrdersStore;

interface IProps {
    yearsForStatistics: string[];
}

const StoreStats = observer((props: IProps) => {
    const { getNumberOfOrdersByYear, numberOfOrdersPerYear, isLoading } = ordersStore;
    const { yearsForStatistics } = props;

    const { t } = useTranslation();

    const [selectedCharts, setSelectedCharts] = useState<ISelectOption>({ label: CStatistics.SALARY, value: CStatistics.SALARY });
    const [selectedYear, setSelectedYear] = useState<ISelectOption>({
        label: new Date().getFullYear().toString(),
        value: new Date().getFullYear().toString(),
    });

    const [storeStatisticsCharts, setStoreStatisticsCharts] = useState<IStoreStatisticsCharts[]>([]);

    const onSelectOption = (option: ISelectOption) => {
        setSelectedCharts(option);
    };

    const onSelectedYear = (year: ISelectOption) => {
        setSelectedYear(year);
    };

    const dropDownOptions = storeStatisticsCharts?.map((el) => {
        return { value: el.type, label: EStatistics[el.type] };
    });

    const dropDownYearsOptions = yearsForStatistics?.map((el) => {
        return { value: el, label: el };
    });

    useEffect(() => {
        const salaryPerMonthData = numberOfOrdersPerYear?.map((month) => month.totalOrders) || [];
        const cancellationPerMonthData = numberOfOrdersPerYear?.map((month) => month.numberOfCancellationOrders) || [];
        const incomingPerMonthData = numberOfOrdersPerYear?.map((month) => month.totalAmount) || [];

        const updatedChartsData: IStoreStatisticsCharts[] = [
            {
                type: CStatistics.SALARY,
                title: t('storeStats.salaryTitle', { year: selectedYear.value }),
                data: [
                    EMonth.January,
                    EMonth.February,
                    EMonth.March,
                    EMonth.April,
                    EMonth.May,
                    EMonth.June,
                    EMonth.July,
                    EMonth.August,
                    EMonth.September,
                    EMonth.October,
                    EMonth.November,
                    EMonth.December,
                ],
                series: [
                    { data: salaryPerMonthData, label: t('storeStats.orders') },
                    { data: cancellationPerMonthData, label: t('storeStats.canceled') },
                ],
                colors: ['#d67a00', 'black'],
            },
            {
                type: CStatistics.INCOMING,
                title: t('storeStats.incomingTitle', { year: selectedYear.value }),
                data: [
                    EMonth.January,
                    EMonth.February,
                    EMonth.March,
                    EMonth.April,
                    EMonth.May,
                    EMonth.June,
                    EMonth.July,
                    EMonth.August,
                    EMonth.September,
                    EMonth.October,
                    EMonth.November,
                    EMonth.December,
                ],
                series: [{ data: incomingPerMonthData }],
                colors: ['green'],
            },
        ];
        setStoreStatisticsCharts(updatedChartsData);
    }, [numberOfOrdersPerYear]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getNumberOfOrdersByYear(selectedYear.value);
            } catch (error) {}
        };

        fetchData();
    }, [getNumberOfOrdersByYear, selectedYear]);

    return (
        <>
            <div className={`${storeStatsStyle.storeStatsContainer} ${isLoading ? 'blur-background' : ''}`}>
                <div className={`${storeStatsStyle.dropDownContainer}`}>
                    <DropDown value={selectedCharts.value} options={dropDownOptions} onSelectOption={onSelectOption} />
                    <DropDown value={selectedYear.value} options={dropDownYearsOptions} onSelectOption={onSelectedYear} />
                </div>
                {storeStatisticsCharts?.map(
                    (el, i) =>
                        selectedCharts.value === el.type && <ICharts key={i} title={el.title} data={el.data} series={el.series} colors={el.colors} />
                )}
            </div>
            {isLoading && <Spinner />}
        </>
    );
});

export default StoreStats;
