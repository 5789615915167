import React from 'react';
import siteSettingsBoxStyle from './SiteSettingsBox.module.scss';

interface IProps {
    children: React.ReactNode;
    title: string;
}

const SiteSettingsBox = (props: IProps) => {
    const { children, title } = props;

    return (
        <div className={siteSettingsBoxStyle.siteSettingsBoxContainer}>
            <div className={siteSettingsBoxStyle.titleContainer}>
                <span>{title}</span>
            </div>
            <div className={siteSettingsBoxStyle.contextContainer}>{children}</div>
        </div>
    );
};

export default SiteSettingsBox;
