import { Collapse } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ProductInOrderModel from '../../models/order/ProductInOrder';
import orderStyles from './Order.module.scss';
import ClientConfig from '../../config/index';
import ProductVariantsView from '../../components/variantForm/ProductVariantsView';

interface IProps {
    product: ProductInOrderModel;
}

const ProductInOrder = (props: IProps) => {
    const [open, setIsOpen] = useState<boolean>(false);

    const {
        product: { quantity, product },
    } = props;

    const { t } = useTranslation();

    const toggleProductDetails = () => setIsOpen(!open);

    return (
        <div className={orderStyles.productContainer} onClick={toggleProductDetails}>
            <div className={orderStyles.productHeader}>
                <div className={`${orderStyles.column} ${orderStyles.textContainer}`}>
                    <span className={orderStyles.productInfoTitle}>{t('order.serialNumber')}</span>
                    <span className={orderStyles.productInfoText}>{product.serialNumber}</span>
                </div>
                <div className={`${orderStyles.column} ${orderStyles.textContainer}`}>
                    <span className={orderStyles.productInfoTitle}>{t('order.name')}</span>
                    <span className={orderStyles.productInfoText}>{product.name}</span>
                </div>
                <div className={`${orderStyles.column} ${orderStyles.textContainer}`}>
                    <span className={orderStyles.productInfoTitle}>{t('order.quantity')}</span>
                    <span className={orderStyles.productInfoText}>{quantity}</span>
                </div>
                {!ClientConfig.isProvider && (
                    <div className={`${orderStyles.column} ${orderStyles.textContainer}`}>
                        <span className={orderStyles.productInfoTitle}>{t('order.price')}</span>
                        <span className={orderStyles.productInfoText}>{`${product.updatedPrice} ₪`}</span>
                    </div>
                )}
                <div className={`${orderStyles.column} ${orderStyles.textContainer}`}>
                    <span className={orderStyles.productInfoTitle}>{t('order.description')}</span>
                    <span className={`${orderStyles.productInfoText} ${orderStyles.ellipse}`}>{product.description}</span>
                </div>
            </div>

            <Collapse className={orderStyles.productDetails} in={open} timeout='auto' unmountOnExit>
                <div className={orderStyles.productHeader}>
                    <div className={`${orderStyles.column} ${orderStyles.textContainer}`}>
                        <span className={orderStyles.productInfoTitle}>{t('order.image')}</span>
                        <img className={orderStyles.productInfoImg} src={product.images[0]} />
                    </div>
                    {product.variants.length !== 0 && <ProductVariantsView productVariants={product.variants} />}
                    <div className={`${orderStyles.column} ${orderStyles.textContainer}`}>
                        <span className={orderStyles.productInfoTitle}>{t('order.fullDescription')}</span>
                        <span className={orderStyles.productInfoText}>{product.description}</span>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default ProductInOrder;
