import { action, computed, makeObservable, observable } from 'mobx';
import ToastUtil from '../../utils/ToastUtils';
import StoreInformationFetcher from '../../fetchers/StoreInformation.fetcher';
import StoreInformationModel from '../../models/store/StoreInformation.model';
import DashboardStatisticsModel from '../../models/store/DashboardStatistics.model';
import { CErrors } from '../../constants/constants';
import UpdateSystemFieldsPayload from '../../models/system/UpdateSystemFieldsPayload.model';

class StoreInformationStore {
    @observable
    storeInformation: StoreInformationModel = new StoreInformationModel();

    @observable
    dashboardStatistics: DashboardStatisticsModel = new DashboardStatisticsModel();

    @observable
    isLoading: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    setStoreInformation = (storeInformation: StoreInformationModel) => (this.storeInformation = storeInformation);

    @action
    setDashboardStatistics = (dashboardStatistics: DashboardStatisticsModel) => (this.dashboardStatistics = dashboardStatistics);

    @action
    getStoreInformation = async () => {
        try {
            this.setIsLoading(true);
            let { data } = await StoreInformationFetcher.getStoreInformation();
            this.setStoreInformation(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    getdashboardStatistics = async () => {
        try {
            this.setIsLoading(true);
            let { data } = await StoreInformationFetcher.getDashboardStatistics();
            this.setDashboardStatistics(data);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    updateStoreInformationByFields = async (payload: UpdateSystemFieldsPayload) => {
        try {
            this.setIsLoading(true);
            const { data } = await StoreInformationFetcher.updateStoreInformationByFields(payload);
            this.setStoreInformation(data);
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
        } finally {
            this.setIsLoading(false);
        }
    };
}

export default StoreInformationStore;
