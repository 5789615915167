import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import addCategoryFormStyle from './AddCategoryForm.module.scss';
import { CButton, CCategories, CVariant } from '../../constants/constants';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import commonStyles from '../../styles/commonStyles.module.scss';
import IButton from '../commonComponents/IButton/IButton';

interface IProps {
    submitFormHandler: (sectionName: string) => void;
    closeFormHandler: () => void;
}

const AddCategoryForm = (props: IProps) => {
    const { submitFormHandler, closeFormHandler } = props;

    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        categoryName: yup.string().required(t('categories.requiredField')),
    });

    const formik = useFormik({
        initialValues: {
            categoryName: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            formik.resetForm();
            submitFormHandler(values.categoryName);
        },
    });
    return (
        <div className={addCategoryFormStyle.loginFormContainer}>
            <form onSubmit={formik.handleSubmit}>
                <ITextInput
                    size={CButton.SMALL}
                    handleOnChange={formik.handleChange(CCategories.CATEGORY_NAME)}
                    variant={CVariant.OUTLINED}
                    error={formik.touched.categoryName && Boolean(formik.errors.categoryName)}
                    helperText={formik.touched.categoryName && formik.errors.categoryName}
                    value={formik.values.categoryName}
                    inputProps={{ maxLength: '30' }}
                />
                <div className={addCategoryFormStyle.actionsContainer}>
                    <IButton title={t('categories.add')} className={commonStyles.textButton} type={CButton.SUBMIT} />
                    <IButton title={t('categories.cancel')} className={commonStyles.textButton} onClick={closeFormHandler} />
                </div>
            </form>
        </div>
    );
};

export default AddCategoryForm;
