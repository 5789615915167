export default class OrderConfirmationMessageModel {
    footerMessage: string = '';
    subject: string = '';

    constructor(orderConfirmationMessage?: OrderConfirmationMessageModel) {
        if (orderConfirmationMessage) {
            this.footerMessage = orderConfirmationMessage.footerMessage;
            this.subject = orderConfirmationMessage.subject;
        }
    }
}
