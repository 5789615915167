import { RouterProvider } from 'react-router-dom';
import ToastComponent from './components/commonComponents/toastComponent/ToastComponent';
import router from './router/Router';
import './styles/global.scss';

const App = () => {
    return (
        <>
            <RouterProvider router={router} />
            <ToastComponent />
        </>
    );
};

export default App;
