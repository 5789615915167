const settings = {
    shippingAndOrders: 'משלוחים והזמנות',
    additionalSettings: 'הגדרות נוספות',
    storeSettings: 'פרטי החנות',
    siteSettings: 'הגדרות אתר',
    update: 'עדכון',
    cancel: 'ביטול',
    requiredField: 'שדה חובה',
    onlyNumbersAllowed: 'מספרים בלבד',
    days: 'ימים',
    change: 'שנה',
};

export default settings;
