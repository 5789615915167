import ClientConfig from '../config/index';
import ProductModel from '../models/product/Product.model';
import { BaseHttpFetcher } from './BaseHttpFetcher';

const productsPrefix = 'productManagement';

class ProductsFetchers extends BaseHttpFetcher {
    async getProductById(productId: string): Promise<any> {
        return await this.get(`${productsPrefix}/product/${productId}`);
    }

    async updateProduct(product: ProductModel, file): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('product', JSON.stringify(product));

        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        return await this.patch(`${productsPrefix}/product/${product._id}`, formData, headers);
    }

    async createProduct(product: ProductModel, file): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('product', JSON.stringify(product));

        const headers = {
            'Content-Type': 'multipart/form-data'
        }

        return await this.post(`${productsPrefix}/product/create`, formData, headers);
    }
}

const productsFetcherInstance = new ProductsFetchers('/', ClientConfig.apiBaseHost);
export default productsFetcherInstance;
