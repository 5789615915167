const dashboard = {
    waitingOrders: 'הזמנות ממתינות לטיפול',
    productsInStore: 'מוצרים בחנות',
    usersInStore: 'לקוחות רשומים',
    incoming: 'הכנסות מהאתר',
    onlinePayments: 'תשלום באתר',
    inStorePayments: 'תשלום בחנות',
};

export default dashboard;
