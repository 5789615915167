const order = {
    orderNumber: 'הזמנה מספר',
    whoOrderDetails: 'פרטי המזמין',
    orderDetails: 'פרטי הזמנה',
    storeAddress: 'כתובת החנות',
    selfPickup: 'איסוף עצמי',
    delivery: 'משלוח',
    shipmentMethod: 'שיטת משלוח',
    deliveryAddress: 'כתובת למשלוח',
    paymentInfo: 'פרטי תשלום',
    addresseeName: 'שם הלקוח',
    addresseePhoneNumber: 'מספר טלפון',
    addresseeEmail: 'אימייל',
    addresseeId: 'תעודת זהות',
    orderDate: 'תאריך הזמנה',
    phoneNumberToCall: 'טלפון להתקשר',
    paymentMethod: 'שיטת תשלום ',
    cardHolderName: 'שם בעל הכרטיס',
    cardHolderId: 'תעודת זהות בעל הכרטיס',
    cardType: 'שם בעל הכרטיס',
    cardExperationDate: 'תעודת זהות בעל הכרטיס',
    cardNumber: 'מספר כרטיס',
    numberOfPayments: 'מספר תשלומים',
    nameInInvoice: 'שם בחשבונית',
    serialNumber: 'מק״ט',
    name: 'שם',
    price: 'מחיר',
    image: 'תמונה',
    description: 'תיאור',
    fullDescription: 'תיאור מלא',
    statusesHitsory: 'היסטוריית סטאטוסים',
    productsAmount: 'מחיר מוצרים',
    shippingAmount: 'מחיר משלוח',
    totalAmount: 'סה״כ',
    quantity: 'כמות',
    coupon: 'שימוש בקופון',
    couponCode: 'קוד קופון',
    couponDiscount: 'הנחה',
};

export default order;
