import { observer } from 'mobx-react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import iMultipleSelectStyle from './IMultipleSelect.module.scss';
import { ISelectOption } from '../../../interfaces/interfaces';
import { useEffect, useState } from 'react';

interface IProps {
    options: ISelectOption[];
    defaultSelected: ISelectOption[];
    required: boolean;
    onSelect: (selected: ISelectOption[]) => void;
}

const IMultipleSelect = observer((props: IProps) => {
    const { options, defaultSelected, onSelect, required } = props;

    const [selected, setSelected] = useState<ISelectOption[]>(defaultSelected);

    const handleChange = (event: any) => {
        const {
            target: { value },
        } = event;

        const selectedOption = options.find((option) => value[value.length - 1] === option.value);
        if (selectedOption) {
            const isOptionSelected = selected.some((item) => item.value === selectedOption.value);

            if (isOptionSelected) {
                setSelected((prevSelected) => prevSelected.filter((item) => item.value !== selectedOption.value));
                onSelect(selected.filter((item) => item.value !== selectedOption.value));
            } else {
                setSelected((prevSelected) => [...prevSelected, selectedOption]);
                onSelect([...selected, selectedOption]);
            }
        }
    };

    useEffect(() => {
        const filteredSelected = selected.filter((selectedOption) => options.some((option) => option.value === selectedOption.value));
        setSelected(filteredSelected);
    }, [options]);

    return (
        <div className={iMultipleSelectStyle.iMultipleSelecContainer}>
            <Select
                multiple
                value={selected}
                onChange={(option) => {
                    handleChange(option);
                }}
                className={`${iMultipleSelectStyle.iMultipleSelect} ${required ? (selected.length === 0 ? iMultipleSelectStyle.error : '') : ''}`}
                renderValue={(selected) => (
                    <div className={iMultipleSelectStyle.selectedContainer}>
                        {selected.map((value: ISelectOption) => (
                            <Chip key={value.value} label={value.label} />
                        ))}
                    </div>
                )}
            >
                {options.map((option: ISelectOption) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        className={`${
                            selected?.find((selectedOption) => selectedOption.value === option.value) ? iMultipleSelectStyle.selectedItem : ''
                        }`}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
});

export default IMultipleSelect;
