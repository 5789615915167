import { useTranslation } from 'react-i18next';
import IPieChart from '../commonComponents/IPieChart/IPieChart';
import storePieChartsStyle from './StorePieCharts.module.scss';
import DropDown from '../commonComponents/dropDown/DropDown';
import { useEffect, useState } from 'react';
import { ISelectOption } from '../../interfaces/interfaces';
import rootStores from '../../stores';
import { ORDERS_STORE } from '../../stores/constants';
import OrdersStore from '../../stores/orders/Orders.store';
import { observer } from 'mobx-react';

const ordersStore = rootStores[ORDERS_STORE] as OrdersStore;

interface IProps {
    yearsForStatistics: string[];
}

const StorePieCharts = observer((props: IProps) => {
    const { yearsForStatistics } = props;
    const { getPaymentsOrdersByYear, paymentsOrders, isLoading } = ordersStore;

    const [selectedYear, setSelectedYear] = useState<ISelectOption>({
        label: new Date().getFullYear().toString(),
        value: new Date().getFullYear().toString(),
    });

    const { t } = useTranslation();

    const data = [
        { value: paymentsOrders.inStorePayments, label: t('dashboard.inStorePayments') },
        { value: paymentsOrders.onlinePayments, label: t('dashboard.onlinePayments') },
    ];

    const dropDownYearsOptions = yearsForStatistics?.map((el) => {
        return { value: el, label: el };
    });

    const onSelectedYear = (year: ISelectOption) => {
        setSelectedYear(year);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getPaymentsOrdersByYear(selectedYear.value);
            } catch (error) {}
        };

        fetchData();
    }, [getPaymentsOrdersByYear, selectedYear]);

    return (
        <div className={`${storePieChartsStyle.storePieChartsContainer} ${isLoading ? 'blur-background' : ''}`}>
            <div className={`${storePieChartsStyle.dropDownContainer}`}>
                <DropDown value={selectedYear.value} options={dropDownYearsOptions} onSelectOption={onSelectedYear} />
            </div>
            <IPieChart data={data} colors={['#d67a00', '#3c3ec4']} />
        </div>
    );
});

export default StorePieCharts;
