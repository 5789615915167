import changeOrderStatusContextStyle from './ChangeOrderStatusContext.module.scss';
import { useTranslation } from 'react-i18next';
import commonStyle from '../../../../styles/commonStyles.module.scss';
import IButton from '../../IButton/IButton';
import ITextInput from '../../ITextInput/TextInput';
import { CButton, COrderConfirmationMessageForm, CVariant } from '../../../../constants/constants';
import * as yup from 'yup';
import { useFormik } from 'formik';
import OrdersStatus from '../../../../models/order/OrdersStatus.model';

interface IProps {
    updateStatusHandler: (comment: string) => void;
    cancelHandler: () => void;
    newStatusToUpdate: OrdersStatus;
}

const ChangeOrderStatusContext = (props: IProps) => {
    const { updateStatusHandler, cancelHandler, newStatusToUpdate } = props;
    const { t } = useTranslation();

    const validationSchema = yup.object().shape({
        comment: yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            comment: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            updateStatusHandler(values.comment);
        },
    });

    return (
        <div className={changeOrderStatusContextStyle.changeOrderStatusContextContainer}>
            <form onSubmit={formik.handleSubmit}>
                <div className={`${changeOrderStatusContextStyle.titleContainer} row`}>
                    <span>
                        {t('orders.changeStatus')} {newStatusToUpdate.label}?
                    </span>
                </div>
                <div className={`row`}>
                    <ITextInput
                        size={CButton.SMALL}
                        handleOnChange={formik.handleChange(COrderConfirmationMessageForm.COMMENT)}
                        variant={CVariant.OUTLINED}
                        error={formik.touched.comment && Boolean(formik.errors.comment)}
                        helperText={formik.touched.comment && formik.errors.comment}
                        value={formik.values.comment}
                        multiline={true}
                        inputProps={{ maxLength: '50' }}
                        inputLabel={t('orders.comment')}
                    />
                </div>
                <div className={`row`}>
                    <div className={`${changeOrderStatusContextStyle.actionsColumnContainer} col-6`}>
                        <IButton className={`${commonStyle.defaultButton}`} title={t('orders.update')} type={CButton.SUBMIT} />
                    </div>
                    <div className={`${changeOrderStatusContextStyle.actionsColumnContainer} col-6`}>
                        <IButton className={`${commonStyle.textButton}`} title={t('orders.cancel')} onClick={cancelHandler} />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default ChangeOrderStatusContext;
