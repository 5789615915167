import UserModel from '../../models/user/User.model';
import userMiniCardStyle from './UserMiniCard.module.scss';
import { ReactComponent as UserProfileIcon } from '../../assets/icons/user-profile.svg';
import { useNavigate } from 'react-router-dom';
import { RoutesUrl } from '../../router/RoutesUrls';
import rootStores from '../../stores';
import UsersStore from '../../stores/users/Users.store';
import { USERS_STORE } from '../../stores/constants';

interface IProps {
    user: UserModel;
}

const usersStore = rootStores[USERS_STORE] as UsersStore;

const UserMiniCard = (props: IProps) => {
    const { user } = props;

    const { setSelectedUser } = usersStore;

    const navigate = useNavigate();

    const selectUserHandler = () => {
        setSelectedUser(user);
        navigate(`${RoutesUrl.users}/${user.id}`);
    };

    return (
        <div onClick={selectUserHandler} className={userMiniCardStyle.userMiniCardContainer}>
            <div className={userMiniCardStyle.imageContainer}>
                <UserProfileIcon className={userMiniCardStyle.icon} />
            </div>
            <div className={userMiniCardStyle.detailsContainer}>
                <span>{`${user.firstName} ${user.lastName}`}</span>
            </div>
            <div className={userMiniCardStyle.detailsContainer}>
                <span>{user.phoneNumber}</span>
            </div>
            <div className={userMiniCardStyle.detailsContainer}>
                <span>{user.email}</span>
            </div>
        </div>
    );
};

export default UserMiniCard;
