import UserModel from '../user/User.model';
import CancellationModel from './CancellationModel.model';
import CouponModel from './CouponModel.model';
import OrdersStatus from './OrdersStatus.model';
import PaymentAmountModel from './PaymentAmountModel.model';
import PaymentInformationModel from './PaymentInformation.model';
import ProductInOrderModel from './ProductInOrder';
import ShippingDetailsModel from './ShippingDetailsModel.model';

export default class OrderModel {
    _id: string = '';

    shortId: string = '';

    user: UserModel = new UserModel();

    dateAndTime: string = '';

    orderStatus: OrdersStatus[] = [];

    shippingDetails: ShippingDetailsModel = new ShippingDetailsModel();

    paymentInformation: PaymentInformationModel = new PaymentInformationModel();

    products: ProductInOrderModel[] = [];

    paymentAmount: PaymentAmountModel = new PaymentAmountModel();

    cancellation: CancellationModel = new CancellationModel();

    coupon: CouponModel = new CouponModel();

    constructor(order?: OrderModel) {
        if (order) {
            this._id = order._id;
            this.shortId = order.shortId;
            this.user = order.user;
            this.dateAndTime = order.dateAndTime;
            this.orderStatus = order.orderStatus;
            this.shippingDetails = order.shippingDetails;
            this.paymentInformation = order.paymentInformation;
            this.products = order.products;
            this.paymentAmount = order.paymentAmount;
            this.cancellation = order.cancellation;
            this.coupon = order.coupon;
        }
    }
}
