import { useState, useEffect } from 'react';
import ClientConfig from '../../config/index';
import usersStyles from './Users.module.scss';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import rootStores from '../../stores';
import UsersStore from '../../stores/users/Users.store';
import OrdersStore from '../../stores/orders/Orders.store';
import { USERS_STORE, ORDERS_STORE } from '../../stores/constants';
import Spinner from '../../components/commonComponents/spinner/Spinner';
import { useTranslation } from 'react-i18next';
import UserModel from '../../models/user/User.model';
import { RoutesUrl } from '../../router/RoutesUrls';
import UsersColumns from './UsersColumns';
import ITextInput from '../../components/commonComponents/ITextInput/TextInput';
import IButton from '../../components/commonComponents/IButton/IButton';
import { useMediaQuery } from 'react-responsive';
import commonStyles from '../../styles/commonStyles.module.scss';
import UserMiniCard from '../../components/userMiniCard/UserMiniCard';

const usersStore = rootStores[USERS_STORE] as UsersStore;
const ordersStore = rootStores[ORDERS_STORE] as OrdersStore;

const Users = observer(() => {
    const [firstNameSearch, setFirstNameSearch] = useState<string>('');
    const [emailSearch, setEmailSearch] = useState<string>('');
    const [phoneSearch, setPhoneSearch] = useState<string>('');

    const { isLoading, getAllUsers, users, setSelectedUser } = usersStore;
    const { setSelectedUserOrders } = ordersStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const columns = UsersColumns();
    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const filteredRows: UserModel[] = users.filter(
        (row) =>
            row.firstName.toLowerCase().includes(firstNameSearch.toLowerCase()) &&
            row.email.toLowerCase().includes(emailSearch.toLowerCase()) &&
            row.phoneNumber.includes(phoneSearch)
    );

    const selectUserHandler = (selectedUser: UserModel) => {
        setSelectedUser(selectedUser);
        navigate(`${RoutesUrl.users}/${selectedUser.id}`);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await getAllUsers();
            } catch (error) {}
        };

        fetchData();
    }, [getAllUsers]);

    return (
        <div className={`${usersStyles.usersContainer} ${isLoading ? 'blur-background' : ''}`}>
            <div className={`${usersStyles.usersContextContainer} users-data-grid-container`}>
                <div className={`${usersStyles.inputsContainer}`}>
                    {ClientConfig.isProvider && (
                        <IButton
                            onClick={() => {
                                setSelectedUser(new UserModel());
                                setSelectedUserOrders([]);
                                navigate(RoutesUrl.createUser);
                            }}
                            title={t('users.createNewUser')}
                            className={`${commonStyles.defaultButton}`}
                        />
                    )}
                    <span>{t('users.findUserBy')}</span>
                    <ITextInput
                        size='small'
                        value={firstNameSearch}
                        handleOnChange={(e) => setFirstNameSearch(e.target.value)}
                        variant='outlined'
                        placeholder={t('users.firstName')}
                    />
                    <ITextInput
                        size='small'
                        value={phoneSearch}
                        handleOnChange={(e) => setPhoneSearch(e.target.value)}
                        variant='outlined'
                        placeholder={t('users.phoneNumber')}
                    />
                    <ITextInput
                        size='small'
                        value={emailSearch}
                        handleOnChange={(e) => setEmailSearch(e.target.value)}
                        variant='outlined'
                        placeholder={t('users.email')}
                    />
                </div>
                <div className={`${usersStyles.dataGridContainer} `}>
                    {!isMobileScreen && (
                        <DataGrid
                            disableColumnMenu={true}
                            pageSizeOptions={[20]}
                            disableRowSelectionOnClick={true}
                            onRowClick={(e) => {
                                selectUserHandler(e.row);
                            }}
                            rowHeight={80}
                            columns={columns}
                            rows={filteredRows}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20 } },
                            }}
                        />
                    )}
                    {isMobileScreen && (
                        <div className={usersStyles.usersListContainer}>
                            {filteredRows.map((user) => (
                                <UserMiniCard key={user.id} user={user} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {isLoading && <Spinner />}
        </div>
    );
});

export default Users;
