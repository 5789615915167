import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import userFormStyles from './UserForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import IButton from '../commonComponents/IButton/IButton';
import commonStyles from '../../styles/commonStyles.module.scss';
import ITextInput from '../commonComponents/ITextInput/TextInput';
import rootStores from '../../stores';
import { USERS_STORE, AUTH_STORE } from '../../stores/constants';
import AuthStore from '../../stores/auth/Auth.store';
import UsersStore from '../../stores/users/Users.store';
import { CVariant, CButton, CUsers } from '../../constants/constants';
import { formatDateOnly } from '../../utils/dateUtils';
import CreateUserModel from '../../models/user/CreateUser.model';
import { hashPassword } from '../../actions/hashPassword';
import UserModel from '../../models/user/User.model';
import { RoutesUrl } from '../../router/RoutesUrls';

const usersStore = rootStores[USERS_STORE] as UsersStore;
const authStore = rootStores[AUTH_STORE] as AuthStore;

interface IProps {
    isCreatedMode: boolean;
}

const UserForm = observer((props: IProps) => {
    const { isCreatedMode } = props;
    const { selectedUser, setSelectedUser } = usersStore;
    const { createCustomer } = authStore;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const validationSchema = yup.object().shape({
        email: yup.string().email(t('userForm.invalidEmail')).required(t('loginForm.requiredField')),
        firstName: yup.string().required(t('userForm.requiredField')),
        lastName: yup.string().required(t('userForm.requiredField')),
        phoneNumber: yup
            .string()
            .required(t('userForm.requiredField'))
            .matches(/^(05\d{8}|9725\d{8}|97205\d{8})$/, { message: t('userForm.invalidPhoneNumber'), excludeEmptyString: true }),
        password: yup
            .string()
            .required(t('userForm.requiredField'))
            .min(8, t('userForm.minDigits', { length: 8 })),
    });

    const formik = useFormik({
        initialValues: {
            email: selectedUser.email,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            phoneNumber: selectedUser.phoneNumber,
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const user = await createCustomer(
                new CreateUserModel({
                    email: values.email,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    phoneNumber: values.phoneNumber,
                    password: await hashPassword(values.password),
                })
            );
            setSelectedUser(new UserModel(user));
            navigate(`${RoutesUrl.users}/${user.id}`);
        },
    });

    return (
        <div className={userFormStyles.userFormContainer}>
            <form onSubmit={formik.handleSubmit}>
                {isCreatedMode && (
                    <div className={userFormStyles.actionsContainer}>
                        <IButton title={t('userForm.createUser')} type={CButton.SUBMIT} className={commonStyles.defaultButton} />
                    </div>
                )}
                <div className={`${userFormStyles.inputsRowContainer} row`}>
                    <div className={`${userFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={userFormStyles.fieldTitleContainer}>
                            <span>{t('user.firstName')}</span>
                        </div>
                        <div className={userFormStyles.inputContainer}>
                            {isCreatedMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CUsers.FIRST_NAME)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    value={formik.values.firstName}
                                    inputProps={{ maxLength: '10' }}
                                />
                            ) : (
                                <span>{selectedUser.firstName}</span>
                            )}
                        </div>
                    </div>
                    <div className={`${userFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={userFormStyles.fieldTitleContainer}>
                            <span>{t('user.lastName')}</span>
                        </div>
                        <div className={userFormStyles.inputContainer}>
                            {isCreatedMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CUsers.LAST_NAME)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    value={formik.values.lastName}
                                    inputProps={{ maxLength: '10' }}
                                />
                            ) : (
                                <span>{selectedUser.lastName}</span>
                            )}
                        </div>
                    </div>
                    <div className={`${userFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={userFormStyles.fieldTitleContainer}>
                            <span>{t('user.phoneNumber')}</span>
                        </div>
                        <div className={userFormStyles.inputContainer}>
                            {isCreatedMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CUsers.PHONE_NUMBER)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    value={formik.values.phoneNumber}
                                    inputProps={{ maxLength: '10' }}
                                />
                            ) : (
                                <span>{selectedUser.phoneNumber}</span>
                            )}
                        </div>
                    </div>
                </div>
                <div className={`${userFormStyles.inputsRowContainer} row`}>
                    <div className={`${userFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                        <div className={userFormStyles.fieldTitleContainer}>
                            <span>{t('user.email')}</span>
                        </div>
                        <div className={userFormStyles.inputContainer}>
                            {isCreatedMode ? (
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CUsers.EMAIL)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    value={formik.values.email}
                                    inputProps={{ maxLength: '40' }}
                                />
                            ) : (
                                <span>{selectedUser.email}</span>
                            )}
                        </div>
                    </div>
                    {!isCreatedMode && (
                        <div className={`${userFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                            <div className={userFormStyles.fieldTitleContainer}>
                                <span>{t('user.dateOfBirth')}</span>
                            </div>
                            <div className={userFormStyles.inputContainer}>
                                <span>{formatDateOnly(selectedUser.dateOfBirth)}</span>
                            </div>
                        </div>
                    )}
                    {isCreatedMode && (
                        <div className={`${userFormStyles.inputsColumnContainer} col-12 col-md-4`}>
                            <div className={userFormStyles.fieldTitleContainer}>
                                <span>{t('user.password')}</span>
                            </div>
                            <div className={userFormStyles.inputContainer}>
                                <ITextInput
                                    size={CButton.SMALL}
                                    handleOnChange={formik.handleChange(CUsers.PASSWORD)}
                                    variant={CVariant.OUTLINED}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    value={formik.values.password}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </form>
        </div>
    );
});

export default UserForm;
