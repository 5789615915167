import { IInformation, IMediaLinks, IOpeningHours, IUsefulLinks, IOurServices } from '../../interfaces/interfaces';

export default class StoreInformationModel {
    aboutText: string = '';

    usefulLinks: IUsefulLinks[] = [];

    openingHours: IOpeningHours[] = [];

    information: IInformation[] = [];

    ourServices: IOurServices[] = [];

    mediaLinks: IMediaLinks[] = [];

    storeName: string = '';

    mainBannerUrl: string = '';

    headerAddsTexts: string = '';

    defaultProductImage: string = '';

    constructor(storeInformation?: StoreInformationModel) {
        if (storeInformation) {
            this.aboutText = storeInformation.aboutText;
            this.usefulLinks = storeInformation.usefulLinks;
            this.openingHours = storeInformation.openingHours;
            this.information = storeInformation.information;
            this.mediaLinks = storeInformation.mediaLinks;
            this.ourServices = storeInformation.ourServices;
            this.storeName = storeInformation.storeName;
            this.mainBannerUrl = storeInformation.mainBannerUrl;
            this.headerAddsTexts = storeInformation.headerAddsTexts;
            this.defaultProductImage = storeInformation.defaultProductImage;
        }
    }
}
