import { RowData } from '../../../interfaces/interfaces';
import linkSyle from './ILink.module.scss';

interface IProps {
    text: string;
    onClick: any;
    row: RowData;
}

const ILink = (props: IProps) => {
    const { text, onClick, row } = props;
    return (
        <span className={linkSyle.text} onClick={() => onClick(row)}>
            {text}
        </span>
    );
};

export default ILink;
