import { action, makeObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import AuthFetcher from '../../fetchers/Auth.fetcher';
import { ILoginPayload, ILoginResponse } from '../../interfaces/interfaces';
import UserModel from '../../models/user/User.model';
import ToastUtil from '../../utils/ToastUtils';
import UserStore from '../user/User.store';
import { CErrors, CToastUtil } from '../../constants/constants';
import CreateUserModel from '../../models/user/CreateUser.model';

class AuthStore {
    @observable
    accessToken: string = '';

    @observable
    isLoggedIn: boolean = false;

    @observable
    isLoading: boolean = false;

    private userStore: UserStore;

    constructor(userStore: UserStore) {
        makeObservable(this);
        makePersistable(this, {
            name: 'AuthStore',
            properties: ['accessToken', 'isLoggedIn'],
            storage: window.localStorage,
        });
        this.userStore = userStore;
    }

    @action
    setAccessToken = (accessToken: string) => (this.accessToken = accessToken);

    @action
    setIsLoggedIn = (isLoggedIn: boolean) => (this.isLoggedIn = isLoggedIn);

    @action
    setIsLoading = (isLoading: boolean) => (this.isLoading = isLoading);

    @action
    login = async (payload: ILoginPayload) => {
        try {
            this.setIsLoading(true);
            const { data }: ILoginResponse = await AuthFetcher.login(payload);
            this.setAccessToken(data.access_token);
            this.userStore.setUser(data.user);
            this.setIsLoggedIn(true);
        } catch (err: any) {
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return false;
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    createCustomer = async (newUser: CreateUserModel) => {
        try {
            this.setIsLoading(true);
            const {
                data: { user },
            } = await AuthFetcher.createCustomer(newUser);
            ToastUtil.success(CToastUtil.CREATED_SUCCESSFULLY);
            return user;
        } catch (err: any) {
            console.error(err?.message);
            const errMessage = err?.response?.data?.message ? err?.response?.data?.message : CErrors.SYSTEM;
            ToastUtil.error(errMessage);
            return new UserModel();
        } finally {
            this.setIsLoading(false);
        }
    };

    @action
    logout = () => {
        this.setIsLoggedIn(false);
        this.userStore.setUser(new UserModel());
        this.accessToken = '';
    };
}

export default AuthStore;
