const userForm = {
    back: 'חזרה',
    createUser: 'יצירת לקוח',
    requiredField: 'שדה חובה',
    invalidPhoneNumber: 'מספר אינו תקין',
    invalidEmail: 'מייל לא תקין',
    minDigits: 'מינימום 8 תווים',
    oneEnglishLetter: 'מינימום אות אחת באנגלית',
};

export default userForm;
