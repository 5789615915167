import { useTranslation } from 'react-i18next';
import ClientConfig from '../../../config/index';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import menuItemsList from '../../../components/managementOptions/menuItems/menuItems';
import providerMenuItemsList from '../../../components/managementOptions/menuItems/providerMenuItems';
import managementLayoutStyle from './ManagementLayout.module.scss';

const ManagementLayout = () => {
    const isMobileScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const menuItems = !ClientConfig.isProvider ? menuItemsList : providerMenuItemsList;

    return (
        <>
            <div className={managementLayoutStyle.top}></div>
            <div className={managementLayoutStyle.managementLayoutContainer}>
                <div className={managementLayoutStyle.layoutContextContainer}>
                    {!isMobileScreen && (
                        <div className={managementLayoutStyle.topMenuBarContainer}>
                            {menuItems?.map((item, i) => (
                                <div
                                    className={`${managementLayoutStyle.menuItem} ${
                                        location.pathname.includes(item.routeUrl) ? managementLayoutStyle.activeItem : ''
                                    }`}
                                    key={i}
                                    onClick={() => {
                                        navigate(item.routeUrl);
                                    }}
                                >
                                    <span>{t(item.label)}</span>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className={managementLayoutStyle.contentContainer}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ManagementLayout;
