import { ISelectOption } from "../../interfaces/interfaces";

export default class OrderFilterModel {
    price: string = '';

    orderId: string = '';

    status?: ISelectOption;

    date: Date | null = null;

    constructor(filter?: OrderFilterModel) {
        if (filter) {
            this.price = filter.price;
            this.orderId = filter.orderId;
            this.status = filter.status;
            this.date = filter.date;
        }
    }
}
