const coupons = {
    couponCode: 'קוד קופון',
    findCouponBy: 'חפש קופון לפי',
    createNewCoupon: 'יצירת קופון חדש',
    discount: 'הנחה',
    expirationDate: 'תאריך תפוגה',
    isActive: 'פעיל',
    isReusable: 'ניתן לשימוש חוזר',
    minAmount: 'סכום הזמנה מינימלי',
    usageLimit: 'כמות מקסימלית לשימוש',
    usedCount: 'מספר שימושים',
    createdAt: 'תאריך יצירה',
};

export default coupons;
